import React from 'react';
import ContactUs from '../components/contact/contact.jsx';
import Clients from "../components/clients/clients.jsx";
import Bookingform from "../components/booking/Bookingform";

const Contact = () => {
  return (
    <div>
      <ContactUs />
      <Bookingform />
      {/* <Clients /> */}
    </div>
  );
};

export default Contact;
