import React from 'react';
import Novacancy from '../../images/general/no-vacancy.png';

const Careers = (props) => {
    return (
        <div id='order-list-page'>
            {/* Header Banner */}
            <div
                className='banner-header section-padding valign bg-careersimg bg-fixed'
                data-overlay-dark='4'
                data-background='img/slider/1.jpg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 caption mt-90'>
                            <h5>The Emin Pasha Hotel</h5>
                            <h1>Careers</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section id='order-dashboard' className="facilties section-padding bg-order-page">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-12">
                            <div className="section-title" style={{ color: '#aa8453' }}>Message From The Human Resource Manager</div>
                        </div>
                        <div className='col-md-12'>
                            <p>At The Emin Pasha Hotel, we celebrate the dreamers
                                who make their own mark, and with good reason. Our
                                mission is to delight and surprise our guests at
                                every encounter. This starts by treating our
                                associates like family, and fostering an
                                environment built around creativity, teamwork and
                                collaboration.
                            </p>
                            <p>
                                Are you passionate about guest service and hotel
                                business, do you go an extra mile to ensure guest
                                satisfaction, do you enjoy working in teams and
                                achieve team goals, do you want to learn more
                                about hotel business and service, are you smart
                                in mind, body and soul, are you looking at growth in your career?
                            </p>
                        </div> */}
                        <div className='col-md-12'>
                            <div 
                                class="no-vacancies-section" 
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                            <div className='icon-container' style={{ marginBottom: 20 }}>
                                <img src={Novacancy} height="100px" alt="" style={{ maxWidth: "100px" }} />
                            </div>
                            <p>
                                We appreciate your interest in joining our team at Emin Pasha Hotel.
                                While there are currently no open vacancies, we would like to thank you
                                for considering us as a potential employer. At Emin Pasha Hotel, we strive
                                to create an environment that fosters growth, innovation, and collaboration.
                                We value the contributions of talented individuals like yourself, and we
                                encourage you to check back regularly for future career opportunities.
                            </p>

                            <p>
                                In the meantime, we recommend exploring our website to learn more 
                                about our company culture, values, and the work we do. You can also 
                                connect with us on social media to stay updated on any upcoming 
                                vacancies or exciting news within our organization.We understand that 
                                finding the right career fit is important, and we encourage you to 
                                keep pursuing your professional goals. We wish you the best of luck in 
                                your job search and hope to have the opportunity to connect with you 
                                in the future.
                                
                            </p>
                            <p> <strong>Thank you again for considering The Emin Pasha Hotel.</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Careers;
