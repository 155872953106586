import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function HorizontalCheckform() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [numberOfAdults, setNumberOfAdults] = useState('1');
  const [numberOfChildren, setNumberOfChildren] = useState("0");
  const currentDate = new Date();


  const handleAdultChange = (event) => {
    console.log(event.target.value);
    setNumberOfAdults(event.target.value);
  };

  const handleChildrenChange = (e) => {
    setNumberOfChildren(e.target.value);
  };


  return (
    <div>
      {/* Booking Search */}
      <div className='booking-wrapper horizontal-check-form'>
        <div class='container'>
          <div className='booking-inner clearfix'>
            <form
              className='form1 clearfix'>
              <div className='col1 c1'>
                <div className='input1_wrapper'>
                  <label>Check in</label>
                  <div className='input1_inner'>
                    {/* <input
                      type='text'class="input1_inner"
                      className='form-control input datepicker hasDatepicker'
                      placeholder='Check in'
                    /> */}
                    <DatePicker
                      selected={startDate}
                      placeholderText='Check in'
                      onChange={(date: Date) => setStartDate(date)}
                      minDate={currentDate}
                      className="form-control"
                      // customInput={
                      //   <input
                      //     type='text'
                      //     className='form-control input datepicker hasDatepicker'
                      //     placeholder='Check in'
                          
                      //   />
                      // }
                      
                    />
                  </div>

                </div>

              </div>
              <div className='col1 c2'>
                <div className='input1_wrapper'>
                  <label>Check out</label>
                  <div className='input1_inner'>
                    {/* <input
                      type='text'
                      className='form-control input datepicker'
                      placeholder='Check out'
                    /> */}
                    <DatePicker
                      selected={endDate}
                      placeholderText='Check out'
                      onChange={(date: Date) => setEndDate(date)}
                      minDate={startDate}
                      disabled={typeof startDate === 'string'}
                      className="form-control"
                      customInput={
                        <input
                          type='text'
                          className='form-control input datepicker hasDatepicker'
                          placeholder='Check out'
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='col3 c3'>
                <div className='select1_wrapper'>
                  <label>Adults</label>
                  <div className='select1_inner'>
                    <select
                      className='select2 select'
                      style={{ width: '100%' }}
                      value={numberOfAdults}
                      onChange={e => handleAdultChange(e)}
                    >
                      
                      <option value='1'>1 Adult</option>
                      <option value='2'>2 Adults</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='col3 c4'>
                <div className='select1_wrapper'>
                  <label>Children</label>
                  <div className='select1_inner'>
                    <select 
                    className='select2 select' 
                    style={{ width: '100%' }}
                    value={numberOfChildren}
                    onChange={e => handleChildrenChange(e)}
                    >
                      <option selected value='' disabled>Select Children</option>
                      <option value="0">No Child</option>
                      <option value='1'>1 Child</option>
                      <option value='2'>2 Children</option>
                      <option value='3'>3 Children</option>
                      <option value='4'>4 Children</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className='col2 c5'>
                <div className='select1_wrapper'>
                  <label>Rooms</label>
                  <div className='select1_inner'>
                    <select className='select2 select' style={{ width: '100%' }}>
                      <option value='1'>1 Room</option>
                      <option value='2'>2 Rooms</option>
                      <option value='3'>3 Rooms</option>
                      <option value='4'>4 Rooms</option>
                    </select>
                  </div>
                </div>
              </div> */}
              <div className='col3 c6'>
                <div className={startDate && endDate ? "btn-form1-submit" : 'btn-form1-submit-disabled'}>
                  {" "}
                  <Link
                    to="/booking/stepper"
                    state={{ 
                      startDate: startDate, 
                      endDate: endDate,
                      numberOfAdults: numberOfAdults,
                      numberOfChildren: numberOfChildren
                    }}
                    data-scroll-nav="1"
                    style={{
                      pointerEvents: startDate && endDate ? "" : "none"
                    }}
                  >
                    <span>Check Now</span>
                  </Link>{" "}
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HorizontalCheckform;
