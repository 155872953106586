import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import useBreakPoint from './../../shared/breakPoints/useBreakPoint';
import { CurrencyFormat } from '../../shared/Formatter';
import { connect } from 'react-redux';
import { FormatDate } from '../../shared/Formatter/index';
import { XCircle } from 'react-bootstrap-icons';
import { GetRoomsInCart,AddEnhancementToRoomInCart, DeleteRoomFromCart, DeleteEnhancementFromCart, FetchSpecificRoom } from '../../shared/utils/api/api'
import { useAxios } from 'use-axios-client';
import axios from "axios";
import ImageNotFound from '../../images/restaurant/image-not-found.png';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const mapDispatchToProps = dispatch => {
    return {
        removeRoom: product =>
            dispatch({ type: 'REMOVE_ROOM_FROM_CART', payload: product }),
        removeEnhancement: product =>
            dispatch({ type: 'REMOVE_ENHANCEMENT_FROM_CART', payload: product }),
        addEnhancement: product =>
            dispatch({ type: 'ADD_ENHANCEMENT', payload: product }),
    };
};

const mapStateToProps = state => {
    return {
        cartItems: state,
    };
};

const SelectedRooms = (props) => {
    console.log(';;debug selected rooms:', props);
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [roomEnhancements, setRoomEnhancements] = useState([]);
    const [enhancementsObj, setEnhancementsObj] = useState([]);
    const [grossAmount, setGrossAmount] = useState(0);
    const [enhancementAmount, setEnhancementAmount] = useState(0);
    const [roomAmount, setRoomAmount] = useState(0);
    const { matchesMobile } = useBreakPoint();
    const handleAddMoreRooms = () => {
        props.setStep(1);
    };
    


    const attachRoomId = (roomId, enhncement, index) => {
        console.log('::debug roomId, enhncement, index:', roomId, enhncement, index);
        enhncement.roomId = roomId;
        enhncement.uniqueId = roomId.toString() + enhncement.en_id.toString() + index.toString();
        return enhncement;
    };

    const enhancementObj = [
        {
            en_description: "per person 1 hour relaxing massage at Emin Pasha Spa",
            en_id: 1,
            en_image: null,
            en_name: "Airport trans",
            en_price: 200000,
        },

        {
            en_description: "per person 1 hour relaxing massage at Emin Pasha Spa",
            en_id: 2,
            en_image: "https://developers.eminpasha.com/images/enhacement/a4ab77d6-df6e-4530-b606-e5748a79299e.png",
            en_name: "Spa and Sauna",
            en_price: 5000,
        },
    ];

    const removeEnhancementFromSelectedRoom = async (entryId, enhancementID) => {
        //props.removeRoom(room);
        props.setShowProgress(true);
        try {
            await DeleteEnhancementFromCart(entryId, enhancementID).then((response) => {
                console.log('::debug deleted enhancementID from response:', response);
                fetchRoomsInCart();
            });
            props.setShowProgress(false);
        } catch (error) {
            props.setShowProgress(false);
            console.log('::debug delete enhancementID cart eerror:', error);
        }
    };


    const removeSelectedRoom = async (room) => {
        //props.removeRoom(room);
        console.log('::xxxcheck removeSelectedRoom:', room);
        props.setShowProgress(true);
        try {
            await DeleteRoomFromCart(room.entryId).then((response) => {
                console.log('::debug delete Booking cart response:', response);
                fetchRoomsInCart();
            });
            props.setShowProgress(false);
        } catch (error) {
            props.setShowProgress(false);
            console.log('::debug delete BOOKing cart eerror:', error);
        }
    };

    const generateRowNumber = () => {
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        // var randomChars = "0123456789";
        var result = "";
        for (var i = 0; i < 8; i++) {
            result += randomChars.charAt(
                Math.floor(Math.random() * randomChars.length)
            );
        }
        return result;
    };

    const generateKeyId = (id, index) => {
        const keyGen = id.toString() + index.toString();
        return keyGen;
    };

    const addRoomEnhancementToCart = async (entryId,enhancementId) => {
        props.setShowProgress(true);
        try {
            await AddEnhancementToRoomInCart(entryId,{
                enhancementID: enhancementId,
                enhancementQty: 1
            }).then((response) => {
              console.log('::AddedToCart Booking cart response:', response);
              fetchRoomsInCart();
            });
            props.setShowProgress(false);
          } catch (error) {
            props.setShowProgress(false);
            console.log('::AddedToCart BOOKing cart eerror:', error);
          }
    }

    const checkIfEnhancementIsAdded = (room, enhancement, index) => {
        console.log("__AddedToCart room:", room);
        console.log("__AddedToCart roomEnhancements:", enhancement);
        if (room.extraItems.includes(enhancement.id)) {
            return (
                <div className="button-outline-danger">
                    <button onClick={() => removeEnhancementFromSelectedRoom(room.entryId, enhancement.id)}>
                        <span>Remove Enhancement</span>
                    </button>
                </div>
            );
        }

        return (
            <div className="butn-dark">
                {" "}
                <button onClick={() => addRoomEnhancementToCart(room.entryId, enhancement.id) }>
                    <span>Add Enhancement</span>
                </button>{" "}
            </div>

        );
    };

    const fetchRoomsInCart = async () => {
        props.setShowProgress(true);
        try {
            await GetRoomsInCart().then((response) => {
                console.log('xxxrooms in cart:', response.data);
                const objectResponse = response.data.data.room_cart;
                const cartTotal = response.data.data.cart_total;
                setRoomAmount(cartTotal);
                console.log('::xxxrooms in cart objectResponse:', objectResponse);
                getRoomCartData(objectResponse);
            });
            props.setShowProgress(false);
        } catch (error) {
            props.setShowProgress(false);
            console.log('::debug roomcart error:', error);
        }
    }

    const getAllEnhancementIds = (enhancementArray) => {
        var newResults = [];
        for(var m=0; m<enhancementArray.length; m++){
            var IdIndex = enhancementArray[m].enhancementID;
            newResults.push(IdIndex);
        }
        return newResults;
    }
    
    const getRoomCartData = async (roomsArray) => {
        props.setShowProgress(true);
        console.log('::xxxrooms getRoomCartData:', roomsArray);
        var roomsCartDetail = [];
        var extraItems = [];
        for(var i=0; i<roomsArray.length; i++){
            var roomId = roomsArray[i].room_id;
            var entryID = roomsArray[i].entryID;
            extraItems = roomsArray[i].extra_items && roomsArray[i].extra_items.length > 0 ? getAllEnhancementIds(roomsArray[i].extra_items) : [];
            console.log('::xxxrooms get roomcart data index:', roomsArray[i]);
            try{
                // eslint-disable-next-line no-loop-func
                await FetchSpecificRoom(roomId).then((response) => {
                    console.log('::xxxrooms get index response:',response);
                    roomsCartDetail.push({
                        entryId: entryID,
                        ...response.data,
                        extraItems,
                    });
                    console.log('::xxxrooms roomsCartDetail:', roomsCartDetail);
                });
                props.setShowProgress(false);
            } catch (error) {
                props.setShowProgress(false);
                console.log('::xxxrooms Error:', error);
            }
        }
       
        setSelectedRooms(roomsCartDetail);
    }

    useEffect(() => {
        // const roomsArray = props.cartItems.roomsCart;
        const roomEnhancement = props.enhancements;

        // setEnhancementsObj(props.enhancements); 29b452dc-39cd-4708-90ec-f4bc09ee0b6f
        console.log('::debug roomEnhancement:', roomEnhancement);
        fetchRoomsInCart();
        setRoomEnhancements(roomEnhancement);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ props.enhancements]);

    useEffect(() => {
        var totalRoomAmount = 0;
        var totalEnhancementAmount = 0;

        // for (let i = 0; i < selectedRooms.length; i++) {
        //     const tempAmount = parseInt(selectedRooms[i].room_cap.room_base_price);
        //     totalRoomAmount += tempAmount;
        // }

        for (let m = 0; m < props.enhancements.length; m++) {
            const tempAmount = parseInt(props.enhancements[m].en_price);
            totalEnhancementAmount += tempAmount;
        }
        console.log(':: EnhamcementObj:', props.enhancements);
        console.log(':debug totalRoomAmount', totalRoomAmount);
        setEnhancementsObj(props.enhancements);
        setEnhancementAmount(totalEnhancementAmount);
        setGrossAmount(totalRoomAmount + totalEnhancementAmount);
    }, [selectedRooms, props.enhancements]);
    console.log('::debug selectedRoomsxxx:',selectedRooms)

    return (
        <div id="selected-rooms-page">
            <section className={matchesMobile ? "mt-3 pt-3" : "mt-5 pt-5"}>
                <div className="container">
                    <div className="row">
                        <div className='col-md-12'>
                            <div className="selected-preface" style={{ flexDirection: matchesMobile ? 'column' : 'row' }}>
                                <div className='selected-room-summary' style={{ flexDirection: matchesMobile ? 'column' : 'row' }}>
                                    <div className="rooms-selected"><p>Number of Selected Rooms:<span>{selectedRooms.length}</span></p></div>
                                    <div className="rooms-selected"><p>Total Amount:<span>{CurrencyFormat(roomAmount)}</span></p></div>
                                    {/* <div className="rooms-selected"><p>Enhancement Amount:<span>{CurrencyFormat(enhancementAmount)}</span></p></div>
                                    <div className="rooms-selected"><p>Gross Amount:<span>{CurrencyFormat(grossAmount)}</span></p></div> */}
                                </div>
                                <div className="float-right mb-5">
                                    <div className="btn-outline-emin">
                                        <button onClick={() => handleAddMoreRooms()} style={{ width: matchesMobile ? '100%' : 'auto', marginLeft: 0 }}><span>+ Add More Rooms</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        selectedRooms.length > 0 ? (
                            selectedRooms.map((room, index) => {
                                return (
                                    <div
                                        key={room.id}
                                        className="row"
                                        style={{
                                            marginBottom: matchesMobile ? 38 : 0,
                                            borderBottomWidth: matchesMobile ? 2 : 0,
                                            borderBottom: matchesMobile ? 'solid' : 'none',
                                            borderBottomColor: matchesMobile ? '#a29e9e' : 'transparent',

                                        }} >
                                        <div className="col-md-8">
                                            <div className="content">
                                                <div
                                                    className="delete-container-select-room"
                                                    style={{
                                                        top: '0.6%',
                                                        left: matchesMobile ? '5.5%' : '2.7%',
                                                        width: 'auto',
                                                        position: 'absolute',
                                                    }}>
                                                    <button onClick={() => removeSelectedRoom(room)} style={{ backgroundColor: '#eee', borderRadius: '53%' }}><XCircle fontSize="29px" color='#b70606' /></button>
                                                </div>
                                                <figure>
                                                    <img src={room.roomImage && room.roomImage.length > 0 ? room.roomImage[0].image_name : ImageNotFound} alt="" className="img-fluid" />
                                                </figure>
                                                <div className="col-md-12">
                                                    <div
                                                        className="rooms2 animate-box"
                                                        data-animate-effect="fadeInUp"
                                                    >

                                                        <div className="caption">
                                                            <h3>
                                                                {`${CurrencyFormat(room.room_base_price)}USD`} <span>/ Night</span>
                                                            </h3>
                                                            <h4>
                                                                {room.room_name}
                                                            </h4>
                                                            <p>
                                                                {room.room_description}
                                                            </p>
                                                            <div className="row room-facilities">
                                                                <div className="col-md-4">
                                                                    <ul>
                                                                        <li>
                                                                            <i className="flaticon-group"></i> 1-2 Persons
                                                                        </li>
                                                                        <li>
                                                                            <i className="flaticon-wifi"></i> Free Wifi
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <ul>
                                                                        <li>
                                                                            <i className="flaticon-bed"></i> Twin Bed
                                                                        </li>
                                                                        <li>
                                                                            <i className="flaticon-breakfast"></i> Breakfast
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <ul>
                                                                        <li>
                                                                            <i className="flaticon-clock-1"></i> 45 sqft room
                                                                        </li>
                                                                        <li>
                                                                            <i className="flaticon-swimming"></i> Swimming Pool
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <hr className="border-2" />

                                                                <div className="col-md-6">
                                                                    {
                                                                        (props.cartStartDate && props.cartEndDate) ? (
                                                                            <>
                                                                            <p><b>Check-in Date: </b><span>{(props.cartStartDate)}</span></p>
                                                                            <p><b>Check-out Date: </b><span>{(props.cartEndDate)}</span></p>
                                                                            </>
                                                                        ):(
                                                                            <>
                                                                                <p><b>Check-in Date: </b><span>{FormatDate(props.startDate)}</span></p>
                                                                                <p><b>Check-out Date: </b><span>{FormatDate(props.endDate)}</span></p>
                                                                            </>
                                                                        )
                                                                    }
                                                                    
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p><b>Number of Adults: </b><span>1</span></p>
                                                                    <p><b>Number of Children: </b><span>0</span></p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="enhancement-section" style={{ marginBottom: matchesMobile ? 36 : 0 }}>
                                                <h3>Enhancements</h3>
                                                {
                                                    roomEnhancements.length > 0 && (
                                                        roomEnhancements.map((enhancement) => {
                                                            return (
                                                                <Card key={generateKeyId(enhancement.id, index)} sx={{ maxWidth: 345 }} className="mb-3">
                                                                    <img src={enhancement.en_image} alt="" className="img-fluid" style={{ height: 235 }} />
                                                                    <CardContent>
                                                                        <Typography style={{ fontFamily: 'Avenirlight', fontSize: 20, fontWeight: 700, marginBottom: 0 }} gutterBottom variant="h5" component="div">
                                                                            {enhancement.en_name}
                                                                        </Typography>
                                                                        <Typography style={{ fontFamily: 'Avenirlight', fontSize: 13, lineHeight: '20px' }} variant="body2" color="text.secondary">
                                                                            <b>{`${CurrencyFormat(enhancement.en_price)}USD`}</b> {enhancement.en_description}
                                                                        </Typography>
                                                                        {
                                                                            enhancement.en_name.includes('Airport') && (
                                                                                <span className='text-center'>
                                                                                    <Link to='/services/airport-transfer'>Read More</Link>
                                                                                </span>
                                                                            )
                                                                        }
                                                                    </CardContent>
                                                                    <div className="button-center mb-2">
                                                                        {
                                                                            checkIfEnhancementIsAdded(room, enhancement, index)
                                                                        }
                                                                    </div>
                                                                </Card>
                                                            )
                                                        })
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })

                        ) : (
                            <div className='cart-empty'>
                                <div className="row">
                                    <div style={{ display: 'flex', justifyContent: 'center' }} className="col-md-12">
                                        <HourglassEmptyIcon style={{ color: '#cdcccc', fontSize: 83 }} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }} className='col-md-12'>
                                        <p style={{ fontWeight: 'bold', fontSize: 29 }}>No rooms available for the selected checkin and checkout date. </p>

                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }} className='col-md-12'>
                                        <div className="butn-dark">
                                            <button onClick={() => { props.setStep(0) }}>
                                                <span>Select Room</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedRooms);