import React from 'react';
import RoomsAndSuites from '../components/rooms-and-suites/rooms-and-suites';
import Testimony from "../components/home/Testimony";
import HomePricing from "../components/home/HomePricing";
const Roomsandsuites = (props) => {
  return (
    <div>
      <RoomsAndSuites setShowProgress={props.setShowProgress} />
      <HomePricing/>
      {/* <Testimony /> */}
    </div>
  );
};

export default Roomsandsuites;
