import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../globalstyles/themify-icons.css';
import '../globalstyles/animate.min.css';
import '../globalstyles/datepicker.css';
import '../globalstyles/flaticon.css';
import '../globalstyles/magnific-popup.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../globalstyles/owl.theme.default.min.css';
import '../globalstyles/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../globalstyles/select2.css";
import '../globalstyles/style.css';
import spa from "../../images/spa/spa5.jpeg";
import pool from "../../images/swimmingpool/swimmingpool1.jpeg";
import gym from "../../images/spa/2.jpg";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  padding: '8px 40px',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const FitnessAndSpa = () => {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <div>
      {/* Spa-Wellness Slider */}
      <header className="header home-header-container slider-fade">
        {/* The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. */}
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", 'desktop']}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div
            className="text-center item bg-spa-img"
            data-overlay-dark="3"
            data-background="../../images/spa/3.jpg"
          >
            {/* <img src={spa} alt="" /> */}
          </div>
          <div
            className="text-center item bg-spa-img2"
            data-overlay-dark="3"
            data-background="../../images/swimmingpool/swimmingpool1.jpeg"
          >
            {/* <img src={pool} alt="" /> */}
          </div>
          <div
            className="text-center item bg-spa-img3"
            data-overlay-dark="3"
            data-background="../../images/spa/2.jpg"
          >
            {/* <img src={gym} alt="" /> */}
          </div>
        </Carousel>
        {/* arrow down */}
        <div className="arrow bounce text-center">
          <a href="#preface-section" data-scroll-nav="1" className="">
            {" "}
            <i className="ti-arrow-down"></i>{" "}
          </a>
        </div>
      </header>
      {/* Spa-Wellness Content */}
      <section id='preface-section' className="rooms-page section-padding" data-scroll-index="1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {" "}
              <span>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
              </span>
              <div className="section-subtitle">So Many Ways to Unwind</div>
              <div className="section-title">Spa & Wellness</div>
              <p className="mb-30">
                Spas have been known as places of healing for thousands of
                years.They offer therapeutic treatments like massage, body
                treatments and facials, which improve your sense of well-being.
                At Emin Pasha Hotel, our team of professionals offer the most
                calming and luxurious spa experience. Book a spa treatment with
                us, and leave feeling rejuvenated.{" "}
              </p>
            </div>
            <div className="col-md-12">
              <div className="reservations">
                <div className="icon">
                  <span className="flaticon-call"></span>
                </div>
                <div className="text">
                  <p>Reservations</p>{" "}
                  <a href="tel:+256 312 264 712">+256 312 264 712 /4</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Services */}
      <section className="services section-padding2">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 p-0 animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div className="img left">
                <Link to="/fitnessandspa">
                  <img src={spa} alt="" />
                </Link>
              </div>
            </div>
            <div
              className="col-md-6 p-0 bg-cream valign animate-box"
              data-animate-effect="fadeInRight"
            >
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Experiences</h6>
                  </div>
                  <h4>Emin Pasha Spa</h4>
                  <p>
                    Come and experience a calming and relaxing session at the
                    Emin Pasha Spa. Book a spa appointment with us, relax and
                    unwind as we take care of you.
                  </p>
                  <p>
                    <span className="flaticon-clock"></span> Daily: 7.00 am to
                    9.00 pm{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-6 bg-cream p-0 order2 valign animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Modern</h6>
                  </div>
                  <h4>Emin Pasha Gym</h4>
                  <p>
                    Come be apart of the Emin Pasha Fitness Gym . Pay for gym
                    membership to have access to our modern gym. We have
                    profesionals who will guide you through your fitness journey
                    to help you achieve your fitness goals.
                  </p>
                  <p>
                    <span className="flaticon-clock"></span> Daily: 6.00 am to
                    9.00 pm{" "}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 p-0 order1 animate-box"
              data-animate-effect="fadeInRight"
            >
              <div className="img">
                <Link to="/fitnessandspa">
                  <img src={gym} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-6 p-0 animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div className="img left">
                <Link to="/fitnessandspa">
                  <img src={pool} alt="" />
                </Link>
              </div>
            </div>
            <div
              className="col-md-6 p-0 bg-cream valign animate-box"
              data-animate-effect="fadeInRight"
            >
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Experiences</h6>
                  </div>
                  <h4>Swimming Pool</h4>
                  <p>
                    All our guests are allowed access to our pool for both
                    adults and children. We also have a provision
                    for guests to make pool party reservations to host parties.
                  </p>
                  <p>
                    <span className="flaticon-clock"></span> Daily: 10.00 am to
                    7.00 pm{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Faqs */}
      <section className="section-padding bg-cream">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-subtitle">F.A.Qs</div>
              <div className="section-title">Spa Etiquette</div>
            </div>
            <div className='col-md-12'>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography style={{ fontFamily: 'Avenirlight', fontSize: 16, fontWeight: 600, marginBottom: 0 }}>Arriving at The Spa</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ fontFamily: 'Avenirlight', fontSize: 16, lineHeight: "21px" }}>
                  Please arrive at The Spa 15 minutes prior to your
                  scheduled treatment and enjoy the calm and serenity of
                  The Spa atmosphere. Arriving late makes it necessary to
                  curtail the time for your treatment.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Typography style={{ fontFamily: 'Avenirlight', fontSize: 16, fontWeight: 600, marginBottom: 0 }}>Mobile Phones</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ fontFamily: 'Avenirlight', fontSize: 16, lineHeight: "21px" }}>
                  Kindly refrain from using your mobile phone in The Spa
                  premises. Please keep your mobile phone on silent mode
                  at all times.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Typography style={{ fontFamily: 'Avenirlight', fontSize: 16, fontWeight: 600, marginBottom: 0 }}>Valuables</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ fontFamily: 'Avenirlight', fontSize: 16, lineHeight: "21px" }}>
                  We provide a secure place for your personal items inside
                  The Spa complex. However, we do not accept liability for
                  loss or damage. We recommend valuable items are stored
                  inside the safe located in your hotel room/suite.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                  <Typography style={{ fontFamily: 'Avenirlight', fontSize: 16, fontWeight: 600, marginBottom: 0 }}>Health Matters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ fontFamily: 'Avenirlight', fontSize: 16, lineHeight: "21px" }}>
                  Please communicate health conditions such as high blood
                  pressure and allergies, as well as pregnancy or any
                  health-related concerns you may have when making your
                  appointment. We do not recommend the consumption of
                  alcohol before or directly after spa treatments or
                  before using any facilities in the Spa and Health Club.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                  <Typography style={{ fontFamily: 'Avenirlight', fontSize: 16, fontWeight: 600, marginBottom: 0 }}>Children</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ fontFamily: 'Avenirlight', fontSize: 16, lineHeight: "21px" }}>
                  The minimum age for entry to The Spa, The Health Club
                  and the hydrothermal facilities is 16 years of age.
                  Children under the age of 16 may use the hotel pool if
                  they are accompanied by a parent/adult.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                  <Typography style={{ fontFamily: 'Avenirlight', fontSize: 16, fontWeight: 600, marginBottom: 0 }}>Safety</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ fontFamily: 'Avenirlight', fontSize: 16, lineHeight: "21px" }}>
                  The max depth of the pool is 1.60 m. There is no
                  lifeguard at the pool. Children may only use the pool if
                  accompanied by an adult or parent/guardian.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                  <Typography style={{ fontFamily: 'Avenirlight', fontSize: 16, fontWeight: 600, marginBottom: 0 }}>Smoking</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ fontFamily: 'Avenirlight', fontSize: 16, lineHeight: "21px" }}>
                  Kindly note that you are not allowed to smoke in The
                  Spa, The Health Club or sauna area.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FitnessAndSpa;