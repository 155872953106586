import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Image2 from "../../images/bars/bar5.jpeg";
import Image1 from "../../images/restaurant/Wine-and-Whiskey.jpg";
import Image3 from "../../images/restaurant/restaurant-and-bar.jpg";
import FineDinningPhoto from "../../images/restaurant/Sir-Baker-Fine-dinning.jpg";
import Image4 from "../../images/bars/bar-new.jpeg";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import "../globalstyles/themify-icons.css";
import "../globalstyles/animate.min.css";
import "../globalstyles/datepicker.css";
import "../globalstyles/flaticon.css";
import "../globalstyles/magnific-popup.css";
import "../globalstyles/owl.theme.default.min.css";
import "../globalstyles/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../globalstyles/style.css";
import { GetAllRestaurants } from '../../shared/utils/api/api';
import Snackbar from '@mui/material/Snackbar';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import Alert from '@mui/material/Alert';

const RestruantsAndBars = (props) => {
  const [restaurantsArray, setRestaurantsArray] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    console.log('xxx useeffect props:', props);
    const fetchRestaurant = async () => {
      props.setShowProgress(true);
      setIsProcessing(true);
      try {
        await GetAllRestaurants().get().then((response) => {
          console.log('xxx restauranr Response:', response);
          setRestaurantsArray(response.data.data);
          setIsProcessing(false);
          console.log('xxx restauranr Response:', response.data.data);
        });
      } catch (error) {
        props.setShowProgress(false);
        setOpenSnackBar(true);
        setIsProcessing(false);
        setErrorMessage('An error occured while fetching data. Reload the page');
        console.log('xxxdebug ERRRORR', error);
      } finally {
        props.setShowProgress(false);
        setIsProcessing(false);
      }
    };
    fetchRestaurant();
  }, []);

  const handleClose = () => {
    setOpenSnackBar(false);
  };

  const getRestaurantSubtitle = (restaurantName) => {
    switch (restaurantName) {
      case 'Hakki Pasha restaurant':
        return 'Experiences';
      case 'Sir Baker Dinning':
        return 'Discover';
      case "Baker's Kitchen":
        return 'Discover';
      case 'FEZ Wine & Bar':
        return 'Experiences';
      default:
        return 'Discover';

    }
  };

  const getRestaurantRoute = (restaurantName, restaurantId) => {
    console.log('::Debug restaurantName:', restaurantName)
    switch (restaurantName) {
      case 'Hakki Pasha restaurant':
        return `/restaurant/hakki-pasha-restaurant/?id=${restaurantId}`;
      case 'Sir Baker Dinning':
        return `/restaurant/sir-baker-dinning/?id=${restaurantId}`;
      case "Baker's Kitchen":
        return `/restaurant/bakers-kitchen/?id=${restaurantId}`;
      case 'FEZ Wine & Bar':
        return `/restaurant/fez-wine-and-bar/?id=${restaurantId}`;
      case 'Manutea Wine & Whisky Lounge':
        return `/restaurant/manutea-wine-and-whisky-lounge/?id=${restaurantId}`;
      case 'Rooftop bar':
        return `/restaurant/rooftop-bar/?id=${restaurantId}`;
      default:
        return `/restaurant/hakki-pasha-restaurant/?id=${restaurantId}`;

    }
  };

  const getRestaurantImage = (restaurantName) => {
    switch (restaurantName) {
      case 'Hakki Pasha restaurant':
        return Image3;
      case 'Sir Baker Dinning':
        return FineDinningPhoto;
      case "Baker's Kitchen":
        return Image3;
      case 'FEZ Wine & Bar':
        return Image3;
      case 'Manutea Wine & Whisky Lounge':
        return Image1;
      case 'Rooftop bar':
        return Image4;
      default:
        return Image3;

    }
  };

  return (
    <div>
      <div
        className="banner-header section-padding valign bg-img bg-fixed bg-bar"
        data-overlay-dark="4"
        data-background="../../images/slider/1.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 caption mt-90">
              <span>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
              </span>
              <h5>The Emin Pasha Hotel</h5>
              <h1>Restaurant & Bar</h1>
            </div>
          </div>
        </div>
      </div>
      <section class="services section-padding">
        <div class="container">
          <div class="row">
            <div className="col-md-12">
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSnackBar}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert variant="filled" severity="error">{errorMessage}</Alert>
              </Snackbar>
              {/* <div className='restuarant-banner'>
                <div className="restuarant-banner-message">
                  <p>We offer Take out and Food Delivery <Link data-scroll-nav="1" to='/orders/order-landing'><span> Place Order</span></Link></p>
                </div>
              </div> */}
            </div>
            {/* <div
              class="col-md-6 p-0 animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div class="img left">
                <Link to="/restaurant/sir-baker-dinning">
                  <img src={Image1} alt="" />
                </Link>
              </div>
            </div>
            <div
              class="col-md-6 p-0 bg-cream valign animate-box"
              data-animate-effect="fadeInRight"
            >
              <div class="content">
                <div class="cont text-left">
                  <div class="info">
                    <h6>Discover</h6>
                  </div>
                  <h4>Sir Baker Dinning</h4>
                  <p>
                    Sir Baker Dinning is celebrated for its excellent cuisine and
                    unique ambience. The gorgeous dining room features three
                    open studio kitchens, allowing you to enjoy the sights and
                    sounds of the culinary artistry on display. The menu
                    showcases Asian, European and African influences, with a
                    tempting selection of classic favorites and creative dishes
                    for you to sample.
                  </p>
                  <div class="butn-dark">
                    {" "}
                    <Link to="/restaurant/sir-baker-dinning">
                      <span>Read More</span>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div class="rowxx">
            {
              restaurantsArray.length > 0 ? (
                restaurantsArray.map((restaurant,idx) => {
                  return (
                    idx % 2 === 1 ? (
                    <div className='restaurant-conatiner row' key={restaurant.id}>
                      <div
                        class="col-md-6 p-0 order1 animate-box"
                        data-animate-effect="fadeInRight"
                      >
                        <div class="img">
                          <Link to={getRestaurantRoute(restaurant.res_name, restaurant.id)}>
                            <img src={getRestaurantImage(restaurant.res_name)} alt="" />
                          </Link>
                        </div>
                      </div>
                      <div
                        class="col-md-6 bg-cream p-0 order2 valign animate-box"
                        data-animate-effect="fadeInLeft"
                      >
                        <div class="content">
                          <div class="cont text-left">
                            <div class="info">
                              <h6>{getRestaurantSubtitle(restaurant.res_name)}</h6>
                            </div>
                            <h4>{restaurant.res_name}</h4>
                            <p>
                              {restaurant.res_description}
                            </p>
                            <div class="butn-dark">
                              {" "}
                              <Link to={getRestaurantRoute(restaurant.res_name, restaurant.id)}>
                                <span>Read More</span>
                              </Link>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    ):(
                      <div className='restaurant-conatiner row' key={restaurant.id}>
                      <div
                        class="col-md-6 bg-cream p-0 order2 valign animate-box"
                        data-animate-effect="fadeInLeft"
                      >
                        <div class="content">
                          <div class="cont text-left">
                            <div class="info">
                              <h6>{getRestaurantSubtitle(restaurant.res_name)}</h6>
                            </div>
                            <h4>{restaurant.res_name}</h4>
                            <p>
                              {restaurant.res_description}
                            </p>
                            <div class="butn-dark">
                              {" "}
                              <Link to={getRestaurantRoute(restaurant.res_name, restaurant.id)}>
                                <span>Read More</span>
                              </Link>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-6 p-0 order1 animate-box"
                        data-animate-effect="fadeInRight"
                      >
                        <div class="img">
                          <Link to={getRestaurantRoute(restaurant.res_name, restaurant.id)}>
                            <img src={getRestaurantImage(restaurant.res_name)} alt="" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    )
                  )
                })

              ) : (
                <div className='cart-empty'>
                  <div className="row">
                    <div style={{ display: 'flex', justifyContent: 'center' }} className="col-md-12">
                      <HourglassEmptyIcon style={{ color: '#cdcccc', fontSize: 83 }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }} className='col-md-12'>
                      <p style={{ fontWeight: 'bold', fontSize: 29 }}>{isProcessing ? 'Loading data' : 'No data available'} </p>
                    </div>
                  </div>
                </div>
              )
            }

          </div>
          {/* <div class="row">
            <div
              class="col-md-6 p-0 animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div class="img left">
                <Link to="/restaurant/bakers-kitchen">
                  <img src={Image4} alt="" />
                </Link>
              </div>
            </div>
            <div
              class="col-md-6 p-0 bg-cream valign animate-box"
              data-animate-effect="fadeInRight"
            >
              <div class="content">
                <div class="cont text-left">
                  <div class="info">
                    <h6>Discover</h6>
                  </div>
                  <h4>Baker's Kitchen</h4>
                  <p>
                    Manutea Wine & Whisky Lounge is celebrated for its excellent cuisine and
                    unique ambience. The gorgeous dining room features three
                    open studio kitchens, allowing you to enjoy the sights and
                    sounds of the culinary artistry on display. The menu
                    showcases Asian, European and African influences, with a
                    tempting selection of classic favorites and creative dishes
                    for you to sample.
                  </p>
                  <div class="butn-dark">
                    {" "}
                    <Link to="/restaurant/bakers-kitchen">
                      <span>Read More</span>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-6 bg-cream p-0 order2 valign animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div class="content">
                <div class="cont text-left">
                  <div class="info">
                    <h6>Experiences</h6>
                  </div>
                  <h4>FEZ Wine & Bar</h4>
                  <p>
                    FEZ Wine & Bar is a vibrant bar at the heart of The Emin
                    Pasha Hotel. That combines the charm of Kampala's night life
                    and hospitality with the sophistication and quality of an
                    upscale and luxurious, chef-driven restaurant.
                  </p>
                  <div class="butn-dark">
                    {" "}
                    <Link to="/restaurant/fez-wine-and-bar">
                      <span>Read More</span>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 p-0 order1 animate-box"
              data-animate-effect="fadeInRight"
            >
              <div class="img">
                <Link to="/restaurant/fez-wine-and-bar">
                  <img src={Image2} alt="" />
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default RestruantsAndBars;