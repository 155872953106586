import React from 'react';
import EminPashaImage from '../../images/logo/emin.jpg';

const AboutHotel = () => {
  return (
    <div className='about-hotel-page'>
      {/* Header Banner */}
      <div
        className='banner-header section-padding valign bg-img bg-fixed'
        data-overlay-dark='4'
        data-background='img/slider/1.jpg'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 caption mt-90'>
              <h5>The Emin Pasha Hotel</h5>
              <h1>About Us</h1>
            </div>
          </div>
        </div>
      </div>

      <section id='preface-section' className="rooms-page section-padding" data-scroll-index="1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {" "}
              <span>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
              </span>
              <div className="section-subtitle">We provide the most luxurious services</div>
              <div className="section-title">Message From The General Manager</div>
              <p className="mb-1">
                Welcome to The Emin Pasha Hotel and Spa, where a collage of history,
                culture, and nature embrace you in a pouch of serenity! Just like Emin
                Pasha, the distinguished physician and anthropologist,
                The Emin Pasha Hotel and Spa embodies a bold spirit of unity, humanity
                and an appreciation of nature’s marvels. {" "}
              </p>
              <p className='mb-30'>
                Journey through time aboard our
                historically charming architecture dotted with tasteful décor that is
                infused with Uganda’s rich heritage. Be it a moment of quiet reflection
                or pulse racing rendezvous, let our bespoke hospitality envelope you in a
                sense of homecoming and distinct rejuvenation!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id='content-history-section' className="rooms-page section-padding" data-scroll-index="1">
        <div className="container">
          <div className='history-section'>
            <div className="row">
              <div className="col-md-12">
                <div className="section-subtitle" style={{color: '#fae7cb'}}>The Emin Pasha Story | Who was Emin Pasha?</div>
                <div className="section-title" style={{color: '#e4a853'}}>Our History</div>
              </div>
              <div className="col-md-8">
                <span style={{color: '#d3d1d1'}}>
                <p>
                  Emin Pasha was truly one of the most extraordinary characters in Ugandan history. He was a doctor, naturalist, and linguist; an African at heart, Emin Pasha was a truly unique character among all the bravado and machismo of the colonial age. His aim was not to conquer but to understand and learn, and in doing so he respected and fell in love with this country. We cannot imagine a more appropriate and relevant person to name our hotel after.
                </p>

                <p>
                  His link to Uganda dates back to the late 1800's, during the time of the "Scramble for Africa". An explorer, physician, and linguist, he was also fascinated by anthropology, botany, zoology, and meteorology. And, more importantly, he was actively against the slave trade.
                </p>

                <p>
                  Born Eduard Carl Oscar Theodor Schnitzer in Germany, Emin Pasha was based for some time in Albania where he was in medical practice, putting his linguistic talent to good use, adding Turkish, Albanian, and Greek to his repertoire of western European languages. 
      
                </p>

                <p>
                He became the quarantine officer of the port, leaving only in 1870 to join the staff of Ismail Hakki Pasha, governor of northern Albania, in whose service he travelled extensively throughout the Ottoman Empire.
                  In 1875, Emin Pasha reappeared on the scene in Cairo and then Khartoum. 
                </p>
                </span>

                
              </div>
              <div className="col-md-4">
                <img src={EminPashaImage} alt='' style={{width:'auto', height:'450px' }}/>
              </div>
              <div className="col-md-12 mt-4">
              <p>
                  At this point he took the name "Mehemet Emin" (Arabic for Muhammad al-Amin), started a medical practice, and began collecting plants, animals, and birds, many of which he sent to museums in Europe. Although some regarded him as a Muslim, it is not clear if he ever actually converted.
                </p>

                <p>
                  Charles Gordon, then governor of Equatoria - which is now northern Uganda and Southern Sudan - heard of Emin's presence and invited him to be the chief medical officer of the province; Emin assented and arrived there in May 1876. Gordon immediately sent Emin on diplomatic missions to Buganda and Bunyoro to the south (Uganda) where Emin's modest style and fluency in Luganda were quite popular.

                  In 1878, the Khedive of Egypt appointed Emin as Gordon's successor as Governor, giving him the title of Bey. Despite the grand title, there was little for Emin to do; his military force consisted of a few thousand soldiers who controlled no more than a mile's radius around each of their outposts, and the government in Khartoum was indifferent to his proposals for development.
                </p>

                <p>
                  The Mahdi Rebellion began in 1881 and cut Equatoria off from the outside world. In 1885 General Gordon was killed in Khartoum and Emin and most of his forces withdrew further south, to Wadelai on the upper Nile near Lake Albert. Cut off from communications to the north, he was still able to exchange mail with Zanzibar through Buganda. Determined to remain in Equatoria, his communiqués aroused considerable sentiment in Europe in 1886, particularly acute after the death of Gordon.
                </p>

                <p>
                  The Emin Pasha Relief Expedition, led by Henry Morton Stanley, was one of the best-equipped ever to go to Africa. Strangely, rather than go the direct route from the East Africa coast, Stanley undertook to rescue Emin by going up the Congo River and then through the Ituri Forest, an extraordinarily difficult route that resulted in the loss of two-thirds of the expedition. Stanley finally met Emin in April 1888 and was surprised to find the figure of Emin to have "not a trace on it of ill-health or anxiety". They celebrated with three bottles of champagne that had been carried all the way up the Congo. Despite the celebration, Emin Pasha had no desire to leave, felt no need to be rescued and refused to leave the country he had grown to love.
                </p>

                <p>
                  After a year spent in argument and indecision, Stanley finally convinced him to leave for the coast. Marching through new country, exploring the Semliki River, Mount Ruwenzori, and Lakes Edward and George, Stanley and his followers made their way by the south of the Victoria Nyanza, reaching Bagamoyo in 1890. Ironically and amusingly, at a welcome reception in Bagamoyo, Emin Pasha fell out of a 2nd-story window and cracked his head open. After all the delays, Stanley, upset and impatient, left Emin behind. Emin then entered German service, and led an expedition to the lakes in the interior, but was killed by slave traders at Kinene.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutHotel;
