import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Carousel from "react-multi-carousel";
import { CurrencyFormat } from '../../shared/Formatter';
import { Link } from 'react-router-dom';
import useBreakPoint from '../../shared/breakPoints/useBreakPoint'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { starterArray } from '../../shared/constants/Restauarant';
import NoImage from '../../images/restaurant/no-pictures.png'
import { AddProductToCart, GetProductById } from '../../shared/utils/api/api';

const mapDispatchToProps = dispatch => {
  return {
    addItemToCart: product => dispatch({ type: 'ADD_TO_CART', payload: product }),
    setOrderType: product => dispatch({ type: 'SET_ORDER_TYPE', payload: product }),
  };
};

const OrderProductDetails = (props) => {
  const [SelectedOrder, setSelectedOrder] = useState(null);
  const location = useLocation();
  const { matchesDesktop } = useBreakPoint();
  const queryParams = new URLSearchParams(location.search);
  const roomId = queryParams.get('id');
  console.log('::Debug my am hereeee:');

  useEffect(() => {
    props.setShowProgress(true);
    const getProductById = () => {
      GetProductById(roomId).then(response => {
        console.log('::Debug my response:', response);
        props.setShowProgress(false);
      }).catch(() => {
        props.setShowProgress(false);
      })
    }
    getProductById();
  }, [props, roomId]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const AddItemToCart = async (product) => {
    try {
        await AddProductToCart({
            product_id: product.id,
            quantity: 1
        }).then((response) => {
          console.log('::debug Booking cart response:', response.data);
        });
      } catch (error) {
        console.log('::debug BOOKing cart eerror:', error);
      }
}

  return (
    <div className='menu-item-details'>
      <div id='order-list-page'>
        {/* Header Banner */}
        <div
          className='banner-header section-padding valign bg-orderimg bg-fixed'
          data-overlay-dark='4'
          data-background='img/slider/1.jpg'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 caption mt-90'>
                <h5>The Emin Pasha Hotel</h5>
                <h1>Restaurant Menu Item Details </h1>
              </div>
            </div>
          </div>
        </div>
        <section id='order-dashboard' className="facilties section-padding bg-order-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-subtitle">Menu Item Details Page</div>
                <div className="section-title">Menu Item</div>
              </div>
            </div>
            <div className='row'>
              <div className="col-md-6">
                <div className="order-now-img">
                  {
                    SelectedOrder && SelectedOrder.item_images.length > 0 ? 
                      <img src={SelectedOrder.item_images[0].image_name} alt="" />
                      :
                      <img 
                        src={NoImage}
                        alt='' 
                        style={{ width: '200px', height: '200px'}}
                      />
                    
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ marginTop: matchesDesktop ? 0 : 20}} className="order-now-detail">
                  <h5>{SelectedOrder ? SelectedOrder.title : ''}</h5>
                  <div className="amount-tag">
                    <p>{`${CurrencyFormat(SelectedOrder ? SelectedOrder.price : 0)} USD`}</p>
                  </div>
                  <div className="btn-row-section mt-5">
                    <div className="">
                      <div className="btn-order">
                        {" "}
                        <button style={{ width: '80%' }} onClick={() => {
                          props.addItemToCart(SelectedOrder);
                          props.setOrderType(true);
                        }}>
                          <span>Add To Cart</span>
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="similar-room" className="news section-padding bg-black">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-subtitle">
                  <span>Restaurant Menu Item</span>
                </div>
                <div className="section-title">
                  <span>Related Menu Items</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div id='order-list-page' className="header-sec menu-item-details slider-fade">
                  <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={responsive}
                    ssr={false} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={4000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >

                    {
                      starterArray.length > 0 &&

                      starterArray.map(order => {
                        return (
                          <div key={order.id} className=''>
                            <div class="item pr-3">
                              <div class="position-re o-hidden">
                                {" "}
                                <Link style={{ width: '100%'}} to="/orders/order-product-details" state={{ orderObj: order }}>
                                  <img src={order.image} alt="" />
                                </Link>
                                
                                {
                                  order.slug && (
                                    <div class="date">
                                      <Link to="/orders/order-product-details" state={{ orderObj: order }}>
                                        {" "}
                                        <span>{order.slug}</span> <i>{order.slug_text}</i>{" "}
                                      </Link>
                                    </div>
                                  )
                                }
                              </div>
                              <div class="con">
                                {" "}
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="order-item-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                      <div className="order-data">
                                        <span class="category">
                                          <Link to="/orders/order-product-details" state={{ orderObj: order }}>{order.name}</Link>
                                        </span>
                                        <h5>
                                          {CurrencyFormat(order.price ?? 0) + ' USD'}
                                        </h5>
                                      </div>
                                      <div className="order-cart">
                                        <AddShoppingCartIcon onClick={() => { props.addItemToCart(order); props.setOrderType(true); }} style={{ cursor: 'pointer' }} />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="order-list-btn-row pt-3">
                                  <div className="order-now-btn">
                                    {" "}
                                    <Link to="/orders/ordernow" style={{ width: '100%'}} data-scroll-nav="1" state={{ orderObj: order }}>
                                      <span>Order Now</span>
                                    </Link>{" "}
                                  </div>

                                  <div style={{ marginTop: 10}} className="btn-order">
                                    {" "}
                                    <Link style={{ width: '100%'}} to="/orders/orderforlater" data-scroll-nav="1" state={{ orderObj: order }}>
                                      <span>Order For Later</span>
                                    </Link>{" "}
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        );
                      })

                    }
                  </Carousel>
                </div>

              </div>
            </div>
          </div >
        </section >
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(OrderProductDetails);
