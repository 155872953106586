import React, { useState, useEffect, useRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@material-ui/core';
import { GetUserReservations } from './../../shared/utils/api/api';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {useNavigate} from 'react-router-dom';
import './style.css';

const rowsPerPageOptions = [5, 10, 25];

const Reservation = (props) => {
    const [page, setPage] = useState(0);
    const [hidePrintSection, setHidePrintSection] = useState(false);
    const [userObject, setUserObject] = useState(null);
    const [bookingData, setBookingData] = useState(null);
    const [userReservation, setUserReservation] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const pdfContainerRef = useRef(null);

    const generatePDF = () => {
        const input = pdfContainerRef.current;

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF(); // Instantiate jsPDF
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save('generated.pdf');
        });
    };

    const data = [
        { id: 1, name: 'John', age: 25 },
        { id: 2, name: 'Jane', age: 30 },
        // ...more data
    ];

    const navigate = useNavigate();

    // const getAuthenticatedUser = () => {
    //     try {
    //       GetAuthenticatedUser().then(response => {
    //         console.log('::debug auth user:', response);
    //         localStorage.setItem('userData', JSON.stringify(response.data));
    //       })
    //     } catch (error) {
    //     }
    //   };

    useEffect(() => {
        props.setShowProgress(true);
        const userData = localStorage.getItem('userData')
        if (userData) {
            setUserObject(userData)
            GetUserReservations().then(response => {
                props.setShowProgress(false);
                setUserReservation(response.data);
                console.log('::debug response:', response);
            }).catch(() => {
                props.setShowProgress(false);
                setOpenSnackBar(true);
                setErrorMessage()
            });
        }else{
            props.setShowProgress(false);
            navigate('/auth/login');
        }
        // LoginUser('mulumbanasf', 'Password!123').then(response => {
        //     console.log('::debug login successful:', response);
        //     localStorage.setItem('access_token', response.data.access_token);
        //     localStorage.setItem('access_token_expiry', response.data.access_expiration);
        //     localStorage.setItem('refresh_token', response.data.refresh_token);
        //     getAuthenticatedUser();

        //   });
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClose = () => {
        setOpenSnackBar(false);
    };

    return (
        <div id='order-list-page'>
            {/* Header Banner */}
            <div
                className='banner-header section-padding valign bg-orderimg bg-fixed'
                data-overlay-dark='4'
                data-background='img/slider/1.jpg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 caption mt-90'>
                            <h5>The Emin Pasha Hotel</h5>
                            <h1>User Reservations</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section id='order-dashboard' className="facilties section-padding bg-order-page">
                <div className="container">
                    <div className="row">
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={openSnackBar}
                            autoHideDuration={6000}
                            onClose={handleClose}
                        >
                            <Alert variant="filled" severity="error">{errorMessage}</Alert>
                        </Snackbar>
                        <div className='col-md-12'>
                            <div className='user-details'>
                                <div className='row'>
                                    {/* <div className='col-md-12'>
                                        <div className="section-title" style={{ color: '#aa8453', fontSize: 30, fontWeight: 'bold', marginTop: 31 }}>User Details</div>
                                    </div>
                                    <div className='col-md-6'>
                                        <p><strong>First Name:</strong> <span className=''>{userObject.firstName}</span></p>
                                    </div>
                                    <div className='col-md-6'>
                                        <p><strong>Last Name:</strong> <span className=''>{userObject.lastName}</span></p>
                                    </div>
                                    <div className='col-md-6'>
                                        <p><strong>Email:</strong> <span className=''>{userObject.email}</span></p>
                                    </div>
                                    <div className='col-md-6'>
                                        <p><strong>Phone:</strong> <span className=''>{userObject.phone}</span></p>
                                    </div> */}
                                    {userReservation && userReservation.length > 0 &&
                                        <div className='col-md-12'>
                                            <div className="section-title" style={{ color: '#aa8453', fontSize: 30, fontWeight: 'bold', marginTop: 31 }}>Room Reservation History</div>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead style={{ backgroundColor: '#f7efe4' }}>
                                                        <TableRow>
                                                            <TableCell><strong>Room Type</strong></TableCell>
                                                            <TableCell><strong>Checkin Date</strong></TableCell>
                                                            <TableCell><strong>Checkout Date</strong></TableCell>
                                                            <TableCell><strong>Amount</strong></TableCell>
                                                            <TableCell><strong>Action</strong></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                                            <TableRow key={row.id} className={index % 2 === 0 ? 'odd-row' : 'even-row'}>
                                                                {/* <TableCell>{row.reservations.roomsBooked.room_name}</TableCell>
                                                                <TableCell>{row.start_date}</TableCell>
                                                                <TableCell>{row.end_date}</TableCell>
                                                                <TableCell>{row.total_cost}</TableCell> */}
                                                                <TableCell style={{ cursor: 'pointer' }} onClick={generatePDF}><FontAwesomeIcon icon={faDownload} color="#dfab67" /></TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                                <TablePagination
                                                    rowsPerPageOptions={rowsPerPageOptions}
                                                    component="div"
                                                    count={data.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </TableContainer>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            hidePrintSection && (
                                <div className='col-md-12'>
                                    <button onClick={generatePDF}>Download PDF</button>
                                    <div className='reservation-download-details'>
                                        <div ref={pdfContainerRef}>
                                            <div class="background-overlay"></div>
                                            <div class="receipt-container">
                                                <h2>Emin Pasha Hotel Room Reservation Receipt</h2>
                                                <div class="hotel-details">
                                                    <p><small>Emin Pasha Hotel</small></p>
                                                    <p><small>Plot 27 Akii Bua Road, Kampala Uganda</small></p>
                                                    <p><small>info@eminpasha.com</small></p>
                                                    <p><small>+256 312 264 712</small></p>
                                                </div>
                                                <div class="float-right date-section">
                                                    #{bookingData.id}
                                                </div>
                                                <table>
                                                    <tr>
                                                        <th>Guest Name: <span class="font-weight-normal">{userObject.firstName + ' ' + userObject.lastName}</span></th>
                                                    </tr>
                                                    <tr>
                                                        <th>Reservation Number: <span class="font-weight-normal">{bookingData.reservations.id}</span></th>
                                                    </tr>
                                                    <tr>
                                                        <th>Check-in Date: <span class="font-weight-normal">{bookingData.start_date}</span></th>
                                                        <th>Check-out Date: <span class="font-weight-normal">{bookingData.end_date}</span></th>
                                                    </tr>
                                                    <tr>
                                                        <th>Number of Nights: <span class="font-weight-normal">{bookingData.total_days}</span></th>
                                                    </tr>
                                                </table>
                                                <table>
                                                    <tr>
                                                        <th>Room Number</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                    {
                                                        bookingData.reservations.roomsBooked.map(room => {
                                                            return (
                                                                <tr>
                                                                    <td>{room.room_name}</td>
                                                                    <td>{room.room_base_price}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </table>
                                                <table>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Name</th>
                                                    </tr>
                                                    {
                                                        bookingData.reservations.roomsBooked.roomAmenities.map(amenity => {
                                                            return (
                                                                <tr>
                                                                    <td>{amenity.id}</td>
                                                                    <td>{amenity.name}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </table>
                                                {/* <table>
                                            <tr>
                                                <th colspan="2">Subtotal:  <span class="font-weight-normal">{'$'+bookingData.total_cost}</span></th>
                                            </tr>
                                            <tr>
                                                <th colspan="2">Tax:  <span class="font-weight-normal">$0</span></th>
                                            </tr>
                                            <tr>
                                                <th colspan="2">Total:  <span class="font-weight-normal">{'$'+bookingData.total_cost}</span></th>
                                            </tr>
                                        </table> */}
                                                <table>
                                                    <tr>
                                                        <th>Payment Method:  <span class="font-weight-normal">{bookingData.payment_type}</span></th>
                                                    </tr>
                                                    {/* <tr>
                                                <th>Payment Date:  <span class="font-weight-normal">[Payment Date]</span></th>
                                            </tr> */}
                                                    <tr>
                                                        <th>Amount Paid:  <span class="font-weight-normal">{'$' + bookingData.total_cost}</span></th>
                                                    </tr>
                                                    <tr>
                                                        <th>Remaining Balance:  <span class="font-weight-normal">$[Remaining Balance]</span></th>
                                                    </tr>
                                                </table>
                                                <p>Thank you for choosingEmin Pasha hotel. We appreciate your business. If you have any further questions or need assistance, please don't hesitate to contact us.</p>
                                                <div class="hotel-regard">
                                                    <p>Warm regards,</p>
                                                    <p>Emin Pasha Hotel Management</p>
                                                </div>
                                                <div class="qr-code">
                                                    <img src="https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=[Reservation Number]" alt="QR Code" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
            </section>
        </div>
    );
};

export default Reservation;
