import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";

function Bookingform() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [numberOfAdults, setNumberOfAdults] = useState('1');
  const [numberOfChildren, setNumberOfChildren] = useState("0");
  const currentDate = new Date();

  const handleAdultChange = (event) => {
    console.log(event.target.value);
    setNumberOfAdults(event.target.value);
  };

  const handleChildrenChange = (e) => {
    setNumberOfChildren(e.target.value);
  };

  return (
    <div>
      {/*  Reservation & Booking Form */}
      <section className="testimonials">
        <div
          className="background bg-img bg-fixed section-padding pb-0"
          data-background="img/slider/2.jpg"
          data-overlay-dark="2"
        >
          <div className="container">
            <div className="row">
              {/*  Reservation */}
              <div className="col-md-5 mb-30 mt-30">
                <p>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                </p>
                <h5>
                  Each of our guest rooms feature a private bath, wi-fi, cable
                  television and include full breakfast.
                </h5>
                <div className="reservations mb-30">
                  <div className="icon color-1">
                    <span className="flaticon-call"></span>
                  </div>
                  <div className="text">
                    <p className="color-1">Reservation</p>{" "}
                    <a className="color-1" href="tel:+256 312 264 712">
                      +256 312 264 712 /4
                    </a>
                  </div>
                </div>
                <p>
                  <i className="ti-check"></i>
                  <small>Call us, it's toll-free.</small>
                </p>
              </div>
              {/*  Booking From  */}
              <div className="col-md-5 offset-md-2">
                <div className="booking-box">
                  <div className="head-box">
                    <h6>Rooms & Suites</h6>
                    <h4>Hotel Booking Form</h4>
                  </div>
                  <div className="booking-inner clearfix vertical-check-form">
                    <form
                      className="form1 clearfix"
                    >
                      <div className="row">
                        {/* <div className="col-md-12">
                          <div className="input1_wrapper">
                            <label>Check in</label>
                            <div className="input1_inner" style={{ borderBottom: '1px solid #eee'}}>
                             
                              <DatePicker
                                selected={startDate}
                                placeholderText='Check in'
                                onChange={(date: Date) => setStartDate(date)}
                                minDate={currentDate}
                                className="form-control"
                                customInput={
                                  <input
                                    type='text'
                                    className='form-control input datepicker hasDatepicker'
                                    placeholder='Check in'
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="input1_wrapper" style={{ borderBottom: '1px solid #eee'}}>
                            <label>Check out</label>
                            <div className="input1_inner">
                              
                              <DatePicker
                                selected={endDate}
                                placeholderText='Check out'
                                onChange={(date: Date) => setEndDate(date)}
                                minDate={startDate}
                                className="form-control"
                                customInput={
                                  <input
                                    type='text'
                                    className='form-control input datepicker hasDatepicker'
                                    placeholder='Check out'
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="select1_wrapper">
                            <label>Adults</label>
                            <div className="select1_inner" style={{ borderBottom: '1px solid #eee'}}>
                              <select
                                className="select2 select"
                                style={{ width: "100%" }}
                                value={numberOfAdults}
                                onChange={e => handleAdultChange(e)}
                              >
                                <option value="0">Adult</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="select1_wrapper">
                            <label>Children</label>
                            <div className="select1_inner">
                              <select
                                className="select2 select"
                                style={{ width: "100%" }}
                                value={numberOfChildren}
                                onChange={e => handleChildrenChange(e)}
                              >
                                <option value="0">No Child</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                              </select>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-12">
                          <Link
                            className= "btn-form1-submit mt-15"
                            to="/new-booking"
                            state={{
                              startDate: startDate, 
                              endDate: endDate, 
                              numberOfAdults: numberOfAdults,
                              numberOfChildren: numberOfChildren
                            }}
                          >
                            <span>Check Availability</span>
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Bookingform;
