import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../globalstyles/themify-icons.css';
import '../globalstyles/animate.min.css';
import '../globalstyles/datepicker.css';
import '../globalstyles/flaticon.css';
import '../globalstyles/magnific-popup.css';
import '../globalstyles/owl.theme.default.min.css';
import '../globalstyles/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../globalstyles/select2.css';
import '../globalstyles/style.css';
import NoImageLogo from '../../images/img-not-found.png';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { FormatDate } from '../../shared/Formatter/index';
import { CurrencyFormat } from '../../shared/Formatter';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { AddRoomToBookingCart, DeleteRoomFromCart, GetRoomsInCart } from '../../shared/utils/api/api';

const mapDispatchToProps = dispatch => {
  return {
    addRoomToCart: product => dispatch({ type: 'ADD_ROOM_TO_CART', payload: product }),
    removeItem: product =>
      dispatch({ type: 'REMOVE_ROOM_FROM_CART', payload: product }),
  };
};

const mapStateToProps = state => {
  return {
    cartItems: state,
  };
};

const AvailableRooms = (props) => {
  console.log('::debug available room prop:', props);
  const [availableRoomsArray, setAvailableRoomsArray] = useState([]);
  const [roomsInCart, setRoomsInCart] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const startDate = queryParams.get('startdate');
  // const endDate = queryParams.get('enddate');
  // const adults = queryParams.get('adults');
  // const children = queryParams.get('children');
  // const roomId = queryParams.get('roomid');
  console.log('::debug availableRoomsArray:', availableRoomsArray);

  // const getRoomAvailability = async () => {
  //   var locationStartDate = startDate;
  //   var locationEndDate = endDate;
  //   var locationNumberOfAdults = adults;
  //   var locationNumberOfChildren = children;
  //   var locationRoomId = roomId;

  //   props.setShowProgress(true);
  //   setIsNextButtonDisabled(true);
  //   try {
  //     if (locationRoomId) {
  //       await CheckSpecificRoomAvailability({
  //         checkInDate: FormatDate(startDate),
  //         checkOutDate: FormatDate(endDate),
  //         room_id: locationRoomId,
  //         adults: locationNumberOfAdults ?? numberOfAdults
  //       }).then((response) => {
  //         setAvailableRoomsArray(response.data);
  //       });
  //     } else {
  //       await CheckRoomAvailability({
  //         checkInDate: FormatDate(startDate ? startDate : locationStartDate),
  //         checkOutDate: FormatDate(endDate ? endDate : locationEndDate),
  //         adults: locationNumberOfAdults ? locationNumberOfAdults : numberOfAdults,
  //         children: locationNumberOfChildren ? locationNumberOfChildren : numberOfChildren,
  //       }).then((response) => {
  //         console.log('::debug available roomsxx:', response.data);

  //         setAvailableRoomsArray(response.data);
  //       });
  //     }

  //     await GetRoomEnhancements().get().then((response) => {
  //       console.log('::debug GetRoomEnhancements:', response);
  //       setEnhancementsArr(response.data.data);
  //     });
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //     navigate(location.pathname, { replace: true });
  //   } catch (error) {
  //     props.setShowProgress(false);
  //     setIsNextButtonDisabled(false);
  //     setOpenSnackBar(true);
  //     console.log('::error',error);
  //     //console.log(error.response.data.errors.query._schema[0]);
  //     setErrorMessage(error.response.data.errors.query._schema[0]);
  //     // console.log(error.response.data.message);

  //   } finally {
  //     props.setShowProgress(false);
  //     setIsNextButtonDisabled(false);
  //   }
  // };

  useEffect(() => {
    if(props.availableRooms.rooms && props.availableRooms.rooms.length > 0){
      setAvailableRoomsArray(props.availableRooms.rooms);
      fetchRoomsInCart();
    } else {
      props.setStep(0);
    }
    
  }, [props.availableRooms]);

  const fetchRoomsInCart = async () => {
    props.setShowProgress(true);
    try{
      await GetRoomsInCart().then((response) => {
        console.log('::xxxnew rooms in cart:', response);
        const objectResponse = response.data.data.room_cart;
        
        var convertedArray = [];
        // if(typeof objectResponse === 'object'){
        //   console.log('::xxxnew# obj-Response:', objectResponse[1]);
        //   convertedArray.push(objectResponse[1]);
        //   console.log('::xxxnew# convertedArray:', convertedArray);
        // } else{
        //   convertedArray = objectResponse;
        // }

        convertedArray = objectResponse;
        
        console.log('::xxxnew rooms in cart convertedArray:', convertedArray);
        props.setRoomsInCartLength(convertedArray.length);
        setRoomsInCart(convertedArray);
      });
      props.setShowProgress(false);
    } catch (error) {
      props.setShowProgress(false);
      console.log('::debug erorzxxx:', error);
      setOpenSnackBar(true);
      setErrorMessage(error.response.data.errors.query._schema[0]);
    }
  };

  const FuncAddRoomToBookingCart = async (room) => {
    props.setShowProgress(true);
    console.log('::debug FuncAddRoomToBookingCart room:', room);
    const children = props.numberOfChildren ? parseInt(props.numberOfChildren) : 0;
    const occupants = props.numberOfAdults ? parseInt(props.numberOfAdults) : 0;
    try {
      await AddRoomToBookingCart({
        room_id: room.roomID,
        roomType: room.roomTypeID,
        occupants: occupants,
        children: children,
        roomQuantity: 1,
        checkInDate: FormatDate(props.startDate),
        checkOutDate: FormatDate(props.endDate)
      }).then((response) => {
        console.log('::debug Booking cart response:', response);
        fetchRoomsInCart();
      });
    } catch (error) {
      console.log('::debug BOOKing cart eerror:', error);
    }
  };

  const funcRemoveRoomFromBookingCart = async (room) => {
    console.log('::debug Room cart delete:', room);
    props.setShowProgress(true);
    try {
      await DeleteRoomFromCart(room.roomID).then((response) => {
        console.log('::debug delete Booking cart response:', response);
        fetchRoomsInCart();
      });
      props.setShowProgress(false);
    } catch (error) {
      props.setShowProgress(false);
      console.log('::debug delete BOOKing cart eerror:', error);
    }

  };

  const checkIfRoomIsInCart = (room) => {
    console.log('::checkIfRoomIsInCart roomsInCart', roomsInCart);
    //props.removeItem(room); props.addRoomToCart(room);
    const inCart = roomsInCart.some(cartItem => cartItem?.room_id === room.roomID);
    if (inCart) {
      return (
        <div className="button-outline-danger">
          <button onClick={() => { funcRemoveRoomFromBookingCart(room); }}>
            <span>Detach</span>
          </button>
        </div>

      );
    }
    return (
      <div className="button-outline">
        <button onClick={() => { FuncAddRoomToBookingCart(room); }}>
          <span>Add Room</span>
        </button>
      </div>

    );
  };

  const handleSelectRoom = async (room) => {
    console.log('::debug selected room:', room);
    // await props.addRoomToCart(room);
    await FuncAddRoomToBookingCart(room);  

    props.setStep(2);
  }

  const handleClose = () => {
    setOpenSnackBar(false);
  };

  return (
    <div id="available-rooms-page">
      <section className="mt-5 pb-5">
        <div className="container">
          <div className="row">
          <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={openSnackBar}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert variant="filled" severity="error">{errorMessage}</Alert>
            </Snackbar>
            {
              availableRoomsArray.length > 0 ? (
                availableRoomsArray.map((room) => {
                  return (
                    <div key={room.id} className="col-md-12">
                      <div
                        className="rooms2 mb-90 animate-box"
                        data-animate-effect="fadeInUp"
                      >
                        <figure>
                          {
                            room?.roomImages && room?.roomImages.length > 0 ? (
                              <>
                               <img src={room.roomImages[0].imageURL} alt="" className="img-fluid" />
                              </>
                            ):(
                              <img src={NoImageLogo} alt="" className="img-fluid" />
                            )
                          }
                         
                        </figure>
                        <div className="caption">
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                              <h3>
                                {`${CurrencyFormat(room.roomBasePrice)}USD`} <span>/ Night</span>
                              </h3>
                              <h4>
                                <Link to={`/rooms/guestrooms/:guestroomid/?id=${room.id}`} state={{ roomObj: room }}>
                                  {room.roomName}
                                </Link>
                              </h4>
                            </div>

                            <div className='add-detach-section'>
                              {" "}
                              {
                                checkIfRoomIsInCart(room)
                              }

                            </div>

                          </div>
                          <p>
                            {room.roomDescription}
                          </p>
                          <div className="row room-facilities">
                            <div className="col-md-4">
                              <ul>
                                <li>
                                  <i className="flaticon-group"></i> 1-2 Persons
                                </li>
                                <li>
                                  <i className="flaticon-wifi"></i> Free Wifi
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-4">
                              <ul>
                                <li>
                                  <i className="flaticon-bed"></i> Twin Bed
                                </li>
                                <li>
                                  <i className="flaticon-breakfast"></i> Breakfast
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-4">
                              <ul>
                                <li>
                                  <i className="flaticon-clock-1"></i> 45 sqft room
                                </li>
                                <li>
                                  <i className="flaticon-swimming"></i> Swimming Pool
                                </li>
                              </ul>
                            </div>
                          </div>
                          <hr className="border-2" />
                          <div className="info-wrapper">
                            <div className="more">
                              <Link
                                to={`/rooms/guestrooms/:guestroomid/?id=${room.id}`}
                                className="link-btn"
                                tabindex="0"
                                state={{ roomObj: room }}
                              >
                                Details <i className="ti-arrow-right"></i>
                              </Link>
                            </div>
                            <div className={roomsInCart.length !== 0 ? "butn-dark-disabled" : "butn-dark"}>
                              {" "}
                              <button disabled={roomsInCart.length !== 0} onClick={() => { handleSelectRoom(room) }}>
                                <span>Select</span>
                              </button>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className='cart-empty'>
                  <div className="row">
                    <div style={{ display: 'flex', justifyContent: 'center' }} className="col-md-12">
                      <HourglassEmptyIcon style={{ color: '#cdcccc', fontSize: 83 }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }} className='col-md-12'>
                      <p style={{ fontWeight: 'bold', fontSize: 29 }}>No rooms available for the selected checkin and checkout date. </p>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }} className='col-md-12'>
                      <div className="butn-dark">
                        <button onClick={() => { props.setStep(0) }}>
                          <span>Checkin</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

          </div>
        </div>
      </section>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailableRooms);