import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { GetAuthenticatedUser, LoginUser } from './../../shared/utils/api/api';

const AuthLogin = (props) => {
    const [userName, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    const getAuthenticatedUser = () => {
        try {
            GetAuthenticatedUser().then(response => {
                console.log('::debug auth user:', response);
                localStorage.setItem('userData', JSON.stringify(response.data));
                navigate('/');
            window.location.reload();
            })
            
        } catch (error) {
            setErrorMessage('Oops an error occured');
        }
    };

    const handleLoginUser = (e) => {
        e.preventDefault();
        console.log('::debug handleLoginUserfunction');
        props.setShowProgress(true);
        try {
            LoginUser(userName, password).then(response => {
                props.setShowProgress(false);
                console.log('::debug login successful:', response);
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('access_token_expiry', response.data.access_expiration);
                localStorage.setItem('refresh_token', response.data.refresh_token);
                getAuthenticatedUser();

            });
        } catch (error) {
            props.setShowProgress(false);
            setErrorMessage('Invalid username or password');
        }
    };

    const handleClose = () => {
        setOpenSnackBar(false);
    };

    return (
        <div id='order-list-page'>
            {/* Header Banner */}
            <div
                className='section-padding valign'
                style={{
                    backgroundColor: '#f5eadc',
                }}
                data-overlay-dark='2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 caption' style={{marginTop: 60}}>
                            <div className="snackbark-sect">
                                <Snackbar
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={openSnackBar}
                                    autoHideDuration={6000}
                                    onClose={handleClose}
                                >
                                    <Alert variant="filled" severity="error">{errorMessage}</Alert>
                                </Snackbar>
                                {/* <div className="section-subtitle">Our Reservation Policy</div> */}
                                <div className="section-title" style={{ color: '#aa8453', textAlign: 'center' }}>Enter Your Credentials</div>
                            </div>
                            <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }} 
                            >
                            <div 
                                className='login-input-form'
                                style={{
                                    border: '2px solid #eee',
                                    borderRadius: 23,
                                    padding: 40,
                                }}
                                >
                                <FormControl variant="standard" fullWidth sx={{ minWidth: 120, marginBottom: 3 }}>
                                    <TextField
                                        id="standard-basic-username"
                                        name="username"
                                        required
                                        value={userName}
                                        onChange={(e) => { setUsername(e.target.value); }}
                                        label="Username"
                                        type='text'
                                        variant="standard" />
                                </FormControl>
                                <FormControl variant="standard" fullWidth sx={{ minWidth: 120 }}>
                                    <TextField
                                        id="standard-basic-password"
                                        name="password"
                                        required
                                        value={password}
                                        onChange={(e) => { setPassword(e.target.value); }}
                                        label="Password"
                                        type="password"
                                        variant="standard" />
                                </FormControl>
                                <div className='float-right'>
                                    <Link to="/auth/forgot-password" style={{color: '#2770cf', fontWeight: 'bold', fontSize: 15}}>Forgot Password?</Link>
                                </div>
                                <button
                                    className='butn-dark'
                                    onClick={(e) => { e.preventDefault(); handleLoginUser(e) }}
                                    style={{
                                        width: '100%',
                                        marginTop: '25px',
                                        backgroundColor: '#aa8453',
                                        color: '#ffffff',
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        fontSize: 19,
                                    }}
                                >
                                    Login In
                                </button>
                            </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AuthLogin;
