import React from 'react';

const SemperBooking = () => {

    return (
        <div id='booking-stepper-page'>
            <div
                className='banner-header section-padding valign bg-img bg-fixed'
                data-overlay-dark='4'
                data-background='img/slider/1.jpg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 mt-90'>
                            <h5>The Emin Pasha Hotel</h5>
                            <h1>Room Booking</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section className="booking-stepper section-padding bg-cream">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <iframe
                                title='booking engine'
                                src="https://services.semper.co.za/BookingEngine/full-layout/date-selection?VenueID=19684&ChannelID=1"
                                width="100%"
                                height="600"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default SemperBooking;
