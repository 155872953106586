import React from 'react'
import { Link as LinkScroll } from 'react-scroll';
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import "../globalstyles/themify-icons.css";
import "../globalstyles/animate.min.css";
import "../globalstyles/datepicker.css";
import "../globalstyles/flaticon.css";
import "../globalstyles/magnific-popup.css";
import "../globalstyles/owl.theme.default.min.css";
import "../globalstyles/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../globalstyles/style.css";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";

const Restruant = () => {
  const superiorRooms = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const dessertResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <div>
      {/* <!-- Restaurant Slider --> */}
      <header class="header home-header-container slider-fade">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={superiorRooms}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {/* <!-- The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. --> */}
          <div
            className="text-center item bg-restuarant1"
            data-overlay-dark="3"
            data-background="../../images/restaurant/1.jpg"
          >
          </div>
          <div
            className="text-center item bg-restuarant2"
            data-overlay-dark="3"
            data-background="../../images/restaurant/2.jpg"
          >
          </div>
          <div
            className="text-center item bg-restuarant3"
            data-overlay-dark="3"
            data-background="../../images/restaurant/3.jpg"
          >
          </div>
        </Carousel>
        {/* <!-- arrow down --> */}
        <div className="arrow bounce text-center">
          <LinkScroll to="restuarantsection" spy={true} smooth={true}>
            <i className="ti-arrow-down"></i>{" "}
          </LinkScroll>
        </div>
      </header>
      {/* <!-- Restaurant Content --> */}
      <section id="restuarantsection" className="rooms-page section-padding" data-scroll-index="1">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-12">
              <div className='restuarant-banner'>
                <div className="restuarant-banner-message">
                  <p>We offer Take out and Food Delivery <Link data-scroll-nav="1" to='/orders/dashboard'><span> Place Order</span></Link></p>
                </div>
              </div>
            </div> */}
            <div className="col-md-12 text-left">
              <span>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
              </span>
              <div className="section-subtitle">
                An Experience for the Senses
              </div>
              <div className="section-title">FEZ Brasserie</div>
            </div>
            <div className="col-md-12">
              <p className="mb-30">
                FEZ Brasserie is celebrated for its excellent cuisine and unique
                ambience. The gorgeous dining room features three open studio
                kitchens, allowing you to enjoy the sights and sounds of the
                culinary artistry on display. The menu showcases Asian, European
                and African influences, with a tempting selection of classic
                favorites and creative dishes for you to sample. Cheese
                connoisseurs will be drawn to the The Wine and Cheese Cellar,
                housed in five-meter-high glass walls, where our knowledgeable
                staff can introduce you to some of Kampala's greatest culinary
                treasures.
              </p>
              <h6>Hours</h6>
              <ul className="list-unstyled page-list mb-30">
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="ti-time"></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Breakfast: 7.00 am – 11.00 am (daily)</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="ti-time"></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Lunch: 12.00 noon – 2.00 pm (daily)</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="ti-time"></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>
                      Dinner: open from 6.30 pm, last order at 10.00 pm (daily)
                    </p>
                  </div>
                </li>
              </ul>
              <h6>Dress Code</h6>
              <p>Smart casual (no shorts, hats, or sandals permitted)</p>
              <h6>Terrace</h6>
              <p>Open for drinks only</p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Restaurant Menu --> */}
      <section
        id="menu"
        className="restaurant-menu menu section-padding bg-black"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-subtitle">
                <span>The Emin Pasha Hotel</span>
              </div>
              <div className="section-title">
                <span>FEZ Brasserie Menu</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="tabs-icon col-md-10 offset-md-1 text-center">
                  <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={dessertResponsive}
                    ssr={false} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={4000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    <div id="tab-1" className="active item">
                      <h6>Starters</h6>
                    </div>
                    <div id="tab-2" className="item">
                      <h6>Mains</h6>
                    </div>
                    <div id="tab-3" className="item">
                      <h6>Salads</h6>
                    </div>
                    <div id="tab-4" className="item">
                      <h6>Wine</h6>
                    </div>
                    <div id="tab-5" className="item">
                      <h6>Breakfast</h6>
                    </div>
                    <div id="tab-6" className="item">
                      <h6>Dessert</h6>
                    </div>
                  </Carousel>
                </div>
                <div className="restaurant-menu-content col-md-12">
                  {/* <!-- Starters --> */}
                  <div id="tab-1-content" className="cont active">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="menu-info">
                          <h5>
                            Mozzarella Dippers{" "}
                            <span className="price">27$</span>
                          </h5>
                          <p>Fried mozzarella sticks, marinara sauce</p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Onion Rings <span className="price">32$</span>
                          </h5>
                          <p>Fried onion rings, smoked aioli</p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Fried Jalapeno <span className="price">52$</span>
                          </h5>
                          <p>Fried jalapeno pickles, cheddar sauce</p>
                        </div>
                      </div>
                      <div className="col-md-5 offset-md-2">
                        <div className="menu-info">
                          <h5>
                            Buffalo Wings <span className="price">37$</span>
                          </h5>
                          <p>
                            Spicy chicken wings, blue cheese sauce, carrot,
                            celery
                          </p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Chilli Con Carne <span className="price">32$</span>
                          </h5>
                          <p>Spicy ground beef, bacon, kidney beans</p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Potato Skins <span className="price">42$</span>
                          </h5>
                          <p>
                            Crispy potato skins; bacon & cheddar or vegetables
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Mains --> */}
                  <div id="tab-2-content" className="cont">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="menu-info">
                          <h5>
                            Rusty’s Burger <span className="price">27$</span>
                          </h5>
                          <p>
                            Smoked pulled beef ribs, bbq sauce, cheddar, crispy
                            onion
                          </p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Cajun Fish Steak <span className="price">32$</span>
                          </h5>
                          <p>
                            Cajun spicied seabass, deep fried baby potatoes,
                            side salad
                          </p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Southern Fried Chicken{" "}
                            <span className="price">52$</span>
                          </h5>
                          <p>
                            Cajun coated chicken breast, fries and honey mustard
                          </p>
                        </div>
                      </div>
                      <div className="col-md-5 offset-md-2">
                        <div className="menu-info">
                          <h5>
                            Crab Cake <span className="price">37$</span>
                          </h5>
                          <p>
                            Breaded crab cakes, tartar sauce, apple and fennel
                            salad
                          </p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Baby Back Ribs <span className="price">32$</span>
                          </h5>
                          <p>Bbq glazed baby pork ribs, coleslaw, fries</p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Smokehouse Combo <span className="price">42$</span>
                          </h5>
                          <p>
                            Smoked beef brisket, rib and sausage, coleslaw,
                            cornbread
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Salads --> */}
                  <div id="tab-3-content" className="cont">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="menu-info">
                          <h5>
                            Ceaser Salad <span className="price">47$</span>
                          </h5>
                          <p>
                            Romaine lettuce, croutons, parmigiano, Ceaser
                            dressing.
                          </p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Waldorf Salad <span className="price">52$</span>
                          </h5>
                          <p>
                            Lettuce, celery, apple, grape, walnut, waldorf sauce
                          </p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Quinoa & Avocado Salad{" "}
                            <span className="price">52$</span>
                          </h5>
                          <p>
                            Quinoa, avocado, mixed greens. Nuts, dried and fresh
                            fruits
                          </p>
                        </div>
                      </div>
                      <div className="col-md-5 offset-md-2">
                        <div className="menu-info">
                          <h5>
                            Grilled Salmon Salad{" "}
                            <span className="price">37$</span>
                          </h5>
                          <p>
                            Grilled salmon, mixed greens, capers, orange slices
                          </p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Chicken Cobb Salad{" "}
                            <span className="price">32$</span>
                          </h5>
                          <p>
                            Iceberg lettuce, cherry tomatoes, blue cheese,
                            avocado, bacon
                          </p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Salad Chicken <span className="price">42$</span>
                          </h5>
                          <p>
                            {" "}
                            Ceaser dressing. Optional grilled chicken breast
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Wine --> */}
                  <div id="tab-4-content" className="cont">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="menu-info">
                          <h5>
                            Château d'Yquem 2011{" "}
                            <span className="price">400$</span>
                          </h5>
                          <p>Dessert Wine, Bordeaux, Graves, Sauternes</p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Alvear Cream NV <span className="price">30$</span>
                          </h5>
                          <p>Dessert, Fortified Wine, Andalucia</p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Chateau D'yquem 1990{" "}
                            <span className="price">900$</span>
                          </h5>
                          <p>Dessert Wine, Bordeaux, Graves, Sauternes</p>
                        </div>
                      </div>
                      <div className="col-md-5 offset-md-2">
                        <div className="menu-info">
                          <h5>
                            La Grande Année 2007{" "}
                            <span className="price">400$</span>
                          </h5>
                          <p>Rosé, Champagne</p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Sine Qua Non 2012{" "}
                            <span className="price">520$</span>
                          </h5>
                          <p>Syrah, Shiraz & Blends, California</p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            W.S. Keyes Winery 2006{" "}
                            <span className="price">240$</span>
                          </h5>
                          <p>Merlot, California, Napa, Howell Mountain</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Breakfast --> */}
                  <div id="tab-5-content" className="cont">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="menu-info">
                          <h5>
                            Egg Benedict <span className="price">60$</span>
                          </h5>
                          <p>
                            English muffin, beef, hollandaise sauce, poached
                            egg.
                          </p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Texas Benedict <span className="price">30$</span>
                          </h5>
                          <p>
                            English muffin, short ribs, bbq sauce, poached egg.
                          </p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Rusty’s Omlette <span className="price">22$</span>
                          </h5>
                          <p>
                            Mozzarella, cheddar, caramelized onion, black beans.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-5 offset-md-2">
                        <div className="menu-info">
                          <h5>
                            Salmon Bagel <span className="price">30$</span>
                          </h5>
                          <p>
                            Smoked salmon, cream cheese, dill, rocket, red
                            onion.
                          </p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Breakfast Bagel <span className="price">33$</span>
                          </h5>
                          <p>Chocolate, marshmallow, biscuit bar</p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Rusty’s Pancake <span className="price">40$</span>
                          </h5>
                          <p>
                            Strawberry, white chocolate, dark chocolate,
                            crispearls
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Dessert --> */}
                  <div id="tab-6-content" className="cont">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="menu-info">
                          <h5>
                            Bourbon Pecan Pie <span className="price">67$</span>
                          </h5>
                          <p>Bourbon pecan stuffed pie, vanilla ice-cream</p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            New York Cheesecake{" "}
                            <span className="price">50$</span>
                          </h5>
                          <p>Cheesecake, strawberry & lime salad</p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Rusty’s ice-cream <span className="price">32$</span>
                          </h5>
                          <p>Vanilla, bourbon, cookie, chocolate ice-cream</p>
                        </div>
                      </div>
                      <div className="col-md-5 offset-md-2">
                        <div className="menu-info">
                          <h5>
                            S’mores <span className="price">40$</span>
                          </h5>
                          <p>Chocolate chip cookies, marshmallow, chocolate</p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Rocky Road <span className="price">42$</span>
                          </h5>
                          <p>Chocolate, marshmallow, biscuit bar</p>
                        </div>
                        <div className="menu-info">
                          <h5>
                            Apple & Pear Crumble{" "}
                            <span className="price">42$</span>
                          </h5>
                          <p>
                            Caramelized pear and apple, oat crumble, vanilla
                            ice-cream
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Restruant;