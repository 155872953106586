import React from 'react';
import OrderList from './OrderList';
import Image1 from "../../images/restaurant/alvear-montilla-px-wines.jpg";
import Image2 from "../../images/restaurant/sine-2012.jpeg";
import Image3 from "../../images/restaurant/yquem-1990.jpg";

const OrderWine = () => {
    const orderArray = [
        {
            id: 1,
            name: 'Alvear Cream NV',
            price: 11700,
            image: Image1,
            slug: null,
            slug_text: null
        },
        {
            id: 2,
            name: 'Sine Qua Non 2012',
            price: 53200,
            image: Image2,
            slug: null,
            slug_text: null
        },
        {
            id: 3,
            name: "Chateau D'yquem 1990",
            price: 96200,
            image: Image3,
            slug: null,
            slug_text: null
        }
    ]
    return (
        <div id='order-list-page'>
            {/* Header Banner */}
            <div
                className='banner-header section-padding valign bg-orderimg bg-fixed'
                data-overlay-dark='4'
                data-background='img/slider/1.jpg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 caption mt-90'>
                            <h5>The Emin Pasha Hotel</h5>
                            <h1>Wine Menu</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section id='order-dashboard' className="facilties section-padding bg-order-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-subtitle">Our Wine Menu</div>
                            <div className="section-title">We now deliver</div>
                        </div>
                    </div>
                    <OrderList orderArray={orderArray}/>
                </div>
            </section>
        </div>
    );
};

export default OrderWine;
