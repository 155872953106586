import React from 'react';
import Restruant from '../components/restruants-and-bars/restruant.jsx';
import Testimony from "../components/home/Testimony";
const Restruants = () => {
  return (
    <div>
      <Restruant />
      {/* <Testimony/> */}
    </div>
  );
};

export default Restruants;
