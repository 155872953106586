import React from "react";
import {Link} from 'react-router-dom';
import Image1 from '../../images/outdoor/outdoor11.jpeg';
import Image2 from '../../images/spa/spa2.jpeg';
import Image3 from '../../images/spa/2.jpg';
import Image4 from '../../images/swimmingpool/swimming-pool-2.JPG';


function HomeServices() {
    return (
      <div>
        {/*  Services  */}
        <section class="services section-padding">
          <div class="container">
            <div class="row">
              <div
                class="col-md-6 p-0 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <div class="img left">
                  <Link to="/restruantsandbars">
                    <img src={Image1} alt="" style={{ minHeight: 381, maxHeight: 381}}/>
                  </Link>
                </div>
              </div>
              <div
                class="col-md-6 p-0 bg-cream valign animate-box"
                data-animate-effect="fadeInRight"
              >
                <div class="content">
                  <div class="cont text-left">
                    <div class="info">
                      <h6>Discover</h6>
                    </div>
                    <h4>Restaurants And Bars</h4>
                    <p>
                      The Emin Pasha boasts of three uniquely 
                      distinct restaurants and two bars. The 
                      latter are celebrated for their excellent 
                      cuisine, service offering and organic 
                      ambience. The gorgeous dining rooms 
                      feature, among others, a modern dumbwaiter, 
                      essentially allowing you to enjoy the sights 
                      and sounds of the culinary artistry on 
                      display.
                    </p>
                    {/* The menu showcases Asian, European 
                      and African influences, with a tempting 
                      selection of classic favorites and creative 
                      dishes for you to sample. */}
                    <div class="butn-dark">
                      {" "}
                      <Link to="/restruantsandbars">
                        <span>Read More</span>
                      </Link>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-6 bg-cream p-0 order2 valign animate-box"
                data-animate-effect="fadeInLeft"
              >
                <div class="content">
                  <div class="cont text-left">
                    <div class="info">
                      <h6>Experiences</h6>
                    </div>
                    <h4>Emin Pasha Spa</h4>
                    <p>
                      At The Emin Pasha Swanky Spa and Wellness 
                      center, our team of professionals offer 
                      the most calming and luxurious spa 
                      experience. On offer are tailored massage 
                      perks, facials, deep sea salt treatments 
                      and a full, rejuvenating Turkish bath 
                      experience.
                    </p>
                    <div class="butn-dark">
                      {" "}
                      <Link to="/fitnessandspa">
                        <span>Read More</span>
                      </Link>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 p-0 order1 animate-box"
                data-animate-effect="fadeInRight"
              >
                <div class="img">
                  <Link to="/fitnessandspa">
                    <img src={Image2} alt="" style={{ minHeight: 381, maxHeight: 381}}/>
                  </Link>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-6 p-0 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <div class="img left">
                  <Link to="/fitnessandspa">
                    <img src={Image3} alt="" style={{ minHeight: 381, maxHeight: 381}}/>
                  </Link>
                </div>
              </div>
              <div
                class="col-md-6 p-0 bg-cream valign animate-box"
                data-animate-effect="fadeInRight"
              >
                <div class="content">
                  <div class="cont text-left">
                    <div class="info">
                      <h6>Modern</h6>
                    </div>
                    <h4>Emin Pasha Gym</h4>
                    <p>
                      Our Spa and Wellness center is accentuated 
                      by a modern gym, conveniently located, 
                      adjsucent to the swimming pool. This space 
                      is ably managed by select and seasoned 
                      professionals and is indeed your ultimate 
                      companion on your journey to fulfilling 
                      fitness goals.
                    </p>
                    <div class="butn-dark">
                      {" "}
                      <Link to="/fitnessandspa">
                        <span>Read More</span>
                      </Link>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-6 bg-cream p-0 order2 valign animate-box"
                data-animate-effect="fadeInLeft"
              >
                <div class="content">
                  <div class="cont text-left">
                    <div class="info">
                      <h6>Experiences</h6>
                    </div>
                    <h4>Swimming Pool</h4>
                    <p>
                      The Emin Pasha Hotel considers the pool as 
                      a center piece, hence regular upgrades are 
                      constantly on the cards. This ultra-modern 
                      pool is open to both guests and the general 
                      public for a regular swim or as a venue for 
                      poolside parties, and, or events.
                    </p>
                    <div class="butn-dark">
                      {" "}
                      <Link to="/facilities/businesscenters/swimmingpool">
                        <span>Read More</span>
                      </Link>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 p-0 order1 animate-box"
                data-animate-effect="fadeInRight"
              >
                <div class="img">
                  <Link to="/facilities/businesscenters/swimmingpool">
                    <img src={Image4} alt="" style={{ minHeight: 381, maxHeight: 381}}/>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default HomeServices;
