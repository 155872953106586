import React from 'react';
import Testimony from "../components/home/Testimony";
import GiftShop from '../components/giftshop/giftshop.jsx';
const Giftshop = () => {
  return (
    <div>
      <GiftShop/>
      {/* <Testimony /> */}
    </div>
  );
}

export default Giftshop;