import React from 'react';
import OrderList from './OrderList';
import Image1 from "../../images/outdoor/sourdough.jpg";
import Image2 from "../../images/outdoor/Baguette.jpg";
import Image3 from "../../images/outdoor/focaccia.jpg";

const BakersKitchenDashboard = () => {
    const orderArray = [
        {
            id: 1,
            name: 'Sourdough',
            price: 20000,
            image: Image1,
            slug: null,
            slug_text: null
        },
        {
            id: 2,
            name: 'Baguette',
            price: 32000,
            image: Image2,
            slug: null,
            slug_text: null
        },
        {
            id: 3,
            name: 'Focaccia',
            price: 62000,
            image: Image3,
            slug: null,
            slug_text: null
        }
    ]
    return (
        <div id='order-list-page'>
            {/* Header Banner */}
            <div
                className='banner-header section-padding valign bg-orderimg bg-fixed'
                data-overlay-dark='4'
                data-background='img/slider/1.jpg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 caption mt-90'>
                            <h5>The Emin Pasha Hotel</h5>
                            <h1>Breakfast Menu</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section id='order-dashboard' className="facilties section-padding bg-order-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-subtitle">Our Bakery Menu</div>
                            <div className="section-title">We now deliver</div>
                        </div>
                    </div>
                    <OrderList orderArray={orderArray}/>
                </div>
            </section>
        </div>
    );
};

export default BakersKitchenDashboard;
