import React from "react";
import { Link } from "react-router-dom";
import WhatsappIcon from '../../images/icons/whatsappIcon.png';

function Footer() {
  const currentYear = new Date().getFullYear();
  const handleWhatsAppClick = () => {
    const phoneNumber = '+256764 042543';
    const message = 'Hello';

    // Check if the user is on a mobile device
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

    // Create the WhatsApp URL
    let whatsappUrl = '';

    if (isMobile) {
      whatsappUrl = `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    } else {
      whatsappUrl = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    }

    // Open the WhatsApp URL in a new window or tab
    window.open(whatsappUrl, '_blank');

  };
  // const handleWhatsAppClick = () => {
  //   const phoneNumber = '+256774802356';
  //   const message = 'Hello';

  //   // Check if the user is on a mobile device
  //   const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

  //   // Create the WhatsApp URL
  //   let whatsappUrl = '';

  //   if (isMobile) {
  //     whatsappUrl = `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
  //   } else {
  //     whatsappUrl = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
  //   }

  //   // Open the WhatsApp URL in a new window or tab
  //   window.open(whatsappUrl, '_blank');

  // };
    return (
      <div>
        <footer className="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="footer-column footer-about">
                    <h3 className="footer-title">About Hotel</h3>
                    <p className="footer-about-text">
                      Welcome to the best four-star deluxe hotel in Kampala. We
                      are delighted with the opportunity to have you as our
                      guest, expect the most luxurious and comfortable
                      experience during your stay with us.
                    </p>

                    <div className="footer-language">
                      {" "}
                      <i className="lni ti-world"></i>
                      <select onchange="location = this.value;">
                        <option value="http://eminpasha.com/">English</option>
                        <option value="http://eminpasha.com/">German</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 offset-md-1">
                  <div className="footer-column footer-explore clearfix">
                    <h3 className="footer-title">Explore</h3>
                    <ul className="footer-explore-list list-unstyled">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/rooms">Rooms & Suites</Link>
                      </li>
                      <li>
                        <Link to="/restruantsandbars">Restaurants & Bars</Link>
                      </li>
                      <li>
                        <Link to="/fitnessandspa" href="spa-wellness.html">
                          Fitness & Spa
                        </Link>
                      </li>
                      <li>
                        <Link to="/about">About Hotel</Link>
                      </li>
                      <li>
                        <Link to="/contactus">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="footer-column footer-contact">
                    <h3 className="footer-title">Contact</h3>
                    <p className="footer-contact-text">
                      Plot 27 Akii Bua Road, Kampala
                      <br />
                      Uganda
                    </p>
                    <div className="footer-contact-info">
                      <p className="footer-contact-phone">
                        <span className="flaticon-call"></span> +256 312 264
                        712/3/4
                      </p>
                      <p className="footer-contact-mail">info@eminpasha.com</p>
                    </div>
                    <div className="footer-about-social-list">
                      
                      <a href="https://www.facebook.com/eminpashahotel/" target="_blank" rel="noreferrer">
                        <i className="ti-facebook"></i>
                      </a>
                      <a href="https://twitter.com/eminreloaded" target="_blank" rel="noreferrer">
                        <i className="ti-twitter"></i>
                      </a>
                      <a href="https://www.instagram.com/theeminpashahotelandspa/" target="_blank" rel="noreferrer">
                        <i className="ti-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="whatsapp-container float-right" style={{ position: 'fixed', bottom: 56, right: '2%', cursor: 'pointer', zIndex: 9 }}>
                    <button onClick={() => handleWhatsAppClick()}>
                      <img src={WhatsappIcon} alt='' height='45px' style={{width: 45}}/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer-bottom-inner">
                    <p className="footer-bottom-copy-right">
                      © Copyright {currentYear} by <a href="/">The Emin Pasha Hotel</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
}

export default Footer;
