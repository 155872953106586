import React, { useState, useEffect } from 'react';
import { nationalityList } from "../../shared/constants/Nationality"
import { ChevronCompactDown, ChevronCompactUp, XCircle } from 'react-bootstrap-icons';
import useBreakPoint from './../../shared/breakPoints/useBreakPoint';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CurrencyFormat } from '../../shared/Formatter';
import { connect } from 'react-redux';
import { FormatDate } from '../../shared/Formatter/index';

const mapDispatchToProps = dispatch => {
    return {
        removeRoom: product =>
            dispatch({ type: 'REMOVE_ROOM_FROM_CART', payload: product }),
        removeEnhancement: product =>
            dispatch({ type: 'REMOVE_ENHANCEMENT_FROM_CART', payload: product }),

    };
};

const mapStateToProps = state => {
    return {
        cartItems: state,
    };
};

const BookNowForm = (props) => {
    const [showDetails, setShowDetails] = useState(false);
    const [salutation, setSalutation] = useState("");
    const [nationality, setNationality] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");



    const [selectedRooms, setSelectedRooms] = useState([]);
    const [grossAmount, setGrossAmount] = useState(0);
    const { matchesAllMobile } = useBreakPoint();
    const nationalityArray = nationalityList;


    const handleChange = (event) => {
        setSalutation(event.target.value);
    };

    const handleChangeNationality = (event) => {
        setNationality(event.target.value);
    };

    const handleClickIcon = () => {
        setShowDetails(!showDetails);
    }

    useEffect(() => {
        const roomsArray = props.cartItems.roomsCart;
        const roomEnhancements = props.cartItems.enhancements;

        var totalRoomAmount = 0;
        var totalEnhancementAmount = 0;
        for (let i = 0; i < roomsArray.length; i++) {
            const tempAmount = parseInt(roomsArray[i].room_cap.room_base_price);
            totalRoomAmount += tempAmount;
        }

        for (let m = 0; m < roomEnhancements.length; m++) {
            console.log('::debug roomEnhancements index:', roomEnhancements[m]);
            const tempAmount = parseInt(roomEnhancements[m].en_price);
            totalEnhancementAmount += tempAmount;
        }

        console.log('::debug totalEnhancementAmount:', totalEnhancementAmount);

        setSelectedRooms(roomsArray);
        setGrossAmount(totalRoomAmount + totalEnhancementAmount);
    }, [props.cartItems]);

    const onChangeExpiryDate = (e) => {
        console.log('::debug event:', e.target.value);
        console.log('::debug expiryDate:', expiryDate);
        const result = e.target.value;
        const isNumber = parseInt(result);
        console.log('::debug isNumber:', isNumber);
        if (result.length === 2 && expiryDate.length !== 3) {
            const appended = result + "/";
            console.log("::debug appended:", appended);
            setExpiryDate(appended);
        } else {
            setExpiryDate(result);
        }
    }

    const handleAddMoreRooms = () => {
        props.setStep(1);
    }

    const removeSelectedRoom = (room) => {
        props.removeRoom(room);
    };

    return (
        <div id='book-now-page'>
            <section id='order-dashboard' className={matchesAllMobile ? "mt-3 pt-3" : "mt-5 pt-5"}>
                <div className="container">
                    <div className='row'>
                        <div className="reservation-section" style={{ display: matchesAllMobile ? 'block' : 'flex' }}>
                            <div className="booking-cart-section" style={{ width: matchesAllMobile ? '100%' : '50%', paddingLeft: 20, paddingRight: 20 }}>
                                <div id="cart-order-items" className='mb-3'>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="float-right mb-3">
                                                <div className="btn-outline-emin">
                                                    <button onClick={() => handleAddMoreRooms()}><span style={{ fontFamily: 'Avenirlight' }}>+ Add More Rooms</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="total-rooms">
                                                    <h5>Number Of Rooms:{` ${selectedRooms.length}`}</h5>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="total-amount-section">
                                                    <h5>Total Amount:{`${CurrencyFormat(grossAmount)}USD`}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="accordion-box clearfix">
                                                {
                                                    selectedRooms.map((room) => {
                                                        return (
                                                            <li className="accordion">
                                                                <div className="block">
                                                                    <div className="emin-room-item">
                                                                        <span className='cart-name-amount'>
                                                                            <span>{room.room_cap.room_name}</span>
                                                                            <b>{`${CurrencyFormat(room.room_cap.room_base_price)}USD`}</b>
                                                                        </span>
                                                                        <button onClick={() => handleClickIcon()} type='button'>{
                                                                            showDetails ? <ChevronCompactUp /> : <ChevronCompactDown />
                                                                        }</button>
                                                                    </div>
                                                                    {
                                                                        showDetails && (
                                                                            <div className="content">
                                                                                <figure>
                                                                                    <img src={room.room_cap.room_images[0].image_name} alt="" className="img-fluid" />
                                                                                </figure>
                                                                                <div className="col-md-12">
                                                                                    <div
                                                                                        className="rooms2 animate-box"
                                                                                        data-animate-effect="fadeInUp"
                                                                                    >

                                                                                        <div className="caption">
                                                                                            <h3>
                                                                                                {`${CurrencyFormat(room.room_cap.room_base_price)}USD`} <span>/ Night</span>
                                                                                            </h3>
                                                                                            <h4>
                                                                                                {room.room_cap.room_name}
                                                                                            </h4>
                                                                                            <p>
                                                                                                {room.room_cap.room_description}
                                                                                            </p>
                                                                                            <div className="row room-facilities">
                                                                                                <div className="col-md-4">
                                                                                                    <ul>
                                                                                                        <li>
                                                                                                            <i className="flaticon-group"></i> 1-2 Persons
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <i className="flaticon-wifi"></i> Free Wifi
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    <ul>
                                                                                                        <li>
                                                                                                            <i className="flaticon-bed"></i> Twin Bed
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <i className="flaticon-breakfast"></i> Breakfast
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    <ul>
                                                                                                        <li>
                                                                                                            <i className="flaticon-clock-1"></i> 45 sqft room
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <i className="flaticon-swimming"></i> Swimming Pool
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                                <hr className="border-2" />

                                                                                                <div className="col-md-6">
                                                                                                    <p><b>Check-in Date: </b><span>{FormatDate(props.startDate)}</span></p>
                                                                                                    <p><b>Check-out Date: </b><span>{FormatDate(props.endDate)}</span></p>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <p><b>Number of Adults: </b><span>1</span></p>
                                                                                                    <p><b>Number of Children: </b><span>0</span></p>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>

                                                                <div className="delete-container">
                                                                    <button onClick={() => removeSelectedRoom(room)}><XCircle color='#de1d1d' /></button>
                                                                </div>


                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <Divider orientation={matchesAllMobile ? "horizontal" : "vertical"} flexItem />

                            <div className="guest-information-section" style={{ width: matchesAllMobile ? '100%' : '50%', paddingLeft: 20, paddingRight: 20, paddingTop: matchesAllMobile ? 30 : 0 }}>

                                <div id="order-submit-form" className="row">
                                    <h5 className=''>Guest Information</h5>
                                    <div className="col-md-6 form-group">
                                        <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                            <TextField
                                                id="standard-basic-name"
                                                onChange={(e) => {
                                                    setFirstName(e.target.value)
                                                }} name="name" required label="First Name" variant="standard" />
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                            <TextField 
                                                id="standard-basic-fname" 
                                                onChange={(e) => {
                                                    setLastName(e.target.value)
                                                }} name="name" required label="Last Name" variant="standard" />
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label">Salutation</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={salutation}
                                                onChange={handleChange}
                                                label="Salutation"
                                            >
                                                <MenuItem value="">
                                                    <em>Select Salutation</em>
                                                </MenuItem>
                                                <MenuItem value={10}>Mr</MenuItem>
                                                <MenuItem value={20}>Mrs</MenuItem>
                                                <MenuItem value={30}>Miss</MenuItem>
                                                <MenuItem value={40}>Dr</MenuItem>
                                                <MenuItem value={50}>Capt</MenuItem>
                                                <MenuItem value={60}>Major</MenuItem>
                                                <MenuItem value={70}>Col</MenuItem>
                                                <MenuItem value={80}>Lt</MenuItem>
                                                <MenuItem value={90}>Hon</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                            <TextField id="standard-basic-email" type="email" name="email" required label="email" variant="standard" />
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                            <TextField id="standard-basic-phone" type="tel" name="phone" required label="Phone Number" variant="standard" />
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label">Nationality</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={nationality}
                                                onChange={handleChangeNationality}
                                                label="Salutation"
                                            >
                                                <MenuItem value="">
                                                    <em>Select Nationality</em>
                                                </MenuItem>
                                                {
                                                    nationalityArray.map((nationality) => {
                                                        return <MenuItem key={nationality.id} value={nationality.id}>{nationality.name}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                            <TextField id="standard-basic-address" type="text" name="address" required label="Address" variant="standard" />
                                        </FormControl>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                            <TextField id="standard-basic-note" type="text" multiline rows={4} name="message" label="Message" variant="standard" />
                                        </FormControl>
                                    </div>
                                </div>

                                {/* <div className="col-md-12">
                                <div className="checkout-btn-section row">
                                    <div className="col-md-12">
                                    <div className="order-list-btn-row">
                                        <div className="checkout-btn">
                                            <button className="butn-dark">
                                                <a href="#0">
                                                    <span>Submit</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(BookNowForm);
