import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../globalstyles/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../globalstyles/style.css';
import Container from 'react-bootstrap/Container';
import { Nav, Navbar, NavLink, NavDropdown } from "react-bootstrap";
import Logo from '../../images/logo/logo.png';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { connect } from 'react-redux';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WhatsappIcon from '../../images/icons/whatsappIcon.png';
import { LogoutUserToken } from '../../shared/utils/api/api';
import { useNavigate } from 'react-router-dom';
import useBreakPoint from '../../shared/breakPoints/useBreakPoint';

const NavBar = (props) => {
  const [navbar, setNavbar] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [cartLength, setCartLength] = useState(props.cartItems.cartItem.length);
  const { matchesMobile } = useBreakPoint();

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const accessTokenExpiry = localStorage.getItem('access_token_expiry');
    const refreshToken = localStorage.getItem('refresh_token');
    const  userData = localStorage.getItem('userData');
    const isLoggedIn = accessToken && accessTokenExpiry && refreshToken && userData ? true : false;
    setIsUserLoggedIn(isLoggedIn);
    setCartLength(props.cartItems.cartItem.length);
  }, [props.cartItems]);

  const positionNavBar = () => {
    if (navbar && !props.showProgress) {
      return -100;
    } else if (navbar && props.showProgress) {
      return -97;
    }

    return 0;
  };

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener('scroll', changeBackground);

  const handleWhatsAppClick = () => {
    const phoneNumber = '+256703881144';
    const message = 'Hello';

    // Check if the user is on a mobile device
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

    // Create the WhatsApp URL
    let whatsappUrl = '';

    if (isMobile) {
      whatsappUrl = `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    } else {
      whatsappUrl = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    }

    // Open the WhatsApp URL in a new window or tab
    window.open(whatsappUrl, '_blank');

  };
  const navigate = useNavigate();

  const handleReservation = () => {
    navigate('/user/reservation');
  };

  const handleLogin = () => {
    navigate('/auth/login');
  };

  const handleLogout = () => {
    LogoutUserToken().then(() => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('access_token_expiry');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('userData');
      window.location.reload();
    })
  };

  return (
    <Navbar
      className={navbar ? 'navbar navbar-expand-lg nav-scroll' : 'navbar navbar-expand-lg'}
      expand="lg"
      bg={navbar ? "light" : ""}
      style={{
        top: positionNavBar(),
      }}
      inverse
      collapseOnSelect
    >
      <Container>
        <Navbar.Brand className='logo-wrapper valign'>
          <Link className='logo' to='/'>
            <img src={Logo} style={{ width: navbar ? 100 : 150, height: 'auto' }} className='logo-img' alt='' />
          </Link>
        </Navbar.Brand>
        <div>
          {
            matchesMobile && cartLength > 0 && (
              <Link style={{ marginRight: 20 }} to="/orders/checkout"><ShoppingCartOutlinedIcon /><sup><b style={{ paddingTop: 2, paddingRight: 5, paddingBottom: 2, paddingLeft: 6 }} >{cartLength}</b></sup></Link>
            )
          }

          <Navbar.Toggle aria-controls="navbarScroll" data-bs-toggle="collapse" data-bs-target="#navbarScroll" />
        </div>
        <Navbar.Collapse id="navbarScroll">
          <Nav>
            <NavLink eventKey="1" as={Link} to="/">Home</NavLink>
            <NavLink eventKey="2" as={Link} to="/about">About</NavLink>
            <NavLink eventKey="3" as={Link} to="/rooms">Rooms</NavLink>
            <NavLink eventKey="4" as={Link} to="/restruantsandbars">Restaurants</NavLink>
            <NavLink eventKey="5" as={Link} to="/fitnessandspa">Fitness</NavLink>
            <NavLink eventKey="6" as={Link} to="/facilities">Facilities</NavLink>
            <NavLink eventKey="7" as={Link} to="/careers">Careers</NavLink>
            <NavLink eventKey="8" as={Link} to="/contactus">Contact</NavLink>
                <NavDropdown title={<FontAwesomeIcon icon={faUser} color="#aa8453" />} id="basic-nav-dropdown">
                  {/* <NavDropdown.Item to="/user/my-account">My Account</NavDropdown.Item>#f6f3f3  */}
                  {isUserLoggedIn ? <>
                  <NavDropdown.Item onClick={() => handleReservation()}>
                    Reservations
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => handleLogout()}>
                    <p style={{color: '#000000'}}>Logout</p>
                  </NavDropdown.Item>
                  </> :
                  <NavDropdown.Item onClick={() => handleLogin()}>Login</NavDropdown.Item>
                  }
                  
                </NavDropdown>

            {
              !matchesMobile && cartLength > 0 && (
                <NavLink eventKey="8" as={Link} to="/orders/checkout"><ShoppingCartOutlinedIcon /><sup><b style={{ paddingTop: 1, paddingRight: 3, paddingBottom: 1, paddingLeft: 6 }} >{cartLength}</b></sup></NavLink>
              )
            }
            {/* <NavLink eventKey="9">
              <div className="whatsapp-container float-right" style={{ position: 'relative', top: '-15%' }}>
                <button onClick={() => handleWhatsAppClick()}>
                  <img src={WhatsappIcon} alt='' width='65px' height='35px' />
                </button>
              </div>
            </NavLink> */}
            {/* <NavLink eventKey="7" className='btn-order' as={Link} to="/orders/dashboard">
              <Link data-scroll-nav="1" to='/orders/dashboard'><span> Order</span></Link>
            </NavLink> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const mapStateToProps = state => {
  return {
    cartItems: state,
  };
};

export default connect(mapStateToProps)(NavBar);
