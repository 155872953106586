import React, { useState } from 'react';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Image1 from "../../images/rooms/room1.jpeg";

const SearchRooms = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [availableRooms, setAvailableRooms] = useState(false);
    const currentDate = new Date();
    return (
        <div id='search-now-page'>
            <section id='order-dashboard' className="mt-5 pt-5">
                <div className="container">
                    <div className="search-row mb-5">
                        <div className='row'>
                            <div className="col-md-12">
                                <div className="booking-inner clearfix">
                                    <form className="form1 clearfix">
                                        <div className="col1 c1">
                                            <div className="input1_wrapper">
                                                <label>Check in</label>
                                                <div className="input1_inner">
                                                    <DatePicker
                                                        selected={startDate}
                                                        placeholderText='Check in'
                                                        onChange={(date: Date) => setStartDate(date)}
                                                        minDate={currentDate}
                                                        className="form-control"
                                                        customInput={
                                                            <input
                                                                type='text'
                                                                className='form-control input datepicker hasDatepicker'
                                                                placeholder='Check in'
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col1 c2">
                                            <div className="input1_wrapper">
                                                <label>Check out</label>
                                                <div className="input1_inner">
                                                    <DatePicker
                                                        selected={endDate}
                                                        placeholderText='Check out'
                                                        onChange={(date: Date) => setEndDate(date)}
                                                        minDate={startDate}
                                                        disabled={typeof startDate === 'string'}
                                                        className="form-control"
                                                        customInput={
                                                            <input
                                                                type='text'
                                                                className='form-control input datepicker hasDatepicker'
                                                                placeholder='Check out'
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col2 c3">
                                            <div className="select1_wrapper">
                                                <label>Adults</label>
                                                <div className="select1_inner">
                                                    <select
                                                        className="select2 select "
                                                        style={{ width: "100%" }}
                                                    >
                                                        <option value="1">1 Adult</option>
                                                        <option value="2">2 Adults</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col2 c4">
                                            <div className="select1_wrapper">
                                                <label>Children</label>
                                                <div className="select1_inner">
                                                    <select
                                                        className="select2 select"
                                                        style={{ width: "100%" }}
                                                    >
                                                        <option value="1">Children</option>
                                                        <option value="1">1 Child</option>
                                                        <option value="2">2 Children</option>
                                                        <option value="3">3 Children</option>
                                                        <option value="4">4 Children</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col2 c5">
                                            <div className="select1_wrapper">
                                                <label>Rooms</label>
                                                <div className="select1_inner">
                                                    <select
                                                        className="select2 select"
                                                        style={{ width: "100%" }}
                                                    >
                                                        <option value="1">1 Room</option>
                                                        <option value="2">2 Rooms</option>
                                                        <option value="3">3 Rooms</option>
                                                        <option value="4">4 Rooms</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id='available-rooms-page' className="available-rooms-section">
                        {
                            availableRooms && (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div
                                            className="rooms2 mb-90 animate-box"
                                            data-animate-effect="fadeInUp"
                                        >
                                            <figure>
                                                <img src={Image1} alt="" className="img-fluid" />
                                            </figure>
                                            <div className="caption">
                                                <h3>
                                                    350,000 USD <span>/ Night</span>
                                                </h3>
                                                <h4>
                                                    <Link to="/rooms/guestrooms/:guestroomid">
                                                        Superior Room
                                                    </Link>
                                                </h4>
                                                <p>
                                                    Spacious, bright guestrooms with tasteful furnishing, wooden
                                                    floor and panoramic windows from the ceiling to the floor.
                                                </p>
                                                <div className="row room-facilities">
                                                    <div className="col-md-4">
                                                        <ul>
                                                            <li>
                                                                <i className="flaticon-group"></i> 1-2 Persons
                                                            </li>
                                                            <li>
                                                                <i className="flaticon-wifi"></i> Free Wifi
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <ul>
                                                            <li>
                                                                <i className="flaticon-bed"></i> Twin Bed
                                                            </li>
                                                            <li>
                                                                <i className="flaticon-breakfast"></i> Breakfast
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <ul>
                                                            <li>
                                                                <i className="flaticon-clock-1"></i> 45 sqft room
                                                            </li>
                                                            <li>
                                                                <i className="flaticon-swimming"></i> Swimming Pool
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <hr className="border-2" />
                                                <div className="info-wrapper">
                                                    <div className="more">
                                                        <Link
                                                            to="/rooms/guestrooms/:guestroomid"
                                                            className="link-btn"
                                                            tabindex="0"
                                                        >
                                                            Details <i className="ti-arrow-right"></i>
                                                        </Link>
                                                    </div>
                                                    <div className="butn-dark">
                                                        {" "}
                                                        <Link to="#">
                                                            <span>Book Now</span>
                                                        </Link>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SearchRooms;
