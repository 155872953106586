import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../globalstyles/themify-icons.css";
import "../globalstyles/animate.min.css";
import "../globalstyles/datepicker.css";
import "../globalstyles/flaticon.css";
import "../globalstyles/magnific-popup.css";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import "../globalstyles/owl.theme.default.min.css";
import "../globalstyles/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../globalstyles/select2.css";
import "../globalstyles/style.css";
import Image1 from "../../images/compound/compound2.jpeg";
import Image2 from "../../images/compound/compound1.jpeg";
import Image3 from "../../images/compound/compound3.jpeg";
const HotelLibrary = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <div>
      <header className="header home-header-container slider-fade">
      <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {/* The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. */}
          <div
            className="text-center item bg-library-img"
            data-overlay-dark="3"
            data-background="../../images/spa/3.jpg"
          >
            {/* <img src={Image1} alt="" /> */}
          </div>
          <div
            className="text-center item bg-library-img1"
            data-overlay-dark="3"
            data-background="../../images/swimmingpool/swimmingpool1.jpeg"
          >
            {/* <img src={Image2} alt="" /> */}
          </div>
          {/* <div
            className="text-center item bg-library-img2"
            data-overlay-dark="3"
            data-background="../../images/spa/2.jpg"
          >
          </div> */}
        </Carousel>
        {/* arrow down */}
        <div className="arrow bounce text-center">
          <a href="#preface-section" data-scroll-nav="1" className="">
            {" "}
            <i className="ti-arrow-down"></i>{" "}
          </a>
        </div>
      </header>
      {/* Giftshop Content */}
      <section id='preface-section' className="rooms-page section-padding" data-scroll-index="1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {" "}
              <span>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
              </span>
              <div className="section-subtitle">Information and Knowledge</div>
              <div className="section-title">Mehmed Pasha Lounge</div>
              <p className="mb-30">
                Libraries play an essential role in
                providing safe, accessible, and 100% free educational resource
                centers for everyone. And in so doing, build
                literate, productive and engaged communities. The Mehmed Pasha Lounge is no different, giving access to a vast amount to knowledge to all guests on the hotel premises. Come and do research in our well stocked library.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HotelLibrary;