import React, { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { connect } from 'react-redux';
import { CurrencyFormat } from '../../shared/Formatter';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import useBreakPoint from '../../shared/breakPoints/useBreakPoint';
import { GetProductsInCart, DeleteProductFromCart } from '../../shared/utils/api/api';

const CheckOut = (props) => {
    const [amount, setAmount] = useState(0);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [orderCategory, setOrderCategory] = useState("");
    const [cartArray, setCartArray] = useState(props.cartItems.cartItem);
    const [cartLength, setCartLength] = useState(props.cartItems.cartItem.length);
    const [orderQuantity, setOrderQuantity] = useState(1);
    const { matchesAllMobile } = useBreakPoint();
    const decrementQuantity = () => {
        const newAmount = amount - 35;
        setOrderQuantity(orderQuantity - 1);
        setAmount(newAmount);
    }

    const GetAllProductsInCart = async () => {
        try {
            await GetProductsInCart().get().then((response) => {
                console.log('xxx home Response:', response);
            });
        } catch (error) {
            props.setShowProgress(false);
            setOpenSnackBar(true);
            setErrorMessage(error.response.data.errors.query._schema[0]);
            console.log('xxxdebug ERRRORR', error);
        } finally {
            props.setShowProgress(false);
        }
    }


    const handleChange = (event) => {
        setOrderCategory(event.target.value);
    };

    const handleClose = () => {
        setOpenSnackBar(false);
    };

    useEffect(() => {
        console.log('::debug checkout props:', props.isOrderNow);
        const cartItems = props.cartItems.cartItem;
        let inittotal = 0;
        if (props.cartItems.isOrderNow) {
            setOrderCategory("For Now");
        } else {
            setOrderCategory("For Later");
        };
        setCartArray(cartItems);
        setCartLength(cartItems.length);
        for (let i = 0; i < cartItems.length; i++) {
            inittotal += parseInt(props.cartItems.cartItem[i].price);
        }
        setAmount(inittotal);
    }, [props.cartItems]);

    const DeleteProductFromCart = async (productId) => {
        try {
            await DeleteProductFromCart(productId).then((response) => {
                console.log('::debug delete Booking cart response:', response);
            });
        } catch (error) {
            console.log('::debug delete BOOKing cart eerror:', error);
        }
    }

    return (
        <div id='order-list-page'>
            {/* Header Banner */}
            <div
                className='banner-header section-padding valign bg-orderimg bg-fixed'
                data-overlay-dark='4'
                data-background='img/slider/1.jpg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 caption mt-90'>
                            <h5>The Emin Pasha Hotel</h5>
                            <h1>Checkout</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section id='order-dashboard' className="facilties section-padding bg-order-page">
                <div className="container">
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={openSnackBar}
                        autoHideDuration={6000}
                        onClose={handleClose}
                    >
                        <Alert variant="filled" severity="error">{errorMessage}</Alert>
                    </Snackbar>

                    {
                        cartArray.length > 0 ?
                            <>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="section-subtitle">Checkout Page</div>
                                        <div className="section-title">Submit your order</div>
                                    </div>
                                </div>
                                <div className='order-row-section' style={{ display: matchesAllMobile ? 'block' : 'flex' }}>
                                    <div id="cart-order-items" className="oder-detail px-3" style={{ width: matchesAllMobile ? '100%' : '50%', }}>
                                        <div className="col-md-12">
                                            <div className="cart" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div className="items-in-cart">
                                                    <h5 style={{ fontSize: 18 }}>Items in Cart: {cartLength}</h5>
                                                </div>
                                                <div className="total-amount-section">
                                                    <h5>Total Amount: {CurrencyFormat(amount) + ' USD'}</h5>
                                                </div>
                                            </div>

                                        </div>
                                        {
                                            cartArray.map(item => {
                                                return (
                                                    <div key={item.id} style={{ backgroundColor: '#eff0f282', marginBottom: 15, paddingTop: 12, paddingBottom: 12 }} className="row">

                                                        <div className="col-md-6">
                                                            <div className="order-now-img">
                                                                {
                                                                    item.item_images && (
                                                                        <img src={item.item_images[0].image_name} alt="" />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="float-right remove-cart-item">
                                                                        <button onClick={() => props.removeItem(item)}>
                                                                            <HighlightOffIcon style={{ color: '#ed7676' }} />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="order-now-detail">
                                                                <h5>{item.title}</h5>
                                                                <div className="amount-tag">
                                                                    <p>{CurrencyFormat(item.price) + " USD"}</p>
                                                                </div>
                                                                <div className="order-accompaignment">
                                                                    <div className='select1_wrapper'>
                                                                        <label>Quantity</label>
                                                                        <div className='quantity_section'>
                                                                            <span className="button-decrmnt">
                                                                                <button className='btn-decrement' disabled={item.quantity > 1 ? false : true} onClick={() => props.decrementItem(item)}>-</button>
                                                                            </span>
                                                                            <span className='quantity-value'>{item.quantity}</span>
                                                                            <span className="button-incrmnt">
                                                                                <button className='btn-increment' onClick={() => props.incrementItem(item)}>+</button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }


                                    </div>
                                    <Divider orientation={matchesAllMobile ? "horizontal" : "vertical"} flexItem />
                                    <div className="client-details px-3" style={{ width: matchesAllMobile ? '100%' : '50%', }}>

                                        <div id="order-submit-form" className="row">
                                            <h5 className='h5-title'>Enter Your Details</h5>
                                            <div className="col-md-6 form-group">
                                                <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                                    <TextField id="standard-basic-name" name="name" required label="Fullname" variant="standard" />
                                                </FormControl>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                                    <TextField id="standard-basic-emai" type="email" name="email" required label="Email" variant="standard" />
                                                </FormControl>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                                    <TextField id="standard-basic-name" name="phone" type="tel" required label="Phone Number" variant="standard" />
                                                </FormControl>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                                    <TextField id="standard-basic-secondaryphone" type="tel" name="secondaryphone" label="Other Phone Number" variant="standard" />
                                                </FormControl>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                                    <InputLabel id="demo-simple-select-standard-label">Order Category</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={orderCategory}
                                                        onChange={handleChange}
                                                        label="Order Category"
                                                    >
                                                        {/* <MenuItem value="">
                                                            <em>Select a category</em>
                                                        </MenuItem> */}
                                                        <MenuItem value={"For Now"}>For Now</MenuItem>
                                                        <MenuItem value={"For Later"}>For Later</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {orderCategory === 'For Later' && (
                                                <>
                                                    <div className="col-md-6 form-group">
                                                        <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                                            <TextField
                                                                id="date"
                                                                label="Delivery Date"
                                                                variant="standard"
                                                                type="date"
                                                                required={orderCategory === 'For Later'}
                                                                // defaultValue="20-05-24"
                                                                sx={{ width: 220 }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-6 form-group">
                                                        <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                                            <TextField
                                                                id="time"
                                                                label="Delivery Time"
                                                                variant="standard"
                                                                required={orderCategory === 'For Later'}
                                                                type="time"
                                                                sx={{ width: 220 }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </>
                                            )}
                                            <div className="col-md-6 form-group">
                                                <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                                    <TextField id="standard-basic-address" name="address" type="text" required label="Address" variant="standard" />
                                                </FormControl>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                                    <TextField id="standard-basic-landmark" name="landmark" required label="Nearest Landmark" variant="standard" />
                                                </FormControl>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                                    <TextField id="standard-basic-note" type="text" multiline rows={4} name="message" label="Message" variant="standard" />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="checkout-btn-section">
                                                <div className="order-list-btn-row">
                                                    <div className="checkout-btn">
                                                        <button className="butn-dark">
                                                            <a href="#0">
                                                                <span>Submit</span>
                                                            </a>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            (
                                <div className='cart-empty'>
                                    <div className="row">
                                        <div style={{ display: 'flex', justifyContent: 'center' }} className="col-md-12">
                                            <ProductionQuantityLimitsIcon style={{ color: '#cdcccc', fontSize: 83 }} />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }} className='col-md-12'>
                                            <p style={{ fontWeight: 'bold', fontSize: 29 }}>No items in Cart</p>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
            </section>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        cartItems: state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeItem: product =>
            dispatch({ type: 'REMOVE_FROM_CART', payload: product }),
        incrementItem: product =>
            dispatch({ type: 'INCREMENT_ITEM', payload: product }),
        decrementItem: product =>
            dispatch({ type: 'DECREMENT_ITEM', payload: product }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckOut);
