import React from 'react';

const MyAccount = () => {
    return (
        <div id='order-list-page'>
            {/* Header Banner */}
            <div
                className='banner-header section-padding valign bg-orderimg bg-fixed'
                data-overlay-dark='4'
                data-background='img/slider/1.jpg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 caption mt-90'>
                            <h5>The Emin Pasha Hotel</h5>
                            <h1>User Account</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section id='order-dashboard' className="facilties section-padding bg-order-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {/* <div className="section-subtitle">Our Reservation Policy</div> */}
                            <div className="section-title" style={{ color: '#aa8453'}}>User Account</div>
                        </div>
                        <div className='col-md-6'>
                            <p>Name:</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default MyAccount;
