import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../globalstyles/themify-icons.css';
import '../globalstyles/animate.min.css';
import '../globalstyles/datepicker.css';
import '../globalstyles/flaticon.css';
import '../globalstyles/magnific-popup.css';
import '../globalstyles/owl.theme.default.min.css';
import '../globalstyles/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../globalstyles/style.css';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { CurrencyFormat } from '../../shared/Formatter';
import { GetAllRooms } from '../../shared/utils/api/api';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ImageNotFound from '../../images/restaurant/image-not-found.png';

const RoomsAndSuites = (props) => {
  const [roomsArray, setRoomsArray] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    console.log('::debug props:', props);
    const fetchRooms = async () => {
      try {
        props.setShowProgress(true);
        await GetAllRooms().get().then((response) => {
          console.log('::debug Getroom Response:', response.data.data);
          setRoomsArray(response.data.data);
          props.setShowProgress(false);
        });
      } catch (error) {
        props.setShowProgress(false);
        setOpenSnackBar(true);
        setErrorMessage(error.response.data.errors.query._schema[0]);
        console.log(error);
      }
    };
    fetchRooms();
  }, []);

  const handleClose = () => {
    setOpenSnackBar(false);
  };

  return (
    <div id='roomandsuites_page'>
      {/* Header Banner */}
      <div
        className='banner-header section-padding valign bg-img-room-pre bg-fixed'
        data-overlay-dark='5'
        data-background='../../images/rooms/4.jpg'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 caption mt-90'>
              <span>
                <i className='star-rating'></i>
                <i className='star-rating'></i>
                <i className='star-rating'></i>
                <i className='star-rating'></i>
                <i className='star-rating'></i>
              </span>
              <h5>The Emin Pasha Hotel</h5>
              <h1>Rooms & Suites</h1>
            </div>
          </div>
        </div>
      </div>

      <section
        id='rooms-suites-page'
        className='rooms1 section-padding bg-cream'
        data-scroll-index='1'>
        <div className='container'>
        <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={openSnackBar}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert variant="filled" severity="error">{errorMessage}</Alert>
            </Snackbar>
          <div className='row'>
            {
              roomsArray.length > 0 ? (
                roomsArray.map((room) => {
                  return (
                    <div key={room.id} className='col-md-6'>
                      <div className='item'>
                        <div className='position-re o-hidden'>
                          {' '}
                          <Link to={`/rooms/guestrooms/:guestroomid/?id=${room.id}`}>
                            <img 
                            src={room.roomImage && room.roomImage.length > 0 ? room.roomImage[0].image_name : ImageNotFound}
                            style={{
                              width: '100%',
                              display: 'block',
                              height: 'auto'
                            }} 
                            alt='' />
                          </Link>{' '}
                        </div>{' '}
                        <span className='category'>
                          <Link to='/new-booking'>Book</Link>
                        </span>
                        <div className='con'>
                          <h6>
                            <Link to={`/rooms/guestrooms/:guestroomid/?id=${room.id}`}>
                              {`${CurrencyFormat(room.room_base_price)} USD / Night`}
                            </Link>
                          </h6>
                          <h5>
                            <Link to={`/rooms/guestrooms/:guestroomid/?id=${room.id}`}>
                              {room.room_name}
                            </Link>
                          </h5>
                          <div className='line'></div>
                          <div className='row facilities'>
                            <div className='col col-md-7'>
                              <ul>
                                <li>
                                  <i className='flaticon-bed'></i>
                                </li>
                                <li>
                                  <i className='flaticon-bath'></i>
                                </li>
                                <li>
                                  <i className='flaticon-breakfast'></i>
                                </li>
                                <li>
                                  <i className='flaticon-towel'></i>
                                </li>
                              </ul>
                            </div>
                            <div className='col col-md-5 text-right'>
                              <div className='permalink'>
                                <Link to={`/rooms/guestrooms/:guestroomid/?id=${room.id}`}>
                                  Details <i className='ti-arrow-right'></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )
                :
                (
                  <div className='cart-empty'>
                    <div className="row">
                      <div style={{ display: 'flex', justifyContent: 'center' }} className="col-md-12">
                        <HourglassEmptyIcon style={{ color: '#cdcccc', fontSize: 83 }} />
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center' }} className='col-md-12'>
                        <p style={{ fontWeight: 'bold', fontSize: 29 }}>No rooms available yet, still loading...</p>
                      </div>
                    </div>
                  </div>
                )
            }
          </div>
        </div>
      </section>
    </div>
  );
};

export default RoomsAndSuites;
