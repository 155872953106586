import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const useBreakPoint = () => {
    const theme = useTheme();
    const matchesMobile = useMediaQuery('(max-width: 600px)');
    const matchesTablet = useMediaQuery(theme.breakpoints.between(601, 800));
    const matchesAllMobile = useMediaQuery('(max-width: 990px)');
    // const matchesLaptop = useMediaQuery(theme.breakpoints.between(801, 1024));
    const matchesDesktop = useMediaQuery('(min-width: 766px)');
    return {
    matchesMobile, 
    matchesTablet,
    matchesDesktop,
    matchesAllMobile,
    };
};

export default useBreakPoint;
