import React, { useRef } from 'react';
import { IconButton, Slider } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VideoPath from '../../video/promo.mp4';
import { styled } from '@mui/system';
import useBreakPoint from './../../shared/breakPoints/useBreakPoint';

// const VideoContainer = styled('div')({
//     position: 'relative',
//     display: 'flex',
//     alignItems: 'center',
// });

const Controls = styled('div')({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: 8,
});


const VideoPlayer = () => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const { matchesMobile } = useBreakPoint();

    const handlePlayPause = () => {
        const video = videoRef.current;

        if (video.paused) {
            video.play();
            setIsPlaying(true);
        } else {
            video.pause();
            setIsPlaying(false);
        }
    };

    const handleTimeUpdate = () => {
        const video = videoRef.current;
        const currentTime = video.currentTime;
        const duration = video.duration;
        const progress = (currentTime / duration) * 100;
        setProgress(progress);
    };

    return (
        <div>
            <section
                className=' video bg-img bg-fixed'
                data-overlay-dark='3'
                data-background='img/slider/2.jpg'
                style={{
                    minHeight: 350,
                    paddingTop: 31,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 10,
                }}
            >
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-12">
                            <video
                                ref={videoRef}
                                src={VideoPath}
                                onTimeUpdate={handleTimeUpdate}
                                style={{ maxHeight: 350, width: '100%' }}
                            />
                            <Controls style={{ top: matchesMobile ? '38%' : '45%',}}>
                                <IconButton onClick={handlePlayPause}>
                                    {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                                </IconButton>
                            </Controls>
                            <Slider value={progress} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default VideoPlayer;
