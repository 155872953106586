import React from 'react';
import BusinessCenter from '../components/business-center/business-center.jsx';
import Testimony from "../components/home/Testimony";
const Businesscenter = () => {
  return (
    <div>
      <BusinessCenter/>
      {/* <Testimony /> */}
    </div>
  );
    
}

export default Businesscenter;