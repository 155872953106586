import Image1 from "../../images/restaurant/egg-Benedict.jpg";
import Image2 from "../../images/restaurant/texas-Benedict.jpg";
import Image3 from "../../images/restaurant/Omlette.jpg";
export const starterArray = [
    {
        id: 1,
        name: 'Mozzarella Dippers',
        price: 47000,
        image: Image1,
        slug: null,
        slug_text: null
    },
    {
        id: 2,
        name: 'Onion Rings',
        price: 32000,
        image: Image2,
        slug: null,
        slug_text: null
    },
    {
        id: 3,
        name: 'Fried Jalapeno',
        price: 62000,
        image: Image3,
        slug: null,
        slug_text: null
    }
];

export const wineArray = [
    {
        id: 1,
        name: 'Alvear Cream NV',
        price: 11700,
        image: Image1,
        slug: null,
        slug_text: null
    },
    {
        id: 2,
        name: 'Sine Qua Non 2012',
        price: 53200,
        image: Image2,
        slug: null,
        slug_text: null
    },
    {
        id: 3,
        name: "Chateau D'yquem 1990",
        price: 96200,
        image: Image3,
        slug: null,
        slug_text: null
    }
];

export const saladsArray = [
    {
        id: 1,
        name: 'Ceaser Salad',
        price: 47000,
        image: Image1,
        slug: null,
        slug_text: null
    },
    {
        id: 2,
        name: 'Waldorf Salad',
        price: 52000,
        image: Image2,
        slug: null,
        slug_text: null
    },
    {
        id: 3,
        name: 'Avocado Salad',
        price: 32000,
        image: Image3,
        slug: null,
        slug_text: null
    }
];

export const mainArray = [
    {
        id: 1,
        name: 'Rusty’s Burger',
        price: 27500,
        image: Image1,
        slug: 'Buy 1',
        slug_text: 'Get 2'
    },
    {
        id: 2,
        name: 'Cajun Fish Steak',
        price: 32000,
        image: Image2,
        slug: null,
        slug_text: null
    },
    {
        id: 3,
        name: 'Southern Fried Chicken',
        price: 52000,
        image: Image3,
        slug: null,
        slug_text: null
    }
];

export const dessertArray = [
    {
        id: 1,
        name: 'Bourbon Pecan Pie',
        price: 67500,
        image: Image1,
        slug: null,
        slug_text: null
    },
    {
        id: 2,
        name: 'Rusty’s ice-cream',
        price: 32500,
        image: Image2,
        slug: null,
        slug_text: null
    },
    {
        id: 3,
        name: 'Rocky Road',
        price: 62500,
        image: Image3,
        slug: null,
        slug_text: null
    }
];

export const breakfastArray = [
    {
        id: 1,
        name: 'Egg Benedict',
        price: 17000,
        image: Image1,
        slug: null,
        slug_text: null
    },
    {
        id: 2,
        name: 'Texas Benedict',
        price: 32000,
        image: Image2,
        slug: null,
        slug_text: null
    },
    {
        id: 3,
        name: 'Rusty’s Omlette',
        price: 62000,
        image:Image3,
        slug: null,
        slug_text: null
    }
];