import React from 'react'
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';
import Image1 from "../../images/meetingrooms/meetingroom8.jpeg";
import Image2 from "../../images/meetingrooms/meetingroom10.jpeg";
import Image3 from "../../images/meetingrooms/meetingroom12.jpeg";
const SwimmingPool = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    return (
        <div>
            <header className="header home-header-container slider-fade slider">
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={responsive}
                    ssr={false} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={4000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    {/* The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. */}
                    <div
                        className="text-center item bg-swimmingpool-img"
                        data-overlay-dark="3"
                        data-background="../../images/spa/3.jpg"
                    >
                        {/* <img src={Image1} alt="" /> */}
                    </div>
                    <div
                        className="text-center item bg-swimmingpool-img1"
                        data-overlay-dark="3"
                        data-background="../../images/swimmingpool/swimmingpool1.jpeg"
                    >
                        {/* <img src={Image2} alt="" /> */}
                    </div>
                    <div
                        className="text-center item bg-swimmingpool-img2"
                        data-overlay-dark="3"
                        data-background="../../images/spa/2.jpg"
                    >
                        {/* <img src={Image3} alt="" /> */}
                    </div>
                </Carousel>
                {/* arrow down */}
                <div className="arrow bounce text-center">
                    <a href="#preface-section" data-scroll-nav="1" className="">
                        {" "}
                        <i className="ti-arrow-down"></i>{" "}
                    </a>
                </div>
            </header>
            <section id='preface-section' className="rooms-page section-padding" data-scroll-index="1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {" "}
                            <span>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                            </span>
                            <div className="section-subtitle">EXPERIENCES</div>
                            <div className="section-title">Swimming Pool</div>
                            <p className="mb-30">
                                All guests are allowed access to our 300ft pool for adults
                                and 100ft pool for children. We also have a provision for
                                guests to make pool party reservations to host parties. {" "}
                            </p>
                        </div>
                        <div className="col-md-12">
                            <div className="reservations">
                                <div className="icon">
                                    <span className="flaticon-call"></span>
                                </div>
                                <div className="text">
                                    <p>Reservations</p>{" "}
                                    <a href="tel:+256 312 264 712">+256 312 264 712 /4</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SwimmingPool;