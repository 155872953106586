import React from 'react';
import PaymentSuccesfullImage from '../../images/success.png'
import PendingPayment from '../../images/pending-payment.png'
import { useLocation, Link } from 'react-router-dom';

const ReservationSuccessful = (props) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const successMessage = queryParams.get('message');
    const redirectUrl = queryParams.get('url');
    const email = queryParams.get('email');
    const paymentMethod = queryParams.get('paymentMethod');
    const additionalText = paymentMethod === 'card' ? `, Please check your email i.e ${' '+email+' '} we have sent you a copy of your reservation details.` : '';
    return (
        <div id='order-list-page'>
            {/* Header Banner */}
            <div
                className='banner-header section-padding valign bg-orderimg bg-fixed'
                data-overlay-dark='4'
                data-background='img/slider/1.jpg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 caption mt-90'>
                            <h5>The Emin Pasha Hotel</h5>
                            <h1>{paymentMethod === 'card' ? 'Reservation Successful' : 'Complete Mobile Money Payment'}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section id='order-dashboard' className="facilties section-padding bg-order-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-subtitle">User Resevation</div>
                            <div className="section-title">{paymentMethod === 'card' ? 'Complete Payment' : 'Resevation Payment Pending'}</div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12 pt-3'>
                            <div className='text-center'>
                                <img src={paymentMethod === 'card' ? PaymentSuccesfullImage : PendingPayment} alt="" style={{ width: "16%", height: "16%" }} className="img-fluid" />
                            </div>
                            <div className='sucess-message text-center'>
                                <p className='text-center'>{successMessage+additionalText}</p>
                                <div className="button-outline butn-dark">
                                    <div className="butn-dark">
                                        {
                                            paymentMethod === 'card' ?
                                                <Link to="/">
                                                    Go To Home
                                                </Link>
                                                :
                                                <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
                                                    <span>Complete Payment</span>
                                                </a>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default ReservationSuccessful;
