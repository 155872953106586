import React, { useState, useEffect } from 'react'
import { Link as LinkScroll } from 'react-scroll';
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import "../globalstyles/themify-icons.css";
import "../globalstyles/animate.min.css";
import "../globalstyles/datepicker.css";
import "../globalstyles/flaticon.css";
import "../globalstyles/magnific-popup.css";
import "../globalstyles/owl.theme.default.min.css";
import "../globalstyles/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../globalstyles/style.css";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Carousel from "react-multi-carousel";
import { Grid } from '@mui/material';
import { GetRestaurantById } from '../../shared/utils/api/api';
import HakkiPasha from '../../images/restaurant/hakki-Pasha.PNG'
import OrderList from '../orders/OrderList';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useLocation } from 'react-router-dom';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const AllDayRestaurant = (props) => {
    const [value, setValue] = useState(0);
    const [currentMenu, setCurrentMenu] = useState([]);
    const [responseArrayData, setResponseArrayData] = useState([]);
    const [restaurantObject, setRestaurantObjest] = useState({});
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const restaurantId = queryParams.get('id');

    const handleChange = (event, newValue) => {
        console.log('::debug newValue:', newValue);
        const selectedMenu = responseArrayData[newValue].menuItem;
        console.log('::debug selectedMenu:', selectedMenu);
        setCurrentMenu(selectedMenu);
        setValue(newValue);
        // switch (newValue) {
        //     case 0:
        //         const filtered = responseArrayData.filter((item) => item.menu_title === 'Starters');
        //         setCurrentMenu(filtered.length > 0 ? filtered[0].menuItem : []);
        //         // eslint-disable-next-line no-unreachable
        //         break;
        //     case 1:
        //         const filteredMain = responseArrayData.filter((item) => item.menu_title === 'Main Menu')
        //         setCurrentMenu(filteredMain.length > 0 ? filteredMain[0].menuItem : []);
        //         break;
        //     case 2:
        //         const filteredSalads = responseArrayData.filter((item) => item.menu_title === 'Salads')
        //         setCurrentMenu(filteredSalads.length > 0 ? filteredSalads[0].menuItem : []);
        //         break;
        //     case 3:
        //         const filteredBreakfast = responseArrayData.filter((item) => item.menu_title === 'Breakfast')
        //         setCurrentMenu(filteredBreakfast.length > 0 ? filteredBreakfast[0].menuItem : []);
        //         break;
        //     case 4:
        //         const filteredDessert = responseArrayData.filter((item) => item.menu_title === 'Dessert')
        //         setCurrentMenu(filteredDessert.length > 0 ? filteredDessert[0].menuItem : []);
        //         break;
        //     case 5:
        //         const filteredWine = responseArrayData.filter((item) => item.menu_title === 'Wine')
        //         console.log('::debug filteredWine:', filteredWine);
        //         setCurrentMenu(filteredWine.length > 0 ? filteredWine[0].menuItem : []);
        //         break;
        //     default:
        //         setCurrentMenu(responseArrayData[0].menuItem);
        //         break;
        // }
    };

    useEffect(() => {
        const fetchRestaurant = async () => {
            props.setShowProgress(true);
            console.log('xxx restauranr restaurantId:', restaurantId);
            try {
                await GetRestaurantById(restaurantId).get().then((response) => {
                    console.log('xxx restauranr Response:', response);
                    setRestaurantObjest(response.data);
                    const menuObject = response.data.posMenu;
                    setResponseArrayData(menuObject);
                    let filtered = null;
                    if (menuObject.length > 0) {
                        filtered = menuObject[0];
                        // filtered = menuObject.filter((item) => item.menu_title === filtered[0]);
                    }
                    setCurrentMenu(filtered ? filtered.menuItem : []);
                    console.log('xxx restaurant filtered:', filtered);
                    console.log('xxx restaurant menuObject:', menuObject);
                });
            } catch (error) {
                props.setShowProgress(false);
                setOpenSnackBar(true);
                setErrorMessage('An error occured while fetching data. Reload the page');
                console.log('xxxdebug ERRRORR', error);
            } finally {
                props.setShowProgress(false);
            }
        };
        fetchRestaurant();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const superiorRooms = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const handleClose = () => {
        setOpenSnackBar(false);
    };
    return (
        <div>
            {/* <!-- Restaurant Slider --> */}
            <header class="header home-header-container slider-fade">
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={superiorRooms}
                    ssr={false} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={4000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    {/* <!-- The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. --> */}
                    <div
                        className="text-center item bg-restuarant1"
                        data-overlay-dark="3"
                        data-background="../../images/restaurant/1.jpg"
                    >
                    </div>
                    <div
                        className="text-center item bg-restuarant2"
                        data-overlay-dark="3"
                        data-background="../../images/restaurant/2.jpg"
                    >
                    </div>
                    <div
                        className="text-center item bg-restuarant3"
                        data-overlay-dark="3"
                        data-background="../../images/restaurant/3.jpg"
                    >
                    </div>
                </Carousel>
                {/* <!-- arrow down --> */}
                <div className="arrow bounce text-center">
                    <LinkScroll to="restuarantsection" spy={true} smooth={true}>
                        <i className="ti-arrow-down"></i>{" "}
                    </LinkScroll>
                </div>
            </header>
            {/* <!-- Restaurant Content --> */}
            <section
                id="restuarantsection"
                className="rooms-page section-padding"
                data-scroll-index="1"
                style={{
                    backgroundColor: '#fbf8ef',
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className='col-md-12'>
                            <div className='snackbar'>
                                <Snackbar
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={openSnackBar}
                                    autoHideDuration={4000}
                                    onClose={handleClose}
                                >
                                    <Alert variant="filled" severity="error">{errorMessage}</Alert>
                                </Snackbar>
                            </div>
                        </div>
                        {/* <div className="col-md-12">
                            <div className='restuarant-banner'>
                                <div className="restuarant-banner-message">
                                    <p>We offer Take way and Food Delivery <Link data-scroll-nav="1" to='/orders/dashboard'><span> Place Order</span></Link></p>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-12 text-left">
                            <span>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                            </span>
                            <div className="section-subtitle">
                                Indulge in Tastes inspired by African Adventure
                            </div>
                            <div className="section-title">Hakki Pasha Restaurant</div>
                        </div>
                        <div className="col-md-12">
                            <Grid container item pb={5}>
                                <Grid item md={8}>
                                    <p className="mb-3">
                                        In the late 19th century, a man by the name of Hakki Pasha ventured into the wild lands of
                                        East Africa, seeking to collect geological and zoological specimens. Some called him foolish for
                                        entering such a dangerous land, but Hakki Pasha was determined to make his mark on the world.
                                    </p>
                                    <p className="mb-3">
                                        Armed with little more than his wits and a handful of brave men, he set off on his expedition into the
                                        unknown. The East African landscape was both breathtakingly beautiful and treacherous: there
                                        were vast plains, dense forests, and towering mountains that seemed to reach up to the heavens.
                                    </p>
                                    <p className="mb-3">
                                        The journey was fraught with danger at every turn: Hakki Pasha and his team had to deal with wild
                                        animals, hostile tribes, and treacherous terrain. But Hakki Pasha was undeterred, driven by an
                                        unquenchable thirst for knowledge and discovery.
                                    </p>
                                    <p className="mb-3">
                                        As he collected specimens, he encountered countless challenges and obstacles along the way, but
                                        his persistence paid off. He discovered new animal species, studied the local geology and
                                        geography, even helped establish Ottoman trading posts in the area.
                                    </p>
                                    <p className="mb-3">
                                        Hakki Pasha's journey through East Africa was not just an important scientific exploration, but
                                        a dramatic adventure that captured the imagination of people around the world.
                                        His bravery and dedication to his work inspired generations of explorers to come, and
                                        his legacy lives on to this day.
                                    </p>
                                </Grid>
                                <Grid item md={4} justifyContent='flex-end' alignItems='flex-end'>
                                    <img
                                        src={HakkiPasha}
                                        alt="Hakki Pasha Logo"
                                        style={{
                                            width: 345,
                                            float: 'right',
                                        }} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section-padding'>
                <div className='container'>
                    <h6 style={{ fontSize: 28, marginBottom: 0 }}>Hours</h6>
                    <ul className="list-unstyled page-list mb-30">
                        <li>
                            <div className="page-list-icon">
                                {" "}
                                <span className="ti-time"></span>{" "}
                            </div>
                            <div className="page-list-text">
                                <p>Breakfast: 7.00 am – 11.00 am (daily)</p>
                            </div>
                        </li>
                        <li>
                            <div className="page-list-icon">
                                {" "}
                                <span className="ti-time"></span>{" "}
                            </div>
                            <div className="page-list-text">
                                <p>Lunch: 12.00 noon – 2.00 pm (daily)</p>
                            </div>
                        </li>
                        <li>
                            <div className="page-list-icon">
                                {" "}
                                <span className="ti-time"></span>{" "}
                            </div>
                            <div className="page-list-text">
                                <p>
                                    Dinner: open from 6.30 pm, last order at 10.00 pm (daily)
                                </p>
                            </div>
                        </li>
                    </ul>
                    <h6 style={{ fontSize: 28, marginBottom: 0 }}>Dress Code</h6>
                    <p>Smart casual (no shorts, hats, or sandals permitted)</p>
                    <h6 style={{ fontSize: 28, marginBottom: 0 }}>Terrace</h6>
                    <p>Open for drinks only</p>
                </div>
            </section>
            {/* <!-- Restaurant Menu --> */}
            <section
                id="menu"
                className="restaurant-menu menu section-padding bg-black"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-subtitle">
                                <span>The Emin Pasha Hotel</span>
                            </div>
                            <div className="section-title">
                                <span>Hakki Pasha Restaurant Menu</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div id="restuarant-menus" className="col-md-12">
                            <div className="row">
                                {responseArrayData.length > 0 ?
                                    <>
                                        <Tabs
                                            centered
                                            style={{ marginBottom: 39 }}
                                            value={value}
                                            allowScrollButtonsMobile
                                            scrollButtons={true}
                                            onChange={handleChange}
                                            aria-label="basic tabs example"
                                        >
                                            {
                                                responseArrayData.map(item => {
                                                    return (
                                                        <Tab key={item.id} label={item.menu_title} style={{ fontSize: 15, color: '#bdbdbb', }} />
                                                    )
                                                })
                                            }
                                            {/* <Tab label="Starters" style={{ fontSize: 20, color: '#bdbdbb', }} />
                                    <Tab label="Mains" style={{ fontSize: 20, color: '#bdbdbb', }} />
                                    <Tab label="Salads" style={{ fontSize: 20, color: '#bdbdbb', }} />
                                    <Tab label="Breakfast" style={{ fontSize: 20, color: '#bdbdbb', }} />
                                    <Tab label="Dessert" style={{ fontSize: 20, color: '#bdbdbb', }} />
                                    <Tab label="Wine" style={{ fontSize: 20, color: '#bdbdbb', }} /> */}
                                        </Tabs>
                                        <div className='tab-order-content'>
                                            <OrderList orderArray={currentMenu} />
                                        </div>
                                    </>
                                    :
                                    <div className='cart-empty'>
                                        <div className="row">
                                            <div style={{ display: 'flex', justifyContent: 'center' }} className="col-md-12">
                                                <HourglassEmptyIcon style={{ color: '#cdcccc', fontSize: 83 }} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }} className='col-md-12'>
                                                <p style={{ fontWeight: 'bold', fontSize: 29 }}>Menu not available </p>

                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AllDayRestaurant;