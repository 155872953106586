import React from 'react';
import EminPashaImage from '../../images/logo/emin.jpg';

const AirportTransfer = () => {
    return (
        <div className='about-hotel-page'>
            {/* Header Banner */}
            <div
                className='banner-header section-padding valign bg-img bg-fixed'
                data-overlay-dark='4'
                data-background='img/slider/1.jpg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 caption mt-90'>
                            <h5>The Emin Pasha Hotel</h5>
                            <h1>Airport Transfer Service</h1>
                        </div>
                    </div>
                </div>
            </div>

            <section id='preface-section' className="rooms-page section-padding" data-scroll-index="1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {" "}
                            <span>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                            </span>
                            <div className="section-title">We provide the most convenient airport transfer service</div>
                            <p className="mb-2">
                                Our airport transfer services are designed to cater to your
                                individual needs, ensuring your comfort and peace of mind.
                                Whether you're traveling for business or pleasure, 
                                our airport transfer services provide a convenient 
                                and stress-free way to begin and end your trip. 
                                Allow us to take care of the logistics while you 
                                focus on enjoying your stay with us.
                                
                            </p>
                            <p>
                                To arrange your airport transfer or inquire 
                                further about our services, please contact our 
                                concierge team, who will be delighted to assist 
                                you via <a href="mailto:reservations@eminpasha.com" style={{color: '#e4a853', fontWeight: 'bold', letterSpacing: 2}}>reservations@eminpasha.com</a>.
                                Here's what you can expect when you choose our airport
                                transfer services.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section id='content-history-section' className="rooms-page section-padding" data-scroll-index="1">
                <div className="container">
                    <div className='history-section'>
                        <div className='row'>
                        <div className="col-md-12">
                            <div className="section-subtitle" style={{color: '#e0be8d'}}>The Emin Pasha Hotel | Airport Transfer Service</div>
                            <div className="section-title" style={{color: '#e4a853'}}>What we offer</div>
                        </div>
                            <div className='col-md-6 mb-5'>
                                <h3 className='mb-3' style={{ color: '#fae7cb' }}  >
                                    <i style={{ color: '#e4a853' }} className="ti-check"></i>{' '}
                                    Comfortable and Well-Maintained Vehicles
                                </h3>
                                <p>
                                    We understand the importance of starting your trip
                                    on the right note. That's why we provide a fleet of
                                    comfortable and well-maintained vehicles to
                                    transport you to and from the airport. Our vehicles
                                    are equipped with modern amenities and spacious
                                    interiors, allowing you to relax and unwind during
                                    your journey.
                                </p>
                            </div>
                            <div className='col-md-6  mb-5'>
                                <h3 className='mb-3' style={{ color: '#fae7cb' }}  >
                                    <i style={{ color: '#e4a853' }} className="ti-check"></i>{' '}
                                    Professional and Courteous Chauffeurs
                                </h3>
                                <p>
                                    Our team of experienced chauffeurs is dedicated 
                                    to providing you with a seamless and enjoyable 
                                    travel experience. From the moment they greet 
                                    you at the airport to the time they drop you 
                                    off at your destination, our chauffeurs prioritize 
                                    your safety, comfort, and satisfaction. Expect 
                                    nothing less than professionalism, punctuality, 
                                    and impeccable service.
                                </p>
                            </div>
                            <div className='col-md-6  mb-5'>
                                <h3 className='mb-3' style={{ color: '#fae7cb' }}  >
                                    <i style={{ color: '#e4a853' }} className="ti-check"></i>{' '}
                                    Personalized Assistance
                                </h3>
                                <p>
                                    We understand that every guest is unique, and we 
                                    strive to accommodate your specific requirements. 
                                    Whether you need assistance with luggage handling 
                                    or have any special requests, our chauffeurs are 
                                    trained to provide personalized assistance to make 
                                    your journey as comfortable as possible.
                                </p>
                            </div>
                            <div className='col-md-6  mb-5'>
                                <h3 className='mb-3' style={{ color: '#fae7cb' }}  >
                                    <i style={{ color: '#e4a853' }} className="ti-check"></i>{' '}
                                    Efficient Airport Transfers
                                </h3>
                                <p>
                                    Our airport transfer services are designed to 
                                    be efficient and reliable. We closely monitor 
                                    flight schedules to ensure that our chauffeurs 
                                    are ready to greet you upon your arrival, 
                                    regardless of any unexpected delays. With our 
                                    seamless coordination, you can trust that your 
                                    transportation needs will be met promptly and 
                                    efficiently.
                                </p>
                            </div>
                            <div className='col-md-12  mb-0'>
                                <div className=''>
                                    <div>
                                        <h3 className='mb-3' style={{ color: '#fae7cb' }}  >
                                            <i style={{ color: '#e4a853' }} className="ti-check"></i>{' '}
                                            Safety and Security
                                        </h3>
                                        <p>
                                            At The Emin Pasha Hotel & Spa, your safety and 
                                            security are of utmost importance to us. Rest 
                                            assured that our vehicles undergo regular maintenance 
                                            and adhere to the highest safety standards. Our 
                                            chauffeurs are well-trained professionals who 
                                            prioritize your well-being throughout your journey.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AirportTransfer;
