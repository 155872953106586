import React from 'react';

function HomeFacilities() {
  return (
    <div>
      {/*  Facilties  */}
      <section className="facilties section-padding-facilities">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-subtitle">Our Services</div>
              <div className="section-title">Hotel Facilities</div>
            </div>
          </div>
          <div className="row equal-height">
            <div className="col-md-4">
              <div
                className="single-facility animate-box"
                data-animate-effect="fadeInUp"
              >
                <span className="flaticon-world"></span>
                <h5>Pick Up & Drop</h5>
                <p>
                  We’ll pick you up from the airport and also furnish a ride to the airport on departure, if your stay is more than a week
                </p>
                <div className="facility-shape">
                  {" "}
                  <span className="flaticon-world"></span>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="single-facility animate-box"
                data-animate-effect="fadeInUp"
              >
                <span className="flaticon-car"></span>
                <h5>Parking Space</h5>
                <p>
                We have adequate and secure parking space for all guests in our state-of-the-art parking lot manned by security personnel.
                </p>
                <div className="facility-shape">
                  {" "}
                  <span className="flaticon-car"></span>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="single-facility animate-box"
                data-animate-effect="fadeInUp"
              >
                <span className="flaticon-bed"></span>
                <h5>Room Service</h5>
                <p>
                  We provide room service across our room category spectrum 24/7 and with a Special-Select In-Room Dining Menu.
                </p>
                <div className="facility-shape">
                  {" "}
                  <span className="flaticon-bed"></span>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="single-facility animate-box"
                data-animate-effect="fadeInUp"
              >
                <span className="flaticon-swimming"></span>
                <h5>Swimming Pool</h5>
                <p>
                The Emin Pasha boasts of an ultra-modern pool; accessorized by stone finishing and grating.
                </p>
                <div className="facility-shape">
                  {" "}
                  <span className="flaticon-swimming"></span>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="single-facility animate-box"
                data-animate-effect="fadeInUp"
              >
                <span className="flaticon-wifi"></span>
                <h5>Fibre Internet</h5>
                <p>
                  We provide fast, unlimited internet, across a secure cabled and Wi-Fi network as well as dedicated internet for In-house Events.
                </p>
                <div className="facility-shape">
                  {" "}
                  <span className="flaticon-wifi"></span>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="single-facility animate-box"
                data-animate-effect="fadeInUp"
              >
                <span className="flaticon-breakfast"></span>
                <h5>Breakfast</h5>
                <p>
                  Our Guests will be treated to a Continental and or Full English Breakfast tailored to International Cuisine ranges and luxury bespoke serving.
                </p>
                <div className="facility-shape">
                  {" "}
                  <span className="flaticon-breakfast"></span>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeFacilities;
