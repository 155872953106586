import React, {useState,useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Image1 from "../../images/restaurant/burger.jpg";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {connect} from 'react-redux';
import { CurrencyFormat } from '../../shared/Formatter';
import { AddProductToCart } from '../../shared/utils/api/api';

const mapDispatchToProps = dispatch => {
    return {
      addItemToCart: product => dispatch({type: 'ADD_TO_CART', payload: product}),
      setOrderType: product => dispatch({type: 'SET_ORDER_TYPE', payload: product}),
    };
};

const OrderNow = (props) => {
    const [accompainment, setAccompainment] = useState("");
    const [SelectedOrder, setSelectedOrder] = useState({});
    const location = useLocation();
    console.log('::debug Props:', props);

    const handleChange = (event) => {
        setAccompainment(event.target.value);
    };

    const navigate = useNavigate();

    // const selectedOrder = {
    //     id: 1,
    //     name: 'Rusty’s Burger',
    //     price: 27500,
    //     image: Image1,
    //     slug: 'Buy 1',
    //     slug_text: 'Get 2'
    // };

    const AddItemToCart = async (product) => {
        try {
            await AddProductToCart({
                product_id: product.id,
                quantity: 1
            }).then((response) => {
              console.log('::debug Booking cart response:', response);
            });
          } catch (error) {
            console.log('::debug BOOKing cart eerror:', error);
          }
    }

    useEffect(() => {
        const { orderObj } = location.state;
        setSelectedOrder(orderObj);
      }, [location.state]);

    const handleOrderNow = async () => {
      await props.addItemToCart(SelectedOrder);
      await props.setOrderType(true);
      navigate('/orders/checkout');
    }

    return (
        <div id='order-list-page'>
            {/* Header Banner */}
            <div
                className='banner-header section-padding valign bg-orderimg bg-fixed'
                data-overlay-dark='4'
                data-background='img/slider/1.jpg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 caption mt-90'>
                            <h5>The Emin Pasha Hotel</h5>
                            <h1>Order Now </h1>
                        </div>
                    </div>
                </div>
            </div>
            <section id='order-dashboard' className="facilties section-padding bg-order-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-subtitle">Order Now Page</div>
                            <div className="section-title">Your Selected Order</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6">
                            <div className="order-now-img">
                            {
                                SelectedOrder.item_images && (
                                <img src={SelectedOrder.item_images[0].image_name} alt="" />
                                )
                            }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="order-now-detail">
                                <h5>{SelectedOrder.title}</h5>
                                <div className="amount-tag">
                                    <p>{`${CurrencyFormat(SelectedOrder.price ?? 0)} USD`}</p>
                                </div>
                                <div className="order-accompaignment">
                                    <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-standard-label">Accompainment</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={accompainment}
                                            onChange={handleChange}
                                            label="Accompainment"
                                        >
                                            <MenuItem value="">
                                                <em>Select an accompainment</em>
                                            </MenuItem>
                                            <MenuItem value={10}>Steamed Rice</MenuItem>
                                            <MenuItem value={20}>Cock Tail</MenuItem>
                                            <MenuItem value={30}>Sausage</MenuItem>
                                            <MenuItem value={40}>Pineapple Juice</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="btn-row-section mt-5">
                                    <div className="order-list-btn-row">
                                        <div className="btn-order">
                                            {" "}
                                            <button onClick={() => {
                                                props.addItemToCart(SelectedOrder);
                                                props.setOrderType(true);
                                                }}>
                                                <span>Add To Cart</span>
                                            </button>{" "}
                                        </div>

                                        <div className="order-now-btn">
                                            {" "}
                                            <button onClick={() => handleOrderNow()}>
                                                <span>checkout</span>
                                            </button>{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default connect(null,mapDispatchToProps)(OrderNow);
