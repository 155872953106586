import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { ResetUserPassword } from './../../shared/utils/api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

const ForgotPassword = (props) => {
    const [userEmail, setUserEmail] = useState('');
    const [displayResetMain, setDisplayResetMain] = useState(true);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    const handleResetUserPassword = (e) => {
        e.preventDefault();
        console.log('::debug handleResetUserPasswordfunction');
        props.setShowProgress(true);
        try {
            const postData = {
                email: userEmail
              }
            ResetUserPassword(postData).then(response => {
                props.setShowProgress(false);
                console.log('::debug reset successful:', response);
                setDisplayResetMain(false);

            }).catch(error => {
                props.setShowProgress(false);
                console.log('::debug function catch');
                setOpenSnackBar(true);
                setErrorMessage(error.response.message);
            });
        } catch (error) {
            props.setShowProgress(false);
            console.log('::debug try catch');
            setOpenSnackBar(true);
            setErrorMessage(error.response.message);
        }
    };

    const handleClose = () => {
        setOpenSnackBar(false);
    };

    const handleBackToLogin = () => {
        setDisplayResetMain(true);
        navigate('/auth/login');
    }

    return (
        <div id='order-list-page'>
            {/* Header Banner */}
            <div
                className='section-padding valign'
                style={{
                    backgroundColor: '#f8f7f6',
                }}
                data-overlay-dark='2'>
                <div className='container'>
                    <div className='row'>

                        {displayResetMain ? <>
                            <div className='col-md-12 caption' style={{ marginTop: 60 }}>
                                <div className="snackbark-sect">
                                    <Snackbar
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        open={openSnackBar}
                                        autoHideDuration={6000}
                                        onClose={handleClose}
                                    >
                                        <Alert variant="filled" severity="error">{errorMessage}</Alert>
                                    </Snackbar>
                                    {/* <div className="section-subtitle">Our Reservation Policy</div> */}
                                    <div className="section-title" style={{ color: '#aa8453', textAlign: 'center' }}>Enter Your Email</div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                                >
                                    <div
                                        className='login-input-form'
                                        style={{
                                            border: '2px solid #eee',
                                            borderRadius: 23,
                                            padding: 40,
                                        }}
                                    >
                                        <FormControl variant="standard" fullWidth sx={{ minWidth: 120 }}>
                                            <TextField
                                                id="standard-basic-useremail"
                                                name="useremail"
                                                required
                                                value={userEmail}
                                                onChange={(e) => { setUserEmail(e.target.value); }}
                                                label="Email"
                                                type='email'
                                                variant="standard" />
                                        </FormControl>
                                        <div className='float-right'>
                                            <Link to="/auth/login" style={{ color: '#2770cf', fontWeight: 'bold', fontSize: 20 }}>Back To Login</Link>
                                        </div>
                                        <button
                                            className='butn-dark'
                                            onClick={(e) => { e.preventDefault(); handleResetUserPassword(e) }}
                                            style={{
                                                width: '100%',
                                                marginTop: '25px',
                                                backgroundColor: '#aa8453',
                                                color: '#ffffff',
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                fontSize: 25,
                                            }}
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </> : <div className='col-md-12'>
                            <div className='reset-sucess-icon' style={{ textAlign: 'center', marginTop: 90 }}>
                                <FontAwesomeIcon icon={faCircleCheck} color="#43a367" style={{ fontSize: 100 }} />
                            </div>
                            <div className="section-title" style={{ color: '#aa8453', textAlign: 'center' }}>Password Reset Successful!</div>
                            <div
                                style={{ textAlign: 'center' }}>
                                <p>
                                    Congratulations! Your password reset request has been successfully
                                    processed. Your account security is our top priority, and we're pleased
                                    to inform you that your new password has been set, check your email
                                    {' '}<span style={{ color: '#aa8453', fontWeight: 'bold' }}>{userEmail}</span>{' '} to
                                    complete the operation.</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className='butn-dark'>
                                    <button onClick={() => handleBackToLogin()}>
                                        <span>Back To Login</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ForgotPassword;
