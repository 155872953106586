import React, { useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Image1 from "../../images/outdoor/outdoor11.jpeg";
import Image2 from '../../images/spa/spa7.jpeg';
import Image3 from '../../images/meetingrooms/meetingroom1.jpeg';
import BandImage from '../../images/general/band.jpeg';
import BurgerOffer from '../../images/general/offer.jpeg';
import Image4 from '../../images/event/friday-event.jpeg';
import { Link } from "react-router-dom";
import Image5 from "../../images/event/buffet.jpeg";
import Image6 from "../../images/meetingrooms/meetingroom7.jpeg";
import { Lightbox } from "react-modal-image";
// import useBreakPoint from '../../shared/breakPoints/useBreakPoint';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function HomePricing() {
  // const { matchesDesktop } = useBreakPoint();
  const [showImageViewModal, setShowImageViewModal] = useState(false);
  const [imageSrc, setImageSrc] = useState();
  const [imageCaptionText, setImageCaptionText] = useState();

  const handleCloseModal = () => {
    setShowImageViewModal(false);
  };

  const handleDisplayImageModal = (imgSrc, text) => {
    setImageCaptionText(text);
    setImageSrc(imgSrc);
    setShowImageViewModal(true);
  }

  const sliderContent = [
    {
      eventName: 'Barbed wire music band',
      category: 'Event',
      image: BandImage,
      date: 'Every Wednesday'
    },
    {
      eventName: 'Special American Burger Offer',
      category: 'Offer',
      image: BurgerOffer,
      date: 'Every Wednesday'
    },
    {
      eventName: 'Barbed wire music band',
      category: 'Event',
      image: Image1,
      date: 'To be Communicated'
    },
    {
      eventName: 'Barbed wire music band',
      category: 'Event',
      image: BandImage,
      date: 'Every Wednesday'
    },
    {
      eventName: 'Barbed wire music band',
      category: 'Event',
      image: BandImage,
      date: 'Every Wednesday'
    },
    {
      eventName: 'Barbed wire music band',
      category: 'Event',
      image: BandImage,
      date: 'Every Wednesday'
    }
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.http://eminpasha.com/
    }
  };

  return (
    <div>
      <section class="news section-padding bg-black">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-subtitle">
                <span>Hotel Blog</span>
              </div>
              <div class="section-title">
                <span>Our News</span>
              </div>
            </div>
            {showImageViewModal &&
              <div className="col-md-12">
                <Lightbox
                  small={imageSrc}
                  medium={imageSrc}
                  hideZoom={false}
                  alt={imageCaptionText}
                  onClose={() => handleCloseModal()}
                />
              </div>
            }
          </div>
          <div class="row">
            <div class="col-md-12">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={6000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                <div class="item">
                  <div class="show-pointer-cursor" onClick={() => handleDisplayImageModal(Image4, 'Live Band')}>
                    <div class="position-re o-hidden">
                      {" "}
                      <img src={Image4} alt="" />

                    </div>
                    <div class="con">
                      {" "}
                      <span class="category">
                        <h1>
                        Event
                        </h1>
                      </span>
                      <h5>
                        Live Band Every Friday
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="show-pointer-cursor" onClick={() => handleDisplayImageModal(Image5, 'Family Brunch')}>
                    <div class="position-re o-hidden">
                      {" "}
                      <img src={Image5} alt="" />
                      <div class="date">
                        <Link to="#">
                          {" "}
                          <span>27</span> <i>August</i>{" "}
                        </Link>
                      </div>

                    </div>
                    <div class="con">
                      {" "}
                      <span class="category">
                        <h1>
                        Promotion
                        </h1>
                      </span>
                      <h5>
                        Family Brunch
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="show-pointer-cursor" onClick={() => handleDisplayImageModal(BandImage, 'Barbed wire music band')}>
                    <div class="position-re o-hidden">
                      {" "}
                      <img src={BandImage} alt="" />

                    </div>
                    <div class="con">
                      {" "}
                      <span class="category">
                        <h1>
                          Event
                        </h1>
                      </span>
                      <h5>
                        Barbed wire music band
                      </h5>
                    </div>
                  </div>

                </div>
                <div class="item">
                  <div class="show-pointer-cursor" onClick={() => handleDisplayImageModal(BurgerOffer, 'Special American Burger Offer')}>
                    <div class="position-re o-hidden">
                      {" "}
                      <img src={BurgerOffer} alt="" />

                    </div>
                    <div class="con">
                      {" "}
                      <span class="category">
                        <h1>
                          Offer
                        </h1>
                      </span>
                      <h5>
                        Special American Burger Offer
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="show-pointer-cursor"
                    onClick={() =>
                      handleDisplayImageModal(Image1, 'Officially opening Sir Baker Fine Dinning')}>
                    <div class="position-re o-hidden">
                      {" "}
                      <img src={Image1} alt="" />
                      <div class="date">
                        <Link to="/restaurant/sir-baker-dinning">
                          {" "}
                          <span>To be</span> <i>Communicated</i>{" "}
                        </Link>
                      </div>
                    </div>
                    <div class="con">
                      {" "}
                      <span class="category">
                        <h1>
                          Event
                        </h1>
                      </span>
                      <h5>
                        Officially opening Sir Baker Fine Dinning
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="item">
                <div class="show-pointer-cursor"
                    onClick={() =>
                      handleDisplayImageModal(Image2, 'The All New Swanky Spa')}>
                  <div class="position-re o-hidden">
                    {" "}
                    <img src={Image2} alt="" />
                    <div class="date">
                      <Link to="/fitnessandspa">
                        {" "}
                        <span>To be</span> <i>Communicated</i>{" "}
                      </Link>
                    </div>
                  </div>
                  <div class="con">
                    {" "}
                    <span class="category">
                      <h1>Announcement</h1>
                    </span>
                    <h5>
                      The All New Swanky Spa
                    </h5>
                  </div>
                  </div>
                </div>
                <div class="item">
                <div class="show-pointer-cursor"
                    onClick={() =>
                      handleDisplayImageModal(Image3, 'Wedding Discounts')}>
                  <div class="position-re o-hidden">
                    {" "}
                    <img src={Image3} alt="" />
                    <div class="date">
                      <a href="#">
                        {" "}
                        <span>Upon</span> <i>Reservation</i>{" "}
                      </a>
                    </div>
                  </div>
                  <div class="con">
                    {" "}
                    <span class="category">
                      <h1>OFFER</h1>
                    </span>
                    <h5>
                      Wedding Discounts
                    </h5>
                  </div>
                  </div>
                </div>
                {/* <div class="item">
                  <div class="position-re o-hidden">
                    {" "}
                    <img src={Image4} alt="" />
                    <div class="date">
                      <a href="#">
                        {" "}
                        <span>Dec</span> <i>08</i>{" "}
                      </a>
                    </div>
                  </div>
                  <div class="con">
                    <span class="category">
                      <a href="#">ADVERTISEMENT</a>
                    </span>
                    <h5>
                      <a href="#">Live Band Concert</a>
                    </h5>
                  </div>
                </div> */}

                {/* <div class="item">
                  <div class="position-re o-hidden">
                    {" "}
                    <img src={Image5} alt="" />
                    <div class="date">
                      <a href="#">
                        {" "}
                        <span>Every</span> <i>Friday</i>{" "}
                      </a>
                    </div>
                  </div>
                  <div class="con">
                    {" "}
                    <span class="category">
                      <a href="#">Event</a>
                    </span>
                    <h5>
                      <a href="#">Band</a>
                    </h5>
                  </div>
                </div> */}
                {/* <div class="item">
                  <div class="position-re o-hidden">
                    {" "}
                    <img src={Image6} alt="" />
                    <div class="date">
                      <a href="#">
                        {" "}
                        <span>Dec</span> <i>08</i>{" "}
                      </a>
                    </div>
                  </div>
                  <div class="con">
                    {" "}
                    <span class="category">
                      <a href="#">OFFER</a>
                    </span>
                    <h5>
                      <a href="#">Christmass Party Discounts.</a>
                    </h5>
                  </div>
                </div> */}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomePricing;
