import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Image2 from "../../images/bars/bar5.jpeg";
import Image1 from "../../images/outdoor/outdoor11.jpeg";
import Image3 from "../../images/outdoor/outdoor5.jpeg";
import Image4 from "../../images/outdoor/bakery-products.jpg";
import { GetAllRestaurants } from '../../shared/utils/api/api';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';


const OrderLanding = (props) => {
    const [restaurantsArray, setRestaurantsArray] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        const fetchRooms = async () => {
            props.setShowProgress(true);
            try {
                await GetAllRestaurants().get().then((response) => {
                    setRestaurantsArray(response.data.data);
                    console.log('xxx restaurant Response:', response);
                });
            } catch (error) {
                props.setShowProgress(false);
                setOpenSnackBar(true);
                setErrorMessage(error.response.data.errors.query._schema[0] ?? 'Error occured reload page');
                console.log(error);
            } finally {
                props.setShowProgress(false);
            }
        };
        fetchRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const attachImage = (restaurant) => {
        if (restaurant.res_name === 'Sir Baker Dinning') {
            return Image1;
        } else if (restaurant.res_name === 'All day restuarant') {
            return Image3;
        } else if (restaurant.res_name === "Baker's Kitchen") {
            return Image4;
        } else if (restaurant.res_name === 'Rooftop Bar') {
            return Image2;
        } else {
            return Image1;
        }
    }

    const handleClose = () => {
        setOpenSnackBar(false);
      };

    return (
        <div id='order-dashboard-page'>
            {/* Header Banner */}
            <div
                className='banner-header section-padding valign bg-orderimg bg-fixed'
                data-overlay-dark='4'
                data-background='img/slider/1.jpg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 caption mt-90'>
                            <h5>The Emin Pasha Hotel</h5>
                            <h1>Orders</h1>
                        </div>
                    </div>
                </div>
            </div>
            {/* <section id='order-dashboard' className="facilties section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-subtitle">Order Menu</div>
                            <div className="section-title">We now deliver</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div
                                className="single-facility animate-box"
                                data-animate-effect="fadeInUp"
                            >
                                <div>
                                    <span className='vertical-center'><i className='ti-agenda'></i></span>
                                    <div className='order-card-content'>
                                        <h5>Main</h5>
                                        <p>
                                            Look through our menu and order online.
                                        </p>
                                        <div className="facility-shape">
                                            {" "}
                                            <span><i className='ti-agenda'></i></span>{" "}
                                        </div>

                                    </div>
                                </div>
                                <div className="btn-order">
                                    {" "}
                                    <Link to="/orders/menu" data-scroll-nav="1">
                                        <span>View</span>
                                    </Link>{" "}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div
                                className="single-facility animate-box"
                                data-animate-effect="fadeInUp"
                            >
                                <div>
                                    <span className='vertical-center'><i className='ti-write'></i></span>
                                    <div className='order-card-content'>
                                        <h5>Salads</h5>
                                        <p>
                                            Place your order now for immediate delivery.
                                        </p>
                                        <div className="facility-shape">
                                            {" "}
                                            <span><i className='ti-write'></i></span>{" "}
                                        </div>

                                    </div>
                                </div>
                                <div className="btn-order">
                                    {" "}
                                    <Link to="/orders/ordernow" data-scroll-nav="1">
                                        <span>Order now</span>
                                    </Link>{" "}
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div
                                className="single-facility animate-box"
                                data-animate-effect="fadeInUp"
                            >
                                <div>
                                    <span className='vertical-center'><i className='ti-time'></i></span>
                                    <div className='order-card-content'>
                                        <h5>Starter</h5>
                                        <p>
                                            Place you order in advance for later pickup or delivery
                                        </p>
                                        <div className="facility-shape">
                                            {" "}
                                            <span><i className='ti-time'></i></span>{" "}
                                        </div>

                                    </div>
                                </div>
                                <div className="btn-order">
                                    {" "}
                                    <Link to="/orders/orderforlater" data-scroll-nav="1">
                                        <span>Order Later</span>
                                    </Link>{" "}
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div
                                className="single-facility animate-box"
                                data-animate-effect="fadeInUp"
                            >
                                <div>
                                    <span className='vertical-center'><i className='ti-time'></i></span>
                                    <div className='order-card-content'>
                                        <h5>Dessert</h5>
                                        <p>
                                            Place you order in advance for later pickup or delivery
                                        </p>
                                        <div className="facility-shape">
                                            {" "}
                                            <span><i className='ti-time'></i></span>{" "}
                                        </div>

                                    </div>
                                </div>
                                <div className="btn-order">
                                    {" "}
                                    <Link to="/orders/orderforlater" data-scroll-nav="1">
                                        <span>Order Later</span>
                                    </Link>{" "}
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div
                                className="single-facility animate-box"
                                data-animate-effect="fadeInUp"
                            >
                                <div>
                                    <span className='vertical-center'><i className='ti-time'></i></span>
                                    <div className='order-card-content'>
                                        <h5>Breakfast</h5>
                                        <p>
                                            Place you order in advance for later pickup or delivery
                                        </p>
                                        <div className="facility-shape">
                                            {" "}
                                            <span><i className='ti-time'></i></span>{" "}
                                        </div>

                                    </div>
                                </div>
                                <div className="btn-order">
                                    {" "}
                                    <Link to="/orders/orderforlater" data-scroll-nav="1">
                                        <span>Order Later</span>
                                    </Link>{" "}
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div
                                className="single-facility animate-box"
                                data-animate-effect="fadeInUp"
                            >
                                <div>
                                    <span className='vertical-center'><i className='ti-time'></i></span>
                                    <div className='order-card-content'>
                                        <h5>Wine</h5>
                                        <p>
                                            Place you order in advance for later pickup or delivery
                                        </p>
                                        <div className="facility-shape">
                                            {" "}
                                            <span><i className='ti-time'></i></span>{" "}
                                        </div>

                                    </div>
                                </div>
                                <div className="btn-order">
                                    {" "}
                                    <Link to="/orders/orderforlater" data-scroll-nav="1">
                                        <span>Order Later</span>
                                    </Link>{" "}
                                </div>

                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='item'>
                                <div className='position-re o-hidden'>
                                    {' '}
                                    <img src={Image5} alt='' />{' '}
                                </div>{' '}
                                <span className='category'>
                                    <Link to='/booking'>Book</Link>
                                </span>
                                <div className='con'>
                                    <h6>
                                        <Link to='/orders/wine'>
                                            250,000 USD / Night
                                        </Link>
                                    </h6>
                                    <h5>
                                        <Link to='/rooms/guestrooms/:guestroomid'>
                                            Superior Suites
                                        </Link>
                                    </h5>
                                    <div className='line'></div>
                                    <div className='row facilities'>
                                        <div className='col col-md-7'>
                                            <ul>
                                                <li>
                                                    <i className='flaticon-bed'></i>
                                                </li>
                                                <li>
                                                    <i className='flaticon-bath'></i>
                                                </li>
                                                <li>
                                                    <i className='flaticon-breakfast'></i>
                                                </li>
                                                <li>
                                                    <i className='flaticon-towel'></i>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col col-md-5 text-right'>
                                            <div className='permalink'>
                                                <Link to='/rooms/guestrooms/:guestroomid'>
                                                    Details <i className='ti-arrow-right'></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section
                className='rooms1 section-padding bg-cream'
                data-scroll-index='1'>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-subtitle">Restaurant And Bar Category</div>
                            <div className="section-title">We now deliver</div>
                        </div>
                    </div>
                    <div className='row'>
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={openSnackBar}
                            autoHideDuration={6000}
                            onClose={handleClose}
                        >
                            <Alert variant="filled" severity="error">{errorMessage}</Alert>
                        </Snackbar>
                        {
                            restaurantsArray.length > 0 ? (
                                restaurantsArray.map(restaurant => {
                                    return (
                                        <div key={restaurant.id} className='col-md-6'>
                                            <div className='item'>
                                                <div className='position-re o-hidden'>
                                                    {' '}
                                                    <img src={attachImage(restaurant)} alt='' />{' '}
                                                </div>{' '}
                                                <div className='con'>
                                                    <h5>
                                                        <Link to='/orders/dashboard' state={{ restaurantId: restaurant.id }}>
                                                            {restaurant.res_name}
                                                        </Link>
                                                    </h5>
                                                    <div className='line'></div>
                                                    <div className='row facilities'>
                                                        <div className='col col-md-12 text-right'>
                                                            <div className='permalink'>
                                                                <Link to='/orders/dashboard' state={{ restaurantId: restaurant.id }}>
                                                                    View {restaurant.res_name} Menu <i className='ti-arrow-right'></i>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );

                                })
                            ) : (
                                <div className='cart-empty'>
                                    <div className="row">
                                        <div style={{ display: 'flex', justifyContent: 'center' }} className="col-md-12">
                                            <HourglassEmptyIcon style={{ color: '#cdcccc', fontSize: 83 }} />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }} className='col-md-12'>
                                            <p style={{ fontWeight: 'bold', fontSize: 29 }}>No restaurants available </p>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {/* <div className='col-md-6'>
                            <div className='item'>
                                <div className='position-re o-hidden'>
                                    {' '}
                                    <img src={Image1} alt='' />{' '}
                                </div>{' '}
                                <div className='con'>
                                    <h5>
                                        <Link to='/orders/dashboard' state={{}}>
                                            Sir Baker Dinning
                                        </Link>
                                    </h5>
                                    <div className='line'></div>
                                    <div className='row facilities'>
                                        <div className='col col-md-12 text-right'>
                                            <div className='permalink'>
                                                <Link to='/orders/dashboard'>
                                                    View Sir Baker Dinning Menu <i className='ti-arrow-right'></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='item'>
                                <div className='position-re o-hidden'>
                                    {' '}
                                    <img src={Image3} alt='' />{' '}
                                </div>{' '}
                                <div className='con'>
                                    <h5>
                                        <Link to='/orders/dashboard'>
                                            All Day Restaurant
                                        </Link>
                                    </h5>
                                    <div className='line'></div>
                                    <div className='row facilities'>
                                        <div className='col col-md-12 text-right'>
                                            <div className='permalink'>
                                                <Link to='/orders/dashboard'>
                                                    View All Day Restaurant Menu <i className='ti-arrow-right'></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='item'>
                                <div className='position-re o-hidden'>
                                    {' '}
                                    <img src={Image4} alt='' />{' '}
                                </div>{' '}
                                <div className='con'>
                                    <h5>
                                        <Link to='/orders/bakers-kitchen'>
                                            Manutea Wine & Whisky Lounge
                                        </Link>
                                    </h5>
                                    <div className='line'></div>
                                    <div className='row facilities'>
                                        <div className='col col-md-12 text-right'>
                                            <div className='permalink'>
                                                <Link to='/orders/bakers-kitchen'>
                                                    View Manutea Wine & Whisky Lounge Menu <i className='ti-arrow-right'></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='item'>
                                <div className='position-re o-hidden'>
                                    {' '}
                                    <img src={Image2} alt='' />{' '}
                                </div>{' '}
                                <div className='con'>
                                    <h5>
                                        <Link to='/orders/wine'>
                                            FEZ Wine & Bar
                                        </Link>
                                    </h5>
                                    <div className='line'></div>
                                    <div className='row facilities'>
                                        <div className='col col-md-12 text-right'>
                                            <div className='permalink'>
                                                <Link to='/orders/wine'>
                                                    View FEZ Wine & Bar Menu <i className='ti-arrow-right'></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default OrderLanding;
