import React from 'react';

const Iframe = () => {
  
  return (
    <div>
      <iframe title='booking engine' src="https://services.semper.co.za/BookingEngine/full-layout/date-selection?VenueID=19625&ChannelID=1"></iframe>     
    </div>
  );
};

export default Iframe;
