import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import './components/globalstyles/fonts/AvenirLTStd-Black.otf';
import './components/globalstyles/fonts/AvenirLTStd-Roman.otf';
import './components/globalstyles/fonts/AvenirLTStd-Book.otf';
import CssBaseline from '@mui/material/CssBaseline';
import Roomsandsuites from './pages/rooms-and-suites';
import NavBar from './components/navBar/Navbar';
import Home from './pages/home';
import About from './pages/about';
import Fitnessandspa from './pages/fitness-and-spa';
import Contact from './pages/contact';
import Restruant from './pages/restruant';
// import Booking from './pages/booking';
import Guestroomdetails from './pages/guestroom-details';
import Restruantsandbars from './pages/restruants-and-bars';
import Facilities from './pages/facilities';
import Footer from './components/footer/Footer';
import Giftshop from './pages/giftshop';
import Library from './pages/library';
import Businesscenter from './pages/business-center';
import OrderDashboard from './components/orders/OrderDashboard';
import OrderForLater from './components/orders/OrderForLater';
import OrderMain from './components/orders/OrderMain';
import OrderNow from './components/orders/OrderNow';
import OrderBreakfast from './components/orders/OrderBreakfast';
import OrderDessert from './components/orders/OrderDessert';
import OrderSalads from './components/orders/OrderSalads';
import OrderStarter from './components/orders/OrderStarter';
import OrderWine from './components/orders/OrderWine';
import CheckOut from './components/orders/CheckOut';
import ReservationSuccessful from './components/booking/ReservationSuccessful';
import BookNowForm from './components/booking/BookNowForm';
import SearchRooms from './components/booking/SearchRooms';
import OrderLanding from './components/orders/OrderLanding';
import BakersKitchenDashboard from './components/orders/BakersKitchenDashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from "react-router-dom";
import BookingStepper from './components/booking/BookingStepper';
import AvailableRooms from './components/booking/AvailableRooms';
import SelectedRooms from './components/booking/SelectedRooms';
import MakePayment from './components/booking/MakePayment';
import FineDinning from './components/restruants-and-bars/FineDinning';
import AllDayRestaurant from './components/restruants-and-bars/AllDayRestaurant';
import BakersKitchen from './components/restruants-and-bars/BakersKitchen';
import FezWineAndBar from './components/restruants-and-bars/FezWineAndBar';
import Rooftop from './components/restruants-and-bars/RooftopBar';
import Manutea from './components/restruants-and-bars/ManuteaWine';
import OrderProductDetails from './components/orders/orderProductDetails';
import SwimmingPool from './components/SwimmingPool/SwimmingPool';
import AcropoleLounge from './components/Acropole/AcropoleLounge';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import TermsOfService from './components/TermsOfService/TermsOfService'
import AirportTransfer from './components/services/AirportTransfer';
import Reservations from './components/reservation/reservation';
import MyAccount from './components/myAccount/MyAccount';
import Careers from './components/careers/Careers';
import Login from './components/Auth/Login';
import NotFound from './pages/notFound';
import SemperBooking from './components/booking/SemperBooking';
import ForgotPassword from './components/Auth/ForgotPassword';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function App() {
  const [showProgress, setShowProgress] = useState(false);
  const routePath = useLocation();

  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);

  const theme = createTheme({
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: '#e4a853 !important', // Change the color to your desired label color
            },
          },
        },
      },
    },
  });

  return (
    <div className="App">
      {
        showProgress && (
          <div className='emin-linear-progress-top'>
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          </div>
        )
      }
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NavBar showProgress={showProgress} />
        <Routes>
          <Route path="/rooms" element={<Roomsandsuites setShowProgress={setShowProgress} />} />
          <Route path="/" element={<Home setShowProgress={setShowProgress} />} />
          <Route path="/about" element={<About />} />
          <Route path="/fitnessandspa" element={<Fitnessandspa setShowProgress={setShowProgress} />} />
          <Route path="/contactus" element={<Contact />} />
          <Route
            path="/restruantsandbars/restruants/:restruantid"
            element={<Restruant setShowProgress={setShowProgress} />}
          />
          {/* <Route path="/booking" element={<Booking />} /> */}
          <Route
            path="/rooms/guestrooms/:guestroomid"
            element={<Guestroomdetails setShowProgress={setShowProgress} />}
          />
          <Route
            path="/user/reservation"
            element={<Reservations setShowProgress={setShowProgress} />}
          />
          <Route
            path="/user/my-account"
            element={<MyAccount setShowProgress={setShowProgress} />}
          />
          <Route
            path="/careers"
            element={<Careers setShowProgress={setShowProgress} />}
          />
          <Route
            path="/auth/login"
            element={<Login setShowProgress={setShowProgress} />}
          />
          <Route
            path="/auth/forgot-password"
            element={<ForgotPassword setShowProgress={setShowProgress} />}
          />
          <Route path="/restruantsandbars" element={<Restruantsandbars setShowProgress={setShowProgress} />} />
          <Route path="/facilities" element={<Facilities />} />
          <Route
            path="/facilities/giftshops/:giftshopid"
            element={<Giftshop />}
          />
          <Route path="/facilities/libraries/:libraryid" element={<Library />} />
          <Route
            path="/facilities/businesscenters/:businesscenterid"
            element={<Businesscenter />}
          />
          <Route
            path="/facilities/businesscenters/swimmingpool"
            element={<SwimmingPool />}
          />
          <Route
            path="/facilities/businesscenters/acropole-lounge"
            element={<AcropoleLounge />}
          />
          <Route
            path="/orders/dashboard"
            element={<OrderDashboard />}
          />
          <Route
            path="/services/airport-transfer"
            element={<AirportTransfer />}
          />
          <Route
            path="/orders/main-menu"
            element={<OrderMain />}
          />
          <Route
            path="/orders/break-fast"
            element={<OrderBreakfast />}
          />
          <Route
            path="/orders/dessert"
            element={<OrderDessert />}
          />
          <Route
            path="/orders/salads"
            element={<OrderSalads />}
          />
          <Route
            path="/orders/starter"
            element={<OrderStarter />}
          />
          <Route
            path="/orders/wine"
            element={<OrderWine />}
          />
          <Route
            path="/orders/ordernow"
            element={<OrderNow />}
          />
          <Route
            path="/orders/checkout"
            element={<CheckOut setShowProgress={setShowProgress} />}
          />
          <Route
            path="/booking/available-rooms"
            element={<AvailableRooms setShowProgress={setShowProgress} />}
          />
          <Route
            path="/booking/book-now-form"
            element={<BookNowForm setShowProgress={setShowProgress} />}
          />
          <Route
            path="/booking/selected-rooms"
            element={<SelectedRooms setShowProgress={setShowProgress} />}
          />
          <Route
            path="/booking/make-payment"
            element={<MakePayment setShowProgress={setShowProgress} />}
          />
          <Route
            path="/rooms/search-rooms"
            element={<SearchRooms />}
          />
          <Route
            path="/orders/orderforlater"
            element={<OrderForLater />}
          />
          <Route
            path="/terms-of-service"
            element={<TermsOfService />}
          />
          <Route
            path="/orders/order-product-details"
            element={<OrderProductDetails setShowProgress={setShowProgress} />}
          />
          <Route
            path="/orders/bakers-kitchen"
            element={<BakersKitchenDashboard />}
          />
          <Route
            path="/orders/order-landing"
            element={<OrderLanding setShowProgress={setShowProgress} />}
          />
          <Route
            path="/booking/stepper"
            element={<BookingStepper setShowProgress={setShowProgress} showProgress={showProgress} />}
          />
          <Route
            path="/new-booking"
            element={<SemperBooking />}
          />
          <Route
            path="/restaurant/sir-baker-dinning"
            element={<FineDinning setShowProgress={setShowProgress} />}
          />
          <Route
            path="/restaurant/rooftop-bar"
            element={<Rooftop setShowProgress={setShowProgress} />}
          />
          <Route
            path="/restaurant/manutea-wine-and-whisky-lounge"
            element={<Manutea setShowProgress={setShowProgress} />}
          />
          <Route
            path="/restaurant/hakki-pasha-restaurant"
            element={<AllDayRestaurant setShowProgress={setShowProgress} />}
          />
          <Route
            path="/booking/paymentsuccessful"
            element={<ReservationSuccessful />}
          />
          <Route
            path="/restaurant/bakers-kitchen"
            element={<BakersKitchen setShowProgress={setShowProgress} />}
          />
          <Route
            path="/restaurant/fez-wine-and-bar"
            element={<FezWineAndBar setShowProgress={setShowProgress} />}
          />
           <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
