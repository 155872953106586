import React from "react";
import { Link } from "react-router-dom";
import Image1 from "../../images/artist/art4.jpeg";
import Image2 from "../../images/outdoor/Mehmed2.jpg";
import Image3 from "../../images/meetingrooms/meetingroom12.jpeg";
import Image4 from "../../images/swimmingpool/swimmingpool1.jpeg";
import AcropoleLounge from "../../images/lounge/middle-lounge.jpg"

const HotelFacilities = () => {
  return (
    <div id="facilities-main-page">
      <div
        className="banner-header section-padding valign bg-img bg-fixed"
        data-overlay-dark="4"
        data-background="../../images/slider/1.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 caption mt-90">
              <span>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
              </span>
              <h5>The Emin Pasha Hotel</h5>
              <h1>Facilities</h1>
            </div>
          </div>
        </div>
      </div>
      <section class="services section-padding">
        <div class="container">
          <div class="row">
            <div
              class="col-md-6 p-0 animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div class="img left">
                <Link to="/facilities/giftshops/:giftshopid">
                  <img src={Image1} alt="" />
                </Link>
              </div>
            </div>
            <div
              class="col-md-6 p-0 bg-cream valign animate-box"
              data-animate-effect="fadeInRight"
            >
              <div class="content">
                <div class="cont text-left">
                  <div class="info">
                    <h6>Discover</h6>
                  </div>
                  <h4>CG Shop</h4>
                  <p>
                    For generations we have communicated through art. 
                    Uganda has such an illustrious history in the arts 
                    which you can delve into whilst you visit the Emin 
                    Pasha CG Shop. Come be apart of this artistic 
                    experience, buy authentic art pieces and decor.
                  </p>
                  <div class="butn-dark">
                    {" "}
                    <Link to="/facilities/giftshops/:giftshopid">
                      <span>Read More</span>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-6 bg-cream p-0 order2 valign animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div class="content">
                <div class="cont text-left">
                  <div class="info">
                    <h6>Experiences</h6>
                  </div>
                  <h4>Mehmed Pasha Lounge</h4>
                  <p>
                    This space is spectacularly located, directly 
                    offering a scenic view of the lustrous gardens, 
                    dotted with diverse flora. This is your ultimate 
                    destination for that individual desiring to close 
                    out clutter and be in sync with the mind and 
                    thoughts. This lounge boast of both outdoor and 
                    indoor seating areas.
                  </p>
                  <div class="butn-dark">
                    {" "}
                    <Link to="/facilities/libraries/:libraryid">
                      <span>Read More</span>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 p-0 order1 animate-box"
              data-animate-effect="fadeInRight"
            >
              <div class="img">
                <Link to="/facilities/libraries/:libraryid">
                  <img src={Image2} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-6 p-0 animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div class="img left">
                <Link to="/facilities/businesscenters/:businesscenterid">
                  <img src={Image3} alt="" />
                </Link>
              </div>
            </div>
            <div
              class="col-md-6 p-0 bg-cream valign animate-box"
              data-animate-effect="fadeInRight"
            >
              <div class="content">
                <div class="cont text-left">
                  <div class="info">
                    <h6>Modern</h6>
                  </div>
                  <h4>Business Center</h4>
                  <p>
                    Come host your meetings at our smart business 
                    center – fully equipped to enable virtual sessions, 
                    including waiting room functionality. Some of the 
                    equipment at your disposal include, but not limited 
                    to: Bluetooth speakerphone, conference bars, and 
                    collaboration displays.
                  </p>
                  <div class="butn-dark">
                    {" "}
                    <Link to="/facilities/businesscenters/:businesscenterid">
                      <span>Read More</span>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-6 bg-cream p-0 order2 valign animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div class="content">
                <div class="cont text-left">
                  <div class="info">
                    <h6>Experiences</h6>
                  </div>
                  <h4>Swimming Pool</h4>
                  <p>
                    The Emin Pasha Hotel considers carries the pool as 
                    a center piece, hence regular upgrades are on the 
                    cards. This ultra-modern pool is open to both guests 
                    and the general public for a regular swim or as a 
                    venue for poolside parties, and, or events.
                  </p>
                  <div class="butn-dark">
                    {" "}
                    <Link to="/facilities/businesscenters/swimmingpool">
                      <span>Read More</span>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 p-0 order1 animate-box"
              data-animate-effect="fadeInRight"
            >
              <div class="img">
                <Link to="/facilities/businesscenters/swimmingpool">
                  <img src={Image4} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-6 p-0 animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div class="img left">
                <Link to="/facilities/businesscenters/acropole-lounge">
                  <img src={AcropoleLounge} alt="" />
                </Link>
              </div>
            </div>
            <div
              class="col-md-6 p-0 bg-cream valign animate-box"
              data-animate-effect="fadeInRight"
            >
              <div class="content">
                <div class="cont text-left">
                  <div class="info">
                    <h6>New</h6>
                  </div>
                  <h4>Acropole Lounge</h4>
                  <p>
                    This space has been retrofitted to exude a collage 
                    of vintage rustic and modern contemporary experiences. 
                    Acropole lounge offers an array of seating options to 
                    suit an array of moods. Sink into sumptuous armchairs 
                    and cozy couches, whilst marveling at the sights and 
                    sounds of our astounding fountain – an ambiance perfect 
                    for intimate conversations or savoring a glass of fine 
                    wine. 
                  </p>
                  <div class="butn-dark">
                    {" "}
                    <Link to="/facilities/businesscenters/acropole-lounge">
                      <span>Read More</span>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HotelFacilities;
