import React from 'react';
import AboutHotel from '../components/about/about-hotel.jsx';
import HomeAbout from '../components/home/HomeAbout';
import HomePricing from '../components/home/HomePricing';
import HomeFacilities from '../components/home/HomeFacilities';
import Testimony from '../components/home/Testimony';

const About = () => {
  return (
    <div>
      <AboutHotel />
      <HomeAbout />
      <HomePricing />
      <HomeFacilities />
      {/* <Testimony /> */}
    </div>
  );
};

export default About;
