import React, { useState, useEffect }  from 'react'
import { Link as LinkScroll } from 'react-scroll';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import "../globalstyles/themify-icons.css";
import "../globalstyles/animate.min.css";
import "../globalstyles/datepicker.css";
import "../globalstyles/flaticon.css";
import "../globalstyles/magnific-popup.css";
import "../globalstyles/owl.theme.default.min.css";
import "../globalstyles/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../globalstyles/style.css";
import Carousel from "react-multi-carousel";
import OrderList from '../orders/OrderList';
import { Link } from "react-router-dom";
import {starterArray, mainArray, saladsArray, breakfastArray, dessertArray, wineArray} from '../../shared/constants/Restauarant';

const Restruant = () => {

    const [value, setValue] = useState(0);
    const [currentMenu, setCurrentMenu] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        switch (value) {
            case 0:
                setCurrentMenu(starterArray);
                // eslint-disable-next-line no-unreachable
                break;
            case 1:
                setCurrentMenu(mainArray);
                break;
            case 2:
                setCurrentMenu(saladsArray);
                break;
            case 3:
                setCurrentMenu(breakfastArray);
                break;
            case 4:
                setCurrentMenu(dessertArray);
                break;
            case 5:
                setCurrentMenu(wineArray);
                break;
            default:
                return "Check Now";
        }
    }, [ value]);

    const superiorRooms = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    return (
        <div>
            {/* <!-- Restaurant Slider --> */}
            <header class="header home-header-container slider-fade">
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={superiorRooms}
                    ssr={false} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={4000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    {/* <!-- The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. --> */}
                    <div
                        className="text-center item bg-restuarant1"
                        data-overlay-dark="3"
                        data-background="../../images/restaurant/1.jpg"
                    >
                    </div>
                    <div
                        className="text-center item bg-restuarant2"
                        data-overlay-dark="3"
                        data-background="../../images/restaurant/2.jpg"
                    >
                    </div>
                    <div
                        className="text-center item bg-restuarant3"
                        data-overlay-dark="3"
                        data-background="../../images/restaurant/3.jpg"
                    >
                    </div>
                </Carousel>
                {/* <!-- arrow down --> */}
                <div className="arrow bounce text-center">
                    <LinkScroll to="restuarantsection" spy={true} smooth={true}>
                        <i className="ti-arrow-down"></i>{" "}
                    </LinkScroll>
                </div>
            </header>
            {/* <!-- Restaurant Content --> */}
            <section id="restuarantsection" className="rooms-page section-padding" data-scroll-index="1">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-12">
                            <div className='restuarant-banner'>
                                <div className="restuarant-banner-message">
                                    <p>We offer Take out and Food Delivery <Link data-scroll-nav="1" to='/orders/dashboard'><span> Place Order</span></Link></p>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-12 text-left">
                            <span>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                            </span>
                            <div className="section-subtitle">
                                Experience
                            </div>
                            <div className="section-title">FEZ Wine & Bar</div>
                        </div>
                        <div className="col-md-12">
                            <p className="mb-30">
                                FEZ Wine & Bar is a vibrant bar at the heart of The Emin
                                Pasha Hotel. That combines the charm of Kampala's night life
                                and hospitality with the sophistication and quality of an
                                upscale and luxurious, chef-driven restaurant.
                            </p>
                            <h6 style={{ fontSize: 28, marginBottom: 0 }}>Hours</h6>
                            <ul className="list-unstyled page-list mb-30">
                                <li>
                                    <div className="page-list-icon">
                                        {" "}
                                        <span className="ti-time"></span>{" "}
                                    </div>
                                    <div className="page-list-text">
                                        <p>Breakfast: 7.00 am – 11.00 am (daily)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="page-list-icon">
                                        {" "}
                                        <span className="ti-time"></span>{" "}
                                    </div>
                                    <div className="page-list-text">
                                        <p>Lunch: 12.00 noon – 2.00 pm (daily)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="page-list-icon">
                                        {" "}
                                        <span className="ti-time"></span>{" "}
                                    </div>
                                    <div className="page-list-text">
                                        <p>
                                            Dinner: open from 6.30 pm, last order at 10.00 pm (daily)
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <h6 style={{ fontSize: 28, marginBottom: 0 }}>Dress Code</h6>
                            <p>Smart casual (no shorts, hats, or sandals permitted)</p>
                            <h6 style={{ fontSize: 28, marginBottom: 0 }}>Terrace</h6>
                            <p>Open for drinks only</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Restaurant Menu --> */}
            <section
                id="menu"
                className="restaurant-menu menu section-padding bg-black"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-subtitle">
                                <span>The Emin Pasha Hotel</span>
                            </div>
                            <div className="section-title">
                                <span>FEZ Wine & Bar Menu</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div id="restuarant-menus" className="col-md-12">
                            <div className="row">
                                <Tabs centered style={{ marginBottom: 39}} value={value} allowScrollButtonsMobile scrollButtons={true} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Starters" style={{ fontSize: 15 }} />
                                    <Tab label="Mains" style={{ fontSize: 15 }} />
                                    <Tab label="Salads" style={{ fontSize: 15 }} />
                                    <Tab label="Breakfast" style={{ fontSize: 15 }} />
                                    <Tab label="Dessert" style={{ fontSize: 15 }} />
                                    <Tab label="Wine" style={{ fontSize: 15 }} />
                                </Tabs>
                                {/* <div className='tab-order-content'>
                                    <OrderList orderArray={currentMenu}/>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Restruant;