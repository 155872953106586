import React, { useState, useEffect } from 'react';
import useBreakPoint from './../../shared/breakPoints/useBreakPoint';
import Image1 from "../../images/pricing/visa.jpg";
import VisaLogo from "../../images/pricing/visalogo.png";
import Image2 from "../../images/pricing/mastercard.png";
import Image3 from "../../images/pricing/americanexpress.jpg";
import SecurePayment from "../../images/icons/credit-card.png";
import AtmCard from "../../images/pricing/atm-card.png";
import MTNImage from "../../images/pricing/mtndownload.png";
import AirtelImage from "../../images/pricing/airtelMoney.jpg";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const MakePayment = (props) => {
    const [expiryDate, setExpiryDate] = useState("");
    const [isCardNumberInvalid, setIsCardNumberInvalid] = useState(true);
    const [formattedCardNumber, setFormattedCardNumber] = useState("");
    //const [cardNumber, setCardNumber] = useState("");
    const [andornmentImage, setAndornmentImage] = useState(AtmCard);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { matchesAllMobile } = useBreakPoint();

    const handleChangeExpiryMonth = (event) => {
        props.setExpiryMonth(event.target.value);
        props.setExpiryMonthError(false);
    };

    const handleChangeExpiryYear = (event) => {
        props.setExpiryYear(event.target.value);
        props.setExpiryYearError(false);
    };

    const handleEnterCardNumber = (event) => {
        const cardNo = event.target.value;
        props.setCardNumber(cardNo)
        props.setCardNumberError(false);


    }

    // const onChangeExpiryDate = (e) => {
    //     console.log('::debug event:', e.target.value);
    //     console.log('::debug expiryDate:', expiryDate);
    //     const result = e.target.value;
    //     const isNumber = parseInt(result);
    //     console.log('::debug isNumber:', isNumber);
    //     if (result.length === 2 && expiryDate.length !== 3) {
    //         const appended = result + "/";
    //         console.log("::debug appended:", appended);
    //         setExpiryDate(appended);
    //     } else {
    //         setExpiryDate(result);
    //     }
    // }

    const checkIfCardIsValid = (cardNumber) => {
        const formattedCardNumber = cardNumber.replace(/\D/g, '');
        const regexVisa = /^4[0-9]{12}(?:[0-9]{3})?$/;
        const regexMastercard = /^5[1-5][0-9]{14}$/;
        const regexAmericanExpress = /^3[47][0-9]{13}$/;

        if (regexVisa.test(formattedCardNumber)) {
            return true;
        } else if (regexMastercard.test(formattedCardNumber)) {
            return true;
        } else if (regexAmericanExpress.test(formattedCardNumber)) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        console.log('::debug useEffect cardNumber:', props.cardNumber);
        // Remove all non-digit characters from the card number
        const formattedCardNumber = props.cardNumber.replace(/\D/g, '');
        // Split the card number into groups of four digits
        const cardNumberGroups = formattedCardNumber.match(/.{1,4}/g);
        // Join the card number groups with a space in between
        const formattedValue = cardNumberGroups ? cardNumberGroups.join(' ') : '';
        // Update the state with the formatted card number
        setFormattedCardNumber(formattedValue);
        const regexVisa = /^4[0-9]{12}(?:[0-9]{3})?$/;
        const regexMastercard = /^5[1-5][0-9]{14}$/;
        const regexAmericanExpress = /^3[47][0-9]{13}$/;

        if (regexVisa.test(formattedCardNumber)) {
            setAndornmentImage(VisaLogo);
            setIsCardNumberInvalid(true);
        } else if (regexMastercard.test(formattedCardNumber)) {
            setAndornmentImage(Image2);
            setIsCardNumberInvalid(true);
        } else if (regexAmericanExpress.test(formattedCardNumber)) {
            setAndornmentImage(Image3);
            setIsCardNumberInvalid(true);
        } else {
            setAndornmentImage(AtmCard);
        }
    }, [props.cardNumber]);

    const handleOnBlur = (event) => {
        console.log('::debug onblur:', event);
        const cardNumberValue = event?.target?.value;
        const isCardValid = checkIfCardIsValid(cardNumberValue);
        console.log('::debug isCardValid:', isCardValid);
        setIsCardNumberInvalid(isCardValid);
    }


    return (
        <div id='book-now-page'>
            <section id='order-dashboard' className={matchesAllMobile ? "mt-3" : "mt-5 "}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card-type-row mb-5" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <img src={Image1} alt="" style={{ width: matchesAllMobile ? "20%" : "7%", paddingRight: matchesAllMobile ? 10 : 20, height: matchesAllMobile ? "20%" : "7%" }} className="img-fluid" />
                                <img src={Image2} alt="" style={{ width: matchesAllMobile ? "20%" : "7%", paddingRight: matchesAllMobile ? 10 : 20, height: matchesAllMobile ? "20%" : "7%" }} className="img-fluid" />
                                <img src={Image3} alt="" style={{ width: matchesAllMobile ? "20%" : "7%", height: matchesAllMobile ? "20%" : "7%" }} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="payment-section">
                            <div id="order-submit-form" className="row">
                                <h5 className='' style={{ fontSize: 25, marginBottom: 0 }}>Enter Card Information</h5>
                                <div className='col-md-7 col-sm-12 offset-md-5 offset-sm-0 mt-3'>
                                    <div className='d-flex justify-content-between'>
                                    <img 
                                    src={SecurePayment} 
                                    alt="" 
                                    style={{ 
                                        width: matchesAllMobile ? "20%" : "10%", 
                                        paddingRight: matchesAllMobile ? 10 : 20, 
                                        height: matchesAllMobile ? "20%" : "7%" 
                                    }} 
                                    className="img-fluid" 
                                    />
                                        <p style={{ fontSize: 14, fontWeight: '600', fontStyle: 'italic'}}>
                                            Seamless and Secure Electronic Payments, your transactions are 
                                            guarded by state-of-the-art security measures ensuring a worry-free 
                                            and protected payment experience!
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 form-group">
                                    <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                        <TextField
                                            id="standard-basic-cardname"
                                            name="cardfirstname"
                                            error={props.cardFirstNameError}
                                            required
                                            value={props.cardFirstName}
                                            onChange={(e) => { props.setCardFirstName(e.target.value); props.setCardFirstNameError(false); }}
                                            label="First Name on Card"
                                            variant="standard" />
                                    </FormControl>
                                </div>
                                <div className="col-md-6 form-group">
                                    <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                        <TextField
                                            id="standard-basic-cardname"
                                            name="cardlastname"
                                            error={props.cardLastNameError}
                                            required
                                            value={props.cardLastName}
                                            onChange={(e) => { props.setCardLastName(e.target.value); props.setCardLastNameError(false) }}
                                            label="Last Name on Card"
                                            variant="standard" />
                                    </FormControl>
                                </div>
                                <div className="col-md-6 form-group">
                                    <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                        <TextField
                                            id="standard-basic-cardnumber"
                                            name="cardnumber"
                                            required
                                            value={formattedCardNumber}
                                            onChange={(e) => handleEnterCardNumber(e)}
                                            label="Card Number"
                                            variant="standard"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <img src={andornmentImage} alt="" style={{ maxWidth: matchesAllMobile ? "20%" : "80%", maxHeight: matchesAllMobile ? "20%" : "inherit" }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onBlur={handleOnBlur}
                                            error={!isCardNumberInvalid || props.cardNumberError}
                                            helperText={!isCardNumberInvalid ? 'Invalid card number' : ''}

                                        />
                                    </FormControl>
                                </div>
                                <div className="col-md-6 form-group">
                                    <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel required id="demo-simple-select-standard-label">Select Card Expiry Month</InputLabel>
                                        <Select
                                            labelId="select-card-expiry-month"
                                            id="demo-simple-select-expiry-month"
                                            value={props.expiryMonth}
                                            error={props.expiryMonthError}
                                            onChange={handleChangeExpiryMonth}
                                            label="Select Month"
                                            required
                                        >
                                            <MenuItem value="">
                                                <em>Select Month</em>
                                            </MenuItem>
                                            <MenuItem value={'01'}>1</MenuItem>
                                            <MenuItem value={'02'}>2</MenuItem>
                                            <MenuItem value={'03'}>3</MenuItem>
                                            <MenuItem value={'04'}>4</MenuItem>
                                            <MenuItem value={'05'}>5</MenuItem>
                                            <MenuItem value={'06'}>6</MenuItem>
                                            <MenuItem value={'07'}>7</MenuItem>
                                            <MenuItem value={'08'}>8</MenuItem>
                                            <MenuItem value={'09'}>9</MenuItem>
                                            <MenuItem value={'10'}>10</MenuItem>
                                            <MenuItem value={'11'}>11</MenuItem>
                                            <MenuItem value={'12'}>12</MenuItem>
                                        </Select>
                                        {/* <TextField
                                                        id="standard-basic-expirydate"
                                                        name="expirydate"
                                                        required
                                                        label="Expiry Date"
                                                        variant="standard"
                                                        maxLength={5}
                                                        value={expiryDate}
                                                        onChange={onChangeExpiryDate}
                                                    /> */}
                                    </FormControl>
                                </div>
                                <div className="col-md-6 form-group">
                                    <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel required id="demo-simple-select-standard-label">Select Card Expiry Year</InputLabel>
                                        <Select
                                            labelId="select-card-expiry-year"
                                            id="demo-simple-select-expiry-year"
                                            value={props.expiryYear}
                                            onChange={handleChangeExpiryYear}
                                            required
                                            error={props.expiryYearError}
                                            label="Select Year"
                                        >
                                            <MenuItem value="">
                                                <em>Select Year</em>
                                            </MenuItem>
                                            <MenuItem value={'23'}>2023</MenuItem>
                                            <MenuItem value={'24'}>2024</MenuItem>
                                            <MenuItem value={'25'}>2025</MenuItem>
                                            <MenuItem value={'26'}>2026</MenuItem>
                                            <MenuItem value={'27'}>2027</MenuItem>
                                            <MenuItem value={'28'}>2028</MenuItem>
                                            <MenuItem value={'29'}>2029</MenuItem>
                                            <MenuItem value={'30'}>2030</MenuItem>
                                            <MenuItem value={'31'}>2031</MenuItem>
                                            <MenuItem value={'32'}>2032</MenuItem>
                                            <MenuItem value={'33'}>2033</MenuItem>
                                            <MenuItem value={'34'}>2034</MenuItem>
                                        </Select>
                                        {/* <TextField
                                                        id="standard-basic-expirydate"
                                                        name="expirydate"
                                                        required
                                                        label="Expiry Date"
                                                        variant="standard"
                                                        maxLength={5}
                                                        value={expiryDate}
                                                        onChange={onChangeExpiryDate}
                                                    /> */}
                                    </FormControl>
                                </div>
                                <div className="col-md-6 form-group">
                                    <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                        <TextField
                                            id="standard-basic-cvv"
                                            name="cvv"
                                            required
                                            error={props.cvvError}
                                            value={props.cvv}
                                            onChange={(e) => { props.setCvv(e.target.value); props.setCvvError(false) }}
                                            label="CVV"
                                            variant="standard" />
                                    </FormControl>
                                </div>
                            </div>
                            {/* <Tabs value={value} allowScrollButtonsMobile scrollButtons={true} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Card" onClick={() => props.setPaymentMethod("card")} sx={{ fontSize: 20}} />
                                <Tab label="Mobile Money" onClick={() => props.setPaymentMethod("momo")} sx={{ fontSize: 20}}/>
                            </Tabs> */}

                            {/* {
                                value === 0 && (
                                    <div className={matchesAllMobile ? 'payment-section mt-3' : 'payment-section mt-5'}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card-type-row mb-5" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <img src={Image1} alt="" style={{ width: matchesAllMobile ? "20%" : "7%", paddingRight: matchesAllMobile ? 10 : 20, height: matchesAllMobile ? "20%" : "7%" }} className="img-fluid" />
                                                    <img src={Image2} alt="" style={{ width: matchesAllMobile ? "20%" : "7%", paddingRight: matchesAllMobile ? 10 : 20, height: matchesAllMobile ? "20%" : "7%" }} className="img-fluid" />
                                                    <img src={Image3} alt="" style={{ width: matchesAllMobile ? "20%" : "7%", height: matchesAllMobile ? "20%" : "7%" }} className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                )
                            }

                            {
                                value === 1 && (
                                    <div className='mobile-money-section mt-3'>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="momo">
                                                    <div className="card-type-row mb-3" style={{ display: 'flex', justifyContent: matchesAllMobile ? 'center' : 'flex-start' }}>
                                                        <img src={MTNImage} alt="" style={{ width: matchesAllMobile ? "187px" : "12%", paddingRight: matchesAllMobile ? 10 : 20, height: matchesAllMobile ? "115px" : "83px" }} className="img-fluid" />
                                                        <img src={AirtelImage} alt="" style={{ width: matchesAllMobile ? "187px" : "12%", height: matchesAllMobile ? "115px" : "83px" }} className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-5 form-group">
                                                <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                                                    <TextField
                                                        id="standard-basic-phonenumber"
                                                        type="number"
                                                        name="phonenumber"
                                                        required
                                                        error={props.mobileMoneyNumberError}
                                                        value={props.mobileMoneyNumber}
                                                        onChange={(e) => {props.setMobileMoneyNumber(e.target.value); props.setMobileMoneyNumberError(false)}}
                                                        label="Enter Phone Number e.g 256772345543"
                                                        variant="standard" />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } */}
                            {/* 
                            {
                                value === 2 && (
                                    <div className="pay-on-arrival mt-5">
                                        <h3>Disclaimer</h3>
                                        <p>The reservation will be cancelled if we don't here from you 24hours before the set arrival date.</p>
                                    </div>
                                )
                            } */}

                        </div>
                        <div className='col-md-12'>
                            <FormGroup>
                                <FormControlLabel
                                    required
                                    control={
                                        <Checkbox
                                            required
                                            checked={props.hasAcceptedTos}
                                            onChange={(e) => { props.setHasAcceptedTos(e.target.checked); props.setHasAcceptedTosError(false) }} />}
                                    label={
                                        <p style={{ marginBottom: 0 }}>Accept our <Link to={`/terms-of-service`} style={{ color: '#c39e46' }} >
                                            terms and conditions
                                        </Link></p>
                                    } />
                            </FormGroup>
                            {props.hasAcceptedTosError && <p style={{ color: 'red', marginBottom: 10, fontSize: 18, fontWeight: 600 }}>
                                <ReportProblemIcon fontSize="small" color='danger' /> Accept our terms of service
                            </p>}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default MakePayment;
