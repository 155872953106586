import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link as LinkScroll } from 'react-scroll';
import Image4 from "../../images/rooms/room4.jpeg";
import { CurrencyFormat } from "../../shared/Formatter";
import pricing1 from "../../images/restaurant/whisky-bar.jpeg";
import pricing2 from "../../images/rooms/plaza-dry-cleaning.jpg";
import pricing3 from "../../images/rooms/airport-transfer-prices.jpeg";
import pricing4 from "../../images/pricing/4.jpg";
import { useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ImageNotFound from '../../images/restaurant/image-not-found.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faAddressBook } from '@fortawesome/free-regular-svg-icons';
import { faPhone, faNewspaper, faCoffee, faSitemap, faThermometer0, faSnowflake, faBuilding, faCube, faFilm, faServer, faRandom, faTelevision } from '@fortawesome/free-solid-svg-icons';
import NoImageLogo from '../../images/img-not-found.png';
import { ImageGroup, Image } from 'react-fullscreen-image'
import ImageBac from '../../images/rooms/bg-home-room.jpg';
import { Lightbox } from "react-modal-image";
import { GetAllRooms, FetchSpecificRoom } from '../../shared/utils/api/api';

const Guestroomdetail = (props) => {
  const [SelectedRoom, setSelectedRoom] = useState({});
  const [selectedRoomName, setSelectedRoomName] = useState('');
  const [roomsArray, setRoomsArray] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [imageSrc, setImageSrc] = useState();
  const [imageCaptionText, setImageCaptionText] = useState(null);
  const [showImageViewModal, setShowImageViewModal] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const roomId = queryParams.get('id');
  // setSelectedRoom(location.state.roomObj);
  console.log('::debug SelectedRoom:', SelectedRoom);


  const handleCloseModal = () => {
    setShowImageViewModal(false);
  };

  useEffect(() => {
    const fetchRooms = async () => {
      props.setShowProgress(true);
      setIsloading(true);
      try {
        await GetAllRooms().get('', { params: { limit: 4 } }).then((response) => {
          console.log('::debug home Response:', response.data.data);
          setRoomsArray(response.data.data);
        });

        await FetchSpecificRoom(roomId).then((res) => {
          console.log('::debug useeffect FetchSpecificRoom:', res);
          setSelectedRoom(res.data);
          setSelectedRoomName(res.data.room_name);
        })
      } catch (error) {
        props.setShowProgress(false);
        setOpenSnackBar(true);
        setErrorMessage(error.response.data.errors.query._schema[0]);
        console.log(error);
      } finally {
        props.setShowProgress(false);
        setIsloading(false)
      }
    };
    fetchRooms();
  }, []);

  const handleClose = () => {
    setOpenSnackBar(false);
  };

  const handleDisplayImageModal = (imgSrc, text) => {
    setImageCaptionText(text);
    setImageSrc(imgSrc);
    setShowImageViewModal(true);
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const getSliderImages = (roomName) => {
    console.log('::debug rooms silder:', roomName);
    if (roomName === 'Deluxe Room') {
      console.log('::debug is deluxe room:');
      return (
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          autoplayResetOnHover={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          shouldResetAutoplay={false}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div
            className="text-center item bg-deluxeroom"
            data-overlay-dark="1"
            data-background="../../images/rooms/room1.jpeg"
          >
          </div>
        </Carousel>
      )

    } else if (roomName === 'Deluxe Suite') {
      console.log('::debug is deluxe suite:');
      return (
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div
            className="text-center item bg-roomdeluxesuite1"
            data-overlay-dark="1"
            data-background="../../images/rooms/room1.jpeg"
          >
          </div>
          <div
            className="text-center item bg-roomdeluxesuite2"
            data-overlay-dark="1"
            data-background="../../images/rooms/room4.jpeg"
          >
          </div>
          <div
            className="text-center item bg-roomdeluxesuite3"
            data-overlay-dark="1"
            data-background="../../images/rooms/room5.jpeg"
          >
          </div>
        </Carousel>
      )
    } else if (roomName === 'Executive Suite') {
      console.log('::debug is executive suite:');
      return (
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div
            className="text-center item bg-executivesuite1"
            data-overlay-dark="0"
            data-background="../../images/rooms/room1.jpeg"
          >
          </div>
          <div
            className="text-center item bg-executivesuite2"
            data-overlay-dark="1"
            data-background="../../images/rooms/room4.jpeg"
          >
          </div>
          <div
            className="text-center item bg-executivesuite3"
            data-overlay-dark="1"
            data-background="../../images/rooms/room5.jpeg"
          >
          </div>
        </Carousel>
      )
    } else if (roomName === 'Superior Suite') {
      console.log('::debug is superior suite:');
      return (
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div
            className="text-center item bg-superiorsuite1"
            data-overlay-dark="1"
            data-background="../../images/rooms/room1.jpeg"
          >
          </div>
          <div
            className="text-center item bg-superiorsuite2"
            data-overlay-dark="1"
            data-background="../../images/rooms/room4.jpeg"
          >
          </div>
          <div
            className="text-center item bg-superiorsuite3"
            data-overlay-dark="1"
            data-background="../../images/rooms/room5.jpeg"
          >
          </div>
        </Carousel>
      )
    } else {
      console.log('::debug is anonymous room:');
      return (
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div
            className="text-center item bg-roombg1"
            data-overlay-dark="1"
            data-background="../../images/rooms/room1.jpeg"
          >
          </div>
          <div
            className="text-center item bg-roombg2"
            data-overlay-dark="1"
            data-background="../../images/rooms/room4.jpeg"
          >
          </div>
          <div
            className="text-center item bg-roombg3"
            data-overlay-dark="1"
            data-background="../../images/rooms/room5.jpeg"
          >
          </div>
        </Carousel>
      );
    }
  }

  

  const superiorRooms = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <div>
      {/* <!-- Room Page Slider --> */}
      <header class="header home-header-container slider-fade">
        {/* <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        > */}
          {/* <!-- The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. --> */}
          {/* {
            SelectedRoom.roomImage && SelectedRoom.roomImage.length > 0 ?
              <>
                {SelectedRoom.roomImage.map((room) => {
                  return <div
                  key={room.id}
                    className="text-center item"
                    data-overlay-dark="1"
                    style={{
                      backgroundImage: `url(${room.image_name})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                })}
              </>
              : <>
                <div
                  className="text-center item bg-roombg1"
                  data-overlay-dark="1"
                  data-background="../../images/rooms/room1.jpeg"
                >
                </div>
              </>
          } */}
          {/* <div
            className="text-center item bg-roombg1"
            data-overlay-dark="1"
            data-background="../../images/rooms/room1.jpeg"
          >
          </div>
          <div
            className="text-center item bg-roombg2"
            data-overlay-dark="1"
            data-background="../../images/rooms/room4.jpeg"
          >
          </div>
          <div
            className="text-center item bg-roombg3"
            data-overlay-dark="1"
            data-background="../../images/rooms/room5.jpeg"
          >
          </div> */}

        {/* </Carousel> */}
        {
          !isloading && getSliderImages(selectedRoomName)
        }
        {/* <!-- arrow down --> */}
        <div className="arrow bounce text-center">
          <LinkScroll to="roomdetailssection" spy={true} smooth={true}>
            <i className="ti-arrow-down"></i>{" "}
          </LinkScroll>
        </div>
      </header>
      {/* <!-- Room Content --> */}
      <section id="roomdetailssection" className="rooms-page section-padding" data-scroll-index="1">
        <div className="container">
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openSnackBar}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert variant="filled" severity="error">{errorMessage}</Alert>
          </Snackbar>
          {/* <!-- project content --> */}
          <div className="row">
            <div className="col-md-12">
              <span>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
              </span>
              <div className="section-subtitle">The Emin Pasha Hotel</div>
              <div className="section-title">{SelectedRoom.room_name}<br />
                <p className="section-subtitle"><b>{`${CurrencyFormat(SelectedRoom.room_base_price ?? 0)}` + ' '}</b><small>USD/Night</small></p>
              </div>
            </div>
            <div className="col-md-8">
              <p className="mb-30">
                {SelectedRoom.room_description}
              </p>

              <div className="row">
                <div className="col-md-6">
                  <h6>Check-in</h6>
                  <ul className="list-unstyled page-list mb-30">
                    <li>
                      <div className="page-list-icon">
                        {" "}
                        <span className="ti-check"></span>{" "}
                      </div>
                      <div className="page-list-text">
                        <p>Check-in from 9:00 AM - anytime</p>
                      </div>
                    </li>
                    <li>
                      <div className="page-list-icon">
                        {" "}
                        <span className="ti-check"></span>{" "}
                      </div>
                      <div className="page-list-text">
                        <p>Early check-in subject to availability</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <h6>Check-out</h6>
                  <ul className="list-unstyled page-list mb-30">
                    <li>
                      <div className="page-list-icon">
                        {" "}
                        <span className="ti-check"></span>{" "}
                      </div>
                      <div className="page-list-text">
                        <p>Check-out before noon</p>
                      </div>
                    </li>
                    <li>
                      <div className="page-list-icon">
                        {" "}
                        <span className="ti-check"></span>{" "}
                      </div>
                      <div className="page-list-text">
                        <p>Express check-out</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-md-12">
                  <h6>Special check-in instructions</h6>
                  <p>
                    Guests will receive an email 24hrs before arrival with
                    check-in instructions; front desk staff will greet guests on
                    arrival. For more details, please contact the property using
                    the information on the booking confirmation.
                  </p>
                </div>
                <div className="col-md-12">
                  <h6>Pets</h6>
                  <p>Pets not allowed</p>
                </div>
                <div className="col-md-12">
                  <h6>Children and extra beds</h6>
                  <p>
                    Children stay free whilst using existing bedding.
                    Children may not be eligible for complimentary
                    breakfast. Rollaway/extra beds are available for $
                    10 per day.
                  </p>
                </div>
                <div className="col-md-12">
                  <div className="butn-dark mt-15 mb-30">
                    {" "}
                    <Link to="/new-booking"
                      state={{ roomId: SelectedRoom.id, roomName: SelectedRoom.room_name }}>
                      <span>Book Now</span>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {/* <div
                className="mb-9"
              >
                  <ImageGroup>
                    <ul className="images">
                      {
                        SelectedRoom?.roomImage && SelectedRoom?.roomImage.length > 0 ? (
                          <>
                            {SelectedRoom.roomImage.map((room) => (
                                <li key={room.id}>
                                  <Image
                                    src={room.image_name}
                                    alt="room image"
                                    style={{
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      height: '100%',
                                      width: '100%',
                                      objectFit: 'cover',
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </>
                        ) : (
                          <img src={NoImageLogo} alt="" className="img-fluid" />
                        )
                      }
                    </ul>
                  </ImageGroup>
              </div> */}
              <h6>Amenities</h6>
              <ul className="list-unstyled page-list amenities-container mb-30">
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="flaticon-group" style={{ fontWeight: 'bold' }}></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>2-3 Persons</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="flaticon-wifi" style={{ fontWeight: 'bold' }}></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Free Wifi</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="flaticon-clock-1" style={{ fontWeight: 'bold' }}></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Ensuite bathroom</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="flaticon-breakfast" style={{ fontWeight: 'bold' }}></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Balcony Access</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="flaticon-towel" style={{ fontWeight: 'bold' }}></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Air conditioning</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <FontAwesomeIcon icon={faTelevision} color="#aa8453" />{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Flat-screen TV</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="flaticon-towel" style={{ fontWeight: 'bold' }}></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Free toiletries and Bathrobe</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="ti-wallet" style={{ fontWeight: 'bold' }}></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Safety deposit box</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="flaticon-towel" style={{ fontWeight: 'bold' }}></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Toilet Bath or shower Towels</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="flaticon-swimming" style={{ fontWeight: 'bold' }}></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Socket near the bed</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="ti-layout-grid3" style={{ fontWeight: 'bold' }}></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Multi-Plug Electrical</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="ti-layout-media-overlay" style={{ fontWeight: 'bold' }}></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Granite Tile/marble floor</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="ti-layout-cta-right" style={{ fontWeight: 'bold' }}></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Desk & Seating Area</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <FontAwesomeIcon icon={faRandom} color="#aa8453" />{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Slippers</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <i class="fa fa-phone" aria-hidden="true"></i>
                    <FontAwesomeIcon icon={faPhone} color="#aa8453" />{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Telephone</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <FontAwesomeIcon icon={faNewspaper} color="#aa8453" />{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Ironing facilities</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <FontAwesomeIcon icon={faCoffee} color="#aa8453" />{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Coffee Maker</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <FontAwesomeIcon icon={faSitemap} color="#aa8453" />{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Iron Box</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <FontAwesomeIcon icon={faThermometer0} color="#aa8453" />{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Hairdryers</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <span className="flaticon-towel"></span>{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Towels / sheets (extra fee)</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <FontAwesomeIcon icon={faSnowflake} color="#aa8453" />{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Electric kettle</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <FontAwesomeIcon icon={faBuilding} color="#aa8453" />{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Cable TV channels</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <FontAwesomeIcon icon={faSitemap} color="#aa8453" />{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Wake-up service</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <FontAwesomeIcon icon={faFilm} color="#aa8453" />{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Wardrobe or closet</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <FontAwesomeIcon icon={faServer} color="#aa8453" />{" "}
                  </div>
                  <div className="page-list-text">
                    <p>Toilet paper</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* ROOM IMAGES */}
      {/* <section className="rooms1 mb-5 p-5">
        <div className="container lightbox-section">
        <div className="row">
            <div className="col-md-12">
              <div className="section-subtitle">
                <span>Symphony of Comfort</span>
              </div>
              <div className="section-title" style={{ color: '#000000' }}>
                <strong>Room Gallery</strong>
              </div>
            </div>
          </div>
        {showImageViewModal &&
              <div className="col-md-12">
                <Lightbox
                  small={imageSrc}
                  medium={imageSrc}
                  hideZoom={false}
                  alt={imageCaptionText}
                  onClose={() => handleCloseModal()}
                />
              </div>
            }
            <div class="row">
              <div class="col-md-12">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={superiorRooms}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={6000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                <div class="item">
                  <div class="show-pointer-cursor" onClick={() => handleDisplayImageModal(Image4, 'Room Image')}>
                    <div class="position-re o-hidden">
                      {" "}
                      <img src={Image4} alt="" />

                    </div>
                  </div>
                </div>
                </Carousel>
              </div>
            </div>
        </div>
        
      </section> */}
       {/* END -- ROOM IMAGES */}
      {/* <!-- Similiar Room --> */}
      <section id="similar-room" className="rooms1 section-padding bg-black">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-subtitle">
                <span>Luxury Hotel</span>
              </div>
              <div className="section-title">
                <span>Similar Rooms</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="header-sec home-header-container slider-fade">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  responsive={superiorRooms}
                  ssr={false} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={4000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                  {
                    roomsArray.length > 0 &&

                    roomsArray.map((room) => {
                      return (
                        < div
                          key={room.id}
                          className="item similarroom"
                          style={{ backgroundImage: `url(${room.roomImage && room.roomImage.length > 0 ? room.roomImage[0].image_name : ImageNotFound})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
                        >
                          <div className="position-re o-hidden">
                            {" "}
                            {" "}
                          </div>{" "}
                          <span className="category">
                            <Link to="/new-booking" state={{ roomId: SelectedRoom.id, roomName: SelectedRoom.room_name }}>
                              Book
                            </Link>
                          </span>
                          <div className="con">
                            <h6>
                              <Link to={`/rooms/guestrooms/:guestroomid/?id=${room.id}`}>
                                {`${CurrencyFormat(room.room_base_price)} USD / Night`}
                              </Link>
                            </h6>
                            <h5>
                              <Link to={`/rooms/guestrooms/:guestroomid/?id=${room.id}`}>
                                {room.room_name}
                              </Link>{" "}
                            </h5>
                            <div className="line"></div>
                            <div className="row facilities">
                              <div className="col col-md-7">
                                <ul>
                                  <li>
                                    <i className="flaticon-bed"></i>
                                  </li>
                                  <li>
                                    <i className="flaticon-bath"></i>
                                  </li>
                                  <li>
                                    <i className="flaticon-breakfast"></i>
                                  </li>
                                  <li>
                                    <i className="flaticon-towel"></i>
                                  </li>
                                </ul>
                              </div>
                              <div className="col col-md-5 text-right">
                                <div className="permalink">
                                  <Link to={`/rooms/guestrooms/:guestroomid/?id=${room.id}`}>
                                    Details <i className="ti-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })

                  }
                </Carousel>
              </div>

            </div>
          </div>
        </div >
      </section >
      {/* <!-- Pricing --> */}
      < section id="pricing-section" className="pricing section-padding" >
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              {/* <div className="section-subtitle">
                <span>Best Prices</span>
              </div> */}
              <div className="section-title">Extra Services</div>
              <p>
                The Emin Pasha offers a varied array of room related
                extra services at the most affordable prices. The
                latter include: Safe & Secure – Drinks – Laundry &
                Dry Cleaning
              </p>
              {/* <p>
                Enjoy the best views in kampala as you relax and receive the
                best pampering, tailored for your needs.
              </p> */}
              <div className="reservations mb-30">
                <div className="icon">
                  <span className="flaticon-call"></span>
                </div>
                <div className="text">
                  <p>For information</p>{" "}
                  <a href="tel:+256 312 264 712">+256 312 264 712 /4</a>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="header-sec price-header-container slider-fade">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  responsive={superiorRooms}
                  ssr={false} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={4000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                  <div className="pricing-card">
                    <img src={pricing1} alt="" style={{ maxHeight: 230, minHeight: 230 }} />
                    <div className="desc" >
                      <div className="name"
                        style={{
                          fontWeight: 'bold',
                          color: '#222121',
                          letterSpacing: 2,
                          textAlign: 'center'
                        }}>Mini Bar</div>
                      {/* <div className="amount">
                        $50<span>/ month</span>
                      </div>
                      <ul className="list-unstyled list">
                        <li>
                          <i className="ti-check"></i> Room service cleaning
                        </li>
                        <li>
                          <i className="ti-check"></i> Laundry and Dry cleaning
                        </li>
                        <li>
                          <i className="ti-close unavailable"></i>No Breakfast
                        </li>
                      </ul> */}
                    </div>
                  </div>
                  <div className="pricing-card">
                    <img src={pricing2} alt="" style={{ maxHeight: 230, minHeight: 230 }} />
                    <div className="desc">
                      <div className="name" style={{
                        fontWeight: 'bold',
                        color: '#222121',
                        letterSpacing: 2,
                        textAlign: 'center'
                      }}>Laundry and Cleaning</div>
                      {/* <div className="amount">
                        $30<span>/ daily</span>
                      </div>
                      <ul className="list-unstyled list">
                        <li>
                          <i className="ti-check"></i> Whiskey and Wine
                        </li>
                        <li>
                          <i className="ti-check"></i> Room service cleaning
                        </li>
                        <li>
                          <i className="ti-close unavailable"></i>No Laundry and Dry
                          cleaning
                        </li>
                      </ul> */}
                    </div>
                  </div>
                  <div className="pricing-card">
                    <img src={pricing3} alt="" style={{ maxHeight: 230, minHeight: 230 }} />
                    <div
                      className="desc"
                    >
                      <div
                        className="name"
                        style={{
                          fontWeight: 'bold',
                          color: '#222121',
                          letterSpacing: 2,
                          textAlign: 'center'
                        }}
                      >Airport Transfer Service</div>
                      <div className="d-flex justify-content-center" >
                        <Link
                          to="/services/airport-transfer"
                          style={{
                            border: 'solid 2px #aa8453',
                            padding: '10px 30px',
                            color: '#aa8453',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: 18,
                            letterSpacing: 2
                          }}
                        >Read more</Link>
                      </div>
                      {/* <div className="amount">
                        $30<span>/ daily</span>
                      </div>
                      <ul className="list-unstyled list">
                        <li>
                          <i className="ti-check"></i> Breakfast
                        </li>
                        <li>
                          <i className="ti-check"></i> Room service cleaning
                        </li>
                        <li>
                          <i className="ti-close unavailable"></i>No Laundry and Dry
                          cleaning
                        </li>
                      </ul> */}
                    </div>
                  </div>
                  {/* <div className="pricing-card">
                    <img src={pricing4} alt="" />
                    <div className="desc">
                      <div className="name">Safe & Secure</div>
                      <div className="amount">
                        $15<span>/ daily</span>
                      </div>
                      <ul className="list-unstyled list">
                        <li>
                          <i className="ti-check"></i> Property Safe Keeping
                        </li>
                        <li>
                          <i className="ti-check"></i> Room service cleaning
                        </li>
                        <li>
                          <i className="ti-close unavailable"></i>No Laundry and Dry
                          cleaning
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </section >
    </div >
  );


};

export default Guestroomdetail; 
