import React from 'react';
import FitnessAndSpa from '../components/fitness-and-spa/fitness-and-spa.jsx';
import Testimony from "../components/home/Testimony";
const Fitnessandspa = () => {
  return (
    <div>
      <FitnessAndSpa />
      {/* <Testimony/> */}
    </div>
  ); 
}

export default Fitnessandspa;