import React from 'react';
import Image1 from '../../images/about/cocktail-2.JPG';
import Image2 from '../../images/outdoor/outside.jpg';

function HomeAbout() {
  return (
    <div>
      {/* About  */}
      <section className="about section-padding">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 animate-box"
              data-animate-effect="fadeInUp"
              style={{
                position: 'relative',
                zIndex: -2147483648,
              }}
            >
              <span>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
              </span>
              <div className="section-subtitle">The Emin Pasha Hotel</div>
              <div className="section-title">Enjoy a Luxury Experience</div>
              <p>
                Welcome to The Emin Pasha Hotel and Spa, where a collage of history,
                culture, and nature embrace you in a pouch of serenity! Just like Emin
                Pasha, the distinguished physician and anthropologist,
                The Emin Pasha Hotel and Spa embodies a bold spirit of unity, humanity
                and an appreciation of nature’s marvels.
              </p>
              {/* call  */}
              <div className="reservations">
                <div className="icon">
                  <span className="flaticon-call"></span>
                </div>
                <div className="text">
                  <p>Reservation</p>{" "}
                  <a href="tel:+256 312 264 712">+256 312 264 712 /4</a>
                </div>
              </div>
            </div>
            <div
              className="col col-md-3 animate-box"
              data-animate-effect="fadeInUp"
            >
              <img src={Image1} alt="" className="mt-90 mb-30" />
            </div>
            <div
              className="col col-md-3 animate-box"
              data-animate-effect="fadeInUp"
            >
              <img src={Image2} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeAbout;
