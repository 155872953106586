import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../globalstyles/themify-icons.css";
import "../globalstyles/animate.min.css";
import "../globalstyles/datepicker.css";
import "../globalstyles/flaticon.css";
import "../globalstyles/magnific-popup.css";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import "../globalstyles/owl.theme.default.min.css";
import "../globalstyles/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../globalstyles/select2.css";
import "../globalstyles/style.css";
import Image1 from "../../images/artist/art7.jpeg";
import Image2 from "../../images/artist/art6.jpeg";
import Image3 from "../../images/artist/art4.jpeg";
const GiftShop = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <div>
      {/* Giftshop Slider */}
      <header className="header home-header-container slider-fade">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {/* The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. */}
          <div
            className="text-center item bg-giftshop-img"
            data-overlay-dark="3"
            data-background="../../images/spa/3.jpg"
          >
            
          </div>
          <div
            className="text-center item bg-giftshop-img1"
            data-overlay-dark="3"
            data-background="../../images/swimmingpool/swimmingpool1.jpeg"
          >
            {/* <img src={Image2} alt="" /> */}
          </div>
          <div
            className="text-center item bg-giftshop-img2"
            data-overlay-dark="3"
            data-background="../../images/spa/2.jpg"
          >
            {/* <img src={Image3} alt="" /> */}
          </div>
        </Carousel>
        {/* arrow down */}
        <div className="arrow bounce text-center">
          <a href="#preface-section" data-scroll-nav="1" className="">
            {" "}
            <i className="ti-arrow-down"></i>{" "}
          </a>
        </div>
      </header>
      {/* Giftshop Content */}
      <section id='preface-section' className="rooms-page section-padding" data-scroll-index="1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {" "}
              <span>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
              </span>
              <div className="section-subtitle">Art and Culture</div>
              <div className="section-title">Mehmet CG Shop</div>
              <p className="mb-30">
                Art, in its many forms, exists in every community,
                culture, and country . Art has been created since time
                began, evidenced in cave paintings and rock art. At Mehmet Gift
                Shop we believe that art speaks a language that cuts across all cultures and in so doing unites us all. Come visit our CG Shop to be apart of this artistic journey. {" "}
              </p>
            </div>
            <div className="col-md-12">
              <div className="reservations">
                <div className="icon">
                  <span className="flaticon-call"></span>
                </div>
                <div className="text">
                  <p>Call us on</p>{" "}
                  <a href="tel:+256 312 264 712">+256 312 264 712 /4</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default GiftShop;