import React from "react";
import "../globalstyles/themify-icons.css";
import "../globalstyles/animate.min.css";
import "../globalstyles/datepicker.css";
import "../globalstyles/flaticon.css";
import "../globalstyles/magnific-popup.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../globalstyles/owl.theme.default.min.css";
import "../globalstyles/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../globalstyles/select2.css";
import "../globalstyles/style.css";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

const ContactUs = () => {
  return (
    <div id="contactus-page">
      {/* Header Banner */}
      <div
        className="banner-header section-padding valign bg-img bg-fixed"
        data-overlay-dark="3"
        data-background="img/slider/5.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-left caption mt-90">
              <h5>Get in touch</h5>
              <h1>Contact Us</h1>
            </div>
          </div>
        </div>
      </div>
      {/* Contact */}
      <section className="contact section-padding">
        <div className="container">
          <div className="row mb-90">
            <div className="col-md-6 mb-60">
              <h3>The Emin Pasha Hotel</h3>
              <p>
                Welcome to the best four-star deluxe hotel in Kampala. We are
                delighted with the opportunity to have you as our guest, expect
                the most luxurious and comfortable experience during your stay
                with us.
              </p>
              <div className="reservations mb-30">
                <div className="icon">
                  <span className="flaticon-call"></span>
                </div>
                <div className="text">
                  <p>Reservation</p>{" "}
                  <a href="tel:+256 312 264 712">+256 312 264 712 /4</a>
                </div>
              </div>
              <div className="reservations mb-30">
                <div className="icon">
                  <span className="flaticon-call"></span>
                </div>
                <div className="text">
                  <p>Restaurant & Others</p>{" "}
                  <a href="tel:+256 312 264 713">+256 312 264 713</a>
                </div>
              </div>
              <div className="reservations mb-30">
                <div className="icon">
                  <span className="flaticon-envelope"></span>
                </div>
                <div className="text">
                  <p>Email Info</p>{" "}
                  <a href="mailto:info@eminpasha.com">info@eminpasha.com</a>
                </div>
              </div>
              <div className="reservations">
                <div className="icon">
                  <span className="flaticon-location-pin"></span>
                </div>
                <div className="text">
                  <p>Address</p>{" "}
                  <p>
                    Plot 27 Akii Bua Road, Kampala
                    <br />
                    Uganda
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5 mb-30 offset-md-1">
              <h3>Get in touch</h3>
              <form
                method="post"
                className="contact__form"
                action="https://duruthemes.com/demo/html/cappa/demo1/mail.php"
              >
                {/* <!-- form message --> */}
                <div className="row">
                  <div className="col-12">
                    <div
                      className="alert alert-success contact__msg"
                      style={{ display: "none" }}
                      role="alert"
                    >
                      {" "}
                      Your message was sent successfully.{" "}
                    </div>
                  </div>
                </div>
                {/* <!-- form elements --> */}
                <div className="row">
                  <div className="col-md-6 form-group">
                    <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                      <TextField id="standard-basic-name" name="name" required label="Fullname" variant="standard" />
                    </FormControl>
                  </div>
                  <div className="col-md-6 form-group">
                    <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                      <TextField id="standard-basic-email" type="email" name="email" required label="Email" variant="standard" />
                    </FormControl>
                  </div>
                  <div className="col-md-6 form-group">
                    <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                      <TextField id="standard-basic-phone" type="tel" name="phone" required label="Phone" variant="standard" />
                    </FormControl>
                  </div>
                  <div className="col-md-6 form-group">
                    <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                      <TextField id="standard-basic-subject" name="subject" required label="Subject" variant="standard" />
                    </FormControl>
                  </div>
                  <div className="col-md-12 form-group">
                    <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                      <TextField id="standard-basic-note" type="text" multiline rows={4} name="message" label="Message" variant="standard" />
                    </FormControl>
                  </div>
                  <div className="col-md-12">
                    <button className="butn-dark">
                      <a href="#0">
                        <span>Send Message</span>
                      </a>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- Map Section --> */}
          <div className="row">
            <div
              className="col-md-12 map animate-box"
              data-animate-effect="fadeInUp"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7526702590817!2d32.57501691402446!3d0.3286845640983415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dbb7487960fc9%3A0x1bf5b6af293d6e01!2sEmin%20Pasha%20Hotel!5e0!3m2!1sen!2sug!4v1662579834698!5m2!1sen!2sug"
                width={"100%"}
                height={"600px"}
                title="The Emin Pasha Hotel"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default ContactUs;
