import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { array } from 'yup';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const initialState = {
    cartItem: [],
    roomsCart: [],
    enhancements: [],
    guestInfo: [],
    isOrderNow: true
};

// Adding data to nested array
// https://stackoverflow.com/questions/56262916/adding-object-to-nested-array-in-react-redux



const ManageSnackBar = (isOpen) => {
    console.log('::debug isOpen:', isOpen);
    const [open, setOpen] = React.useState(isOpen.isOpen);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    console.log('::debug open:', open);

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message="Note archived"
        />
    );
};
const reducer = (state = initialState, action) => {
    console.log('::debug state:', state);
    // eslint-disable-next-line default-case
    switch (action.type) {
        case 'SET_ORDER_TYPE':
            console.log("::ITEM set order type:", action.payload);
            return { ...state, isOrderNow: action.payload };
        case 'ADD_ROOM_TO_CART':
            const isRoomInCart = state.roomsCart.some(cartItem => cartItem.id === action.payload.id);
            if (isRoomInCart) {
                // already in cart, shallow copy cart items
                console.log("::ITEM ALREADY IN CART:", action.payload);

                return {
                    ...state,

                    // total= state.total + action.payload.cost
                }
            } else {
                console.log("::ITEM Not IN CAR:", action.payload);
                //const total = state.reduce((total, item) => total + item.price)

                return {
                    ...state,
                    roomsCart: [...state.roomsCart, action.payload]
                };
            }
        case 'ADD_TO_CART':
            const inCart = state.cartItem.some(cartItem => cartItem.id === action.payload.id);
            if (inCart) {
                <ManageSnackBar isOpen={true} />

                // already in cart, shallow copy cart items


                console.log("::ITEM ALREADY IN CART:", action.payload);

                return {
                    ...state,

                    // total= state.total + action.payload.cost
                }
            } else {
                action.payload.quantity = 1;
                console.log("::ITEM Not IN CAR:", action.payload);
                //const total = state.reduce((total, item) => total + item.price)

                return {
                    ...state,
                    cartItem: [...state.cartItem, action.payload]
                };
            }
        case 'ADD_ENHANCEMENT':
            console.log(';;debug state enhancements:', state.enhancements);
            console.log(';;debug enhancements playload:', action.payload);
            const isAlreadyAdded = state.enhancements.some(cartItem => 
                cartItem.en_id === action.payload.en_id && cartItem.roomId === action.payload.roomId);
            if (isAlreadyAdded) {
                <ManageSnackBar isOpen={true} />

                // already in cart, shallow copy cart items
                console.log(";;ITEM ALREADY IN CART:", action.payload);

                return {
                    ...state,

                    // total= state.total + action.payload.cost
                }
            } else {
                return {
                    ...state,
                    enhancements: [...state.enhancements, action.payload]
                };
            }
            case 'ADD_GUESTINFO':
                state.guestInfo = [];
                return {
                    ...state,
                    guestInfo: [...state.guestInfo, action.payload]
                };

        case 'EMPTY_CART':
            return {
                ...state,
                cartItem: [],
                roomsCart: [],
                guestInfo: [],
                enhancements: [],
                isOrderNow: false
            }
        case 'REMOVE_ENHANCEMENT_FROM_CART':
            let newEnhacementItems = state.enhancements.filter(cartItem => 
                cartItem.uniqueId !== action.payload.uniqueId);
            return { ...state, enhancements: newEnhacementItems };
        case 'REMOVE_ROOM_FROM_CART':
            let new_RoomItems = state.roomsCart.filter(cartItem => cartItem.id !== action.payload.id);
            let newRoomEnhacementItems = state.enhancements.filter(cartItem => 
                cartItem.roomId !== action.payload.id);
            return { ...state, roomsCart: new_RoomItems, enhancements: newRoomEnhacementItems };
        case 'REMOVE_FROM_CART':
            let new_items = state.cartItem.filter(cartItem => cartItem.id !== action.payload.id)
            return { ...state, cartItem: new_items };
        case 'INCREMENT_ITEM':
            console.log('::debug increment:', action.payload.quantity);
            console.log('::debug increment:');
            action.payload.price = parseInt(action.payload.price) / parseInt(action.payload.quantity);
            let addedItem = state.cartItem.find(item => item.id === action.payload.id)
            // addedItem.quantity += 1;
            addedItem.quantity = parseInt(addedItem.quantity) + 1;
            addedItem.price = parseInt(action.payload.price) * parseInt(addedItem.quantity);
            console.log(action.payload.quantity + " " + action.payload.price + " " + addedItem.price);
            return { ...state };
        // return Object.assign([], state.cartItem.map(cartItem => {
        //     if (cartItem.id === action.payload.id) {
        //         cartItem.quantity = parseInt(cartItem.quantity) + 1;
        //         //const myprice = cartItem.price/cartItem.quantity;
        //         cartItem.price = parseInt(action.payload.price) * parseInt(cartItem.quantity);
        //         console.log(action.payload.quantity + " " + action.payload.price + " " + cartItem.price);
        //     }
        //     return {...state};
        // }));
        case 'DECREMENT_ITEM':
            console.log(action.payload.quantity);

            if (action.payload.quantity > 1) {
                action.payload.price = parseInt(action.payload.price) / parseInt(action.payload.quantity);
                let addedItem = state.cartItem.find(item => item.id === action.payload.id)
                // addedItem.quantity += 1;
                addedItem.quantity = parseInt(addedItem.quantity) - 1;
                addedItem.price = parseInt(action.payload.price) * parseInt(addedItem.quantity);
                console.log(action.payload.quantity + " " + action.payload.price + " " + addedItem.price);
                return { ...state };
                // return Object.assign([], state.cartItem.map(cartItem => {
                //     if (cartItem.id === action.payload.id) {
                //         cartItem.quantity = parseInt(cartItem.quantity) - 1;
                //         //const myprice = cartItem.price/cartItem.quantity;
                //         cartItem.price = parseInt(cartItem.price) * parseInt(cartItem.quantity);
                //     }
                //     return {...state};
                // }));
            }
    }

    return (
        state
    )
};
export default reducer;