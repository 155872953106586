import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Image1 from '../../images/team/4.jpg';
import Image2 from '../../images/team/1.jpg';
import quot from '../../images/quot.png';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Image3 from '../../images/team/5.jpg';

function Testimony() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <div>
      {/* Testiominals */}
      <section className="testimonials">
        <div
          className="background bg-img bg-fixed section-padding pb-0"
          data-background="img/slider/2.jpg"
          data-overlay-dark="3"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="testimonials-box">
                  <div className="head-box">
                    <h6>Testimonials</h6>
                    <h4>What Client's Say?</h4>
                    <div className="line"></div>
                  </div>
                  <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={responsive}
                    ssr={false} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={4000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    <div className="item">
                      <span className="quote">
                        <img src={quot} alt="" />
                      </span>
                      <p>
                        I enjoyed my experience with Emin Pasha Hotel. I was
                        greated with so much love and I received the best
                        customer service.
                      </p>
                      <div className="info">
                        <div className="author-img">
                          {" "}
                          <img src={Image1} alt="" />{" "}
                        </div>
                        <div className="cont">
                          {" "}
                          <span>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                          </span>
                          <h6>Emily Brown</h6> <span>Guest review</span>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <span className="quote">
                        <img src={quot} alt="" />
                      </span>
                      <p>
                        I got a beautiful view from the garden suite, and the
                        food was amazing. The room service was very
                        professional.
                      </p>
                      <div className="info">
                        <div className="author-img">
                          {" "}
                          <img src={Image2} alt="" />{" "}
                        </div>
                        <div className="cont">
                          {" "}
                          <span>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                          </span>
                          <h6>Nolan White</h6> <span>Guest review</span>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <span className="quote">
                        <img src={quot} alt="" />
                      </span>
                      <p>
                        I intend to visit again because, I was able to book my
                        spa treatment very conveniently. The gym instructors
                        were very professional too.
                      </p>
                      <div className="info">
                        <div className="author-img">
                          {" "}
                          <img src={Image3} alt="" />{" "}
                        </div>
                        <div className="cont">
                          {" "}
                          <span>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                          </span>
                          <h6>Olivia Martin</h6> <span>Guest review</span>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Testimony;
