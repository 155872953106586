import React from 'react';
import { Link } from 'react-router-dom';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { connect } from 'react-redux';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { CurrencyFormat } from '../../shared/Formatter';
import { AddProductToCart } from '../../shared/utils/api/api';
import NoImge from '../../images/restaurant/no-pictures.png'

const mapDispatchToProps = dispatch => {
    return {
        addItemToCart: product => dispatch({ type: 'ADD_TO_CART', payload: product }),
        setOrderType: product => dispatch({ type: 'SET_ORDER_TYPE', payload: product }),
    };
};

const OrderList = (orderArray) => {
    // const orderArrayxxxx = props;
    console.log('::debug orderArray xxxx:', orderArray.orderArray);

    const AddItemToCart = async (product) => {
        try {
            await AddProductToCart({
                product_id: product.id,
                quantity: 1
            }).then((response) => {
                console.log('::debug Booking cart response:', response);
                orderArray.setOrderType(true);
            });
        } catch (error) {
            console.log('::debug BOOKing cart eerror:', error);
        }
    }

    return (
        <div id='order-list-page'>
            <section class="news">
                <div class="container">
                    <div class="row">
                        {
                            (orderArray.orderArray && orderArray.orderArray.length > 0) ?

                                orderArray.orderArray.map(order => {
                                    return (
                                        <div key={order.id} className='col-md-4'>
                                            <div class="item">
                                                <div class="position-re o-hidden">
                                                    {" "}
                                                    <Link style={{ width: '100%' }} to='#'>
                                                        {order.itemImages && order.itemImages.length > 0 ? <img
                                                            src={order.itemImages[0].image_name}
                                                            alt=''
                                                        />
                                                            :
                                                            <div style={{
                                                                backgroundColor: '#f0f8ff',
                                                                minHeight: '270px',
                                                                display: 'flex',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <img
                                                                    src={NoImge}
                                                                    width="100"
                                                                    alt=''
                                                                    style={{ width: '200px', height: '200px' }}
                                                                />
                                                            </div>
                                                        }
                                                    </Link>

                                                    {/* {
                                                    order.slug && (
                                                        <div class="date">
                                                            <Link to="/orders/order-product-details" state={{ orderObj: order }}>
                                                                {" "}
                                                                <span>{order.slug}</span> <i>{order.slug_text}</i>{" "}
                                                            </Link>
                                                        </div>
                                                    )
                                                } */}
                                                </div>
                                                <div class="con">
                                                    {" "}
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="order-item-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div className="order-data">
                                                                    <span class="category">
                                                                        <Link to='#'>{order.title}</Link>
                                                                    </span>
                                                                    <h5>
                                                                        {CurrencyFormat(order.price ?? 0) + ' UGX'}
                                                                    </h5>
                                                                </div>
                                                                {/* <div className="order-cart">
                                                                <AddShoppingCartIcon onClick={() => { orderArray.addItemToCart(order); orderArray.setOrderType(true); }} style={{ cursor: 'pointer' }} />
                                                            </div> */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="order-list-btn-row">
                                                    <div className="order-now-btn">
                                                        {" "}
                                                        <Link to="/orders/ordernow" data-scroll-nav="1" state={{ orderObj: order }}>
                                                            <span>Order Now</span>
                                                        </Link>{" "}
                                                    </div>

                                                    <div className="btn-order">
                                                        {" "}
                                                        <Link to="/orders/orderforlater" data-scroll-nav="1" state={{ orderObj: order }}>
                                                            <span>Order For Later</span>
                                                        </Link>{" "}
                                                    </div>
                                                </div> */}

                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                                :
                                (
                                    <div className='cart-empty'>
                                        <div className="row">
                                            <div style={{ display: 'flex', justifyContent: 'center' }} className="col-md-12">
                                                <HourglassEmptyIcon style={{ color: '#cdcccc', fontSize: 83 }} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }} className='col-md-12'>
                                                <p style={{ fontWeight: 'bold', fontSize: 29 }}>No products available </p>

                                            </div>
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </div>
            </section >
        </div >
    );
};



export default connect(null, mapDispatchToProps)(OrderList);
