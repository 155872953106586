import React from 'react';
import NotFoundImage from '../images/icons/not-found.png';

const NotFound = () => {
    return (
        <div className='notfound-page' style={{ backgroundColor: '#ababaa' }}>
            <section id='content-history-section' className="rooms-page section-padding" data-scroll-index="1">
                <div className="container">
                    <div className="row">
                        <div 
                            className="col-md-12 col-sm-12" 
                            style={{ marginTop: 75 }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'center'}}>
                                <img 
                                    src={NotFoundImage} 
                                    alt="" 
                                    style={{ width: 100, height: 100}}
                                />
                            </div>
                            <h1 style={{ textAlign: 'center', marginBottom: 0}}>404 - Page Not Found</h1>
                            <p style={{ textAlign: 'center'}}>The page you are looking for does not exist.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default NotFound;
