import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ImageNotFound from '../../images/restaurant/image-not-found.png';
import { GetAllRooms } from '../../shared/utils/api/api';
import { CurrencyFormat } from '../../shared/Formatter';
import Iframe from '../../shared/bookingIframe/iframe';

function HomeRooms(props) {
  const [roomsArray, setRoomsArray] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  

  useEffect(() => {
    console.log('xxx useeffect');
    const fetchRooms = async () => {
      props.setShowProgress(true);
      try {
        await GetAllRooms().get('', { params: { limit: 4 } }).then((response) => {
          console.log('xxx home Response:', response);
          setRoomsArray(response.data.data);
          console.log('xxx home Response:', response.data.data);
        });
      } catch (error) {
        props.setShowProgress(false);
        setOpenSnackBar(true);
        setErrorMessage(error.response.data.errors.query._schema[0]);
        console.log('xxxdebug ERRRORR',error);
      } finally {
        props.setShowProgress(false);
      }
    };
    fetchRooms();
  }, []);

  const handleClose = () => {
    setOpenSnackBar(false);
  };

  return (
    <div>
      {/* Rooms  */}
      <section
        className="rooms1 section-padding bg-cream"
        data-scroll-index="1"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-subtitle">The Emin Pasha Hotel</div>
              <div className="section-title">Rooms & Suites</div>
            </div>
          </div>
          <div className="row">
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={openSnackBar}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert variant="filled" severity="error">{errorMessage}</Alert>
            </Snackbar>
            {
              showIframe && <>
                <Iframe/>
              </>
            }
            {
              roomsArray.length > 0 &&
              roomsArray.map(room => {
                return (
                  <div  key={room.id} className="col-md-6">
                    <div className="item">
                      
                      <div className="position-re o-hidden">
                        {" "}
                        <Link to={`/rooms/guestrooms/:guestroomid/?id=${room.id}`} state={{ roomObj: room }}>
                          {
                            room.roomImage ? (
                              <img 
                                src={ room.roomImage.length > 0 ? room.roomImage[0]?.image_name : ImageNotFound} 
                                style={{
                                  width: '100%',
                                  display: 'block',
                                  height: 'auto'
                                }} 
                                alt="" />
                            ):(
                              <img 
                                src={ImageNotFound} 
                                alt="" 
                                style={{
                                  width: '100%',
                                  display: 'block',
                                  height: 'auto'
                                }}
                              />        
                            )
                          }
                          
                        </Link>
                          
                          {" "}
                      </div>{" "}
                      <span className="category">
                        <Link to="/new-booking">Book</Link>
                      </span>
                      <div className="con">
                        <h6>
                          <Link to={`/rooms/guestrooms/:guestroomid/?id=${room.id}`} state={{ roomObj: room }}>
                            {`${CurrencyFormat(room.room_base_price)}USD / Night`}
                          </Link>
                        </h6>
                        <h5>
                          <Link to={`/rooms/guestrooms/:guestroomid/?id=${room.id}`} state={{ roomObj: room }}>
                            {room.room_name}
                          </Link>
                        </h5>
                        <div className="line"></div>
                        <div className="row facilities">
                          <div className="col col-md-7">
                            <ul>
                              <li>
                                <i className="flaticon-bed"></i>
                              </li>
                              <li>
                                <i className="flaticon-bath"></i>
                              </li>
                              <li>
                                <i className="flaticon-breakfast"></i>
                              </li>
                              <li>
                                <i className="flaticon-towel"></i>
                              </li>
                            </ul>
                          </div>
                          <div className="col col-md-5 text-right">
                            <div className="permalink">
                              <Link to={`/rooms/guestrooms/:guestroomid/?id=${room.id}`} state={{ roomObj: room }}>
                                Details <i className="ti-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeRooms;
