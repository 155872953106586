import React from 'react';
import { Link } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import bimage2 from '../images/slider/2.jpg';
import useBreakPoint from './../../shared/breakPoints/useBreakPoint';

const HomeHeader = () => {
  const { matchesMobile } = useBreakPoint();
  console.log('::debug matchesMobile:', matchesMobile);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <div>
      <header className="header home-header-container slider-fade">
        {/* The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9.  */}
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoplayResetOnHover={false}
          autoPlaySpeed={4000}
          keyBoardControl={false}
          shouldResetAutoplay={false}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {/* <div className="text-center item bg-img1" data-overlay-dark="2">
            <div className="v-middle caption">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <span>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                    </span>
                    <h4>The Emin Pasha Hotel</h4>
                    <h1>Enjoy a Luxury Experience</h1>
                    <div className="butn-light mt-30 mb-13">
                      {" "}
                      <Link to="/rooms" data-scroll-nav="1">
                        <span>Rooms & Suites</span>
                      </Link>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div
            className="text-center item bg-img4"
            data-overlay-dark="3"
            data-background="img/slider/1.jpg"
            style={{ backgroundSize: '100% 38% !important'}}
          >
            <div className="v-middle caption">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                  { !matchesMobile && <>
                    <span>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                    </span>
                    
                      <h4>Indulge in Pure Luxury</h4>
                      <h1>Create Timeless Memories in Our Luxurious Hotel Rooms</h1>
                      </>}
                    <div className="butn-light mt-30 mb-13">
                      {" "}
                      <Link to="/rooms" data-scroll-nav="1">
                        <span>Rooms & Suites</span>
                      </Link>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-center item bg-img5"
            data-overlay-dark="3"
            data-background="img/slider/1.jpg"
          >
            <div className="v-middle caption">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    {
                      !matchesMobile && 
                      <>
                      <span>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                    </span>
                    <h4>A Symphony of Comfort</h4>
                    <h1>Uncover Unmatched Comfort in Our Hotel Rooms</h1>
                      </>
                    }
                    
                    <div className="butn-light mt-30 mb-13">
                      {" "}
                      <Link to="/rooms" data-scroll-nav="1">
                        <span>Rooms & Suites</span>
                      </Link>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-center item bg-img3"
            data-overlay-dark="3"
            data-background="img/slider/1.jpg"
          >
            <div className="v-middle caption">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    {
                      !matchesMobile && 
                      <>
                        <span>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                    </span>
                    <h4>The Ultimate Luxury Experience</h4>
                    <h1>Enjoy The Best Moments of Life</h1>
                      </>
                    }
                    
                    <div className="butn-light mt-30 mb-13">
                      {" "}
                      <Link to="/rooms" data-scroll-nav="1">
                        <span>Rooms & Suites</span>
                      </Link>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-center item bg-imgord1"
            data-overlay-dark="3"
            data-background="img/slider/1.jpg"
          >
            <div className="v-middle caption">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    {
                      !matchesMobile && 
                      <>
                      <span>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                    </span>
                    <h4>The Ultimate Recipe</h4>
                    <h1>Enjoy A Delicious Meal</h1>
                      </>
                    }
                    
                    {/* <div className="butn-light mt-30 mb-13">
                      {" "}
                      <Link to="/orders/order-landing" data-scroll-nav="1">
                        <span>Order Now</span>
                      </Link>{" "}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-center item bg-img2"
            data-overlay-dark="2"
            data-background="img/slider/3.jpg"
          >
            <div className="v-middle caption">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    {
                      !matchesMobile &&
                      <>
                      <span>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                    </span>
                    <h4>Unique Place to Relax & Enjoy</h4>
                    <h1>The Perfect Base For You</h1>
                      </>
                    }
                    
                    {/* <div className="butn-light mt-30 mb-13">
                      {" "}
                      <Link to="/restruantsandbars" data-scroll-nav="1">
                        <span>Restaurant & Bar</span>
                      </Link>{" "}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          

        </Carousel>

        <div className="reservation">
          <a href="tel:+256 312 264 712">
            <div className="icon d-flex justify-content-center align-items-center">
              <i className="flaticon-call"></i>
            </div>
            <div className="call">
              <span>+256 312 264 712 /4</span> <br />
              Reservation
            </div>
          </a>
        </div>
      </header>
    </div>
  );
};

export default HomeHeader;
