import React from 'react'
import HotelFacilities from '../components/facilities/facilities.jsx';
import Testimony from "../components/home/Testimony";
const Facilities = () => {
  return (
    <div>
      <HotelFacilities/> 
      {/* <Testimony /> */}
    </div>
  );
}

export default Facilities;