import React from 'react';
import HomeHeader from '../components/home/HomeHeader';
import HorizontalCheckform from '../components/home/HorizontalCheckform';
import HomeAbout from '../components/home/HomeAbout';
import HomeRooms from '../components/home/HomeRooms';
import HomePricing from '../components/home/HomePricing';
import HomeFacilities from '../components/home/HomeFacilities';
import HomeServices from '../components/home/HomeServices';
import Bookingform from '../components/booking/Bookingform';
import Testimony from '../components/home/Testimony';
import Video from '../components/home/VideoPlayer';

const Home = (props) => {
  
  return (
    <div>
      <HomeHeader />
      {/* <HorizontalCheckform /> */}
      <HomeAbout />
      <HomeRooms setShowProgress={props.setShowProgress}/>
      <HomePricing />
      <Video />
      <HomeFacilities />
      {/* <Testimony /> */}
      <HomeServices />
      <Bookingform />
     
    </div>
  );
};

export default Home;
