import React from 'react';
import RestruantsAndBars from '../components/restruants-and-bars/restruants-and-bars.jsx';

import Testimony from '../components/home/Testimony';
const Restruantsandbars = (props) => {
  return (
    <div>
      <RestruantsAndBars {...props} /> 
      {/* <Testimony /> */}
    </div>
  );
};

export default Restruantsandbars;
