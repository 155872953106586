import axios from "axios";

// axios.defaults.withCredentials = true; 04250992452
//const client = wrapper(axios.create({ jar }));
// dev base url=http://localhost:5000
// const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000/';
//const baseUrl = 'https://api.eminpasha.com/';
const isProduction = process.env.NODE_ENV === "production";
//const baseUrl = isProduction ? 'https://api.eminpasha.com/' : 'http://localhost:5000/';
const baseUrl = 'https://api.eminpasha.com/' ;
const client = (endpoint) => {
  axios.defaults.withCredentials = true;
 const axio = axios.create({
        withCredentials: true,
        baseURL: baseUrl+endpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Cache: "no-cache",
        },
        
      });
return axio;
}

// api.eminpasha.com
// {
//   "username": "mulumbanasf",
//   "firstName": "Mulumba",
//   "lastName": "Nasiifu",
//   "email": "mulumbanasf@gmail.com",
//   "password": "Password!123",
//   "phone": "256700789099",
//   "country": "Uganda",
//   "about_me": "Admin"
// }

// {
//   "username": "mulumbanasf",
//   "firstName": "Mulumba",
//   "lastName": "Nasiifu",
//   "email": "mulumbanasf@gmail.com",
//   "password": "Password!123",
//   "about_me": "Admin"
// } eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbiI6ImQ0WFpHSlhzYkV2ZUxyWlhwSmZ0UjNPc2wxcXNEZTFHMXh6Y2RpMnBUaFUifQ.IvjwECrQjcQ9o28J2Q7XabxNtRBqmtrI9REVXZRNkQk

// "first_name": "Admin",
// "last_name": "User",
// "username": "adminuser",
// "email": "mulumbanasf@gmail.com",
// "password": "Password!123"
//MIjLBRQ90j8IHoJZtFmOE8NrmAdkVkqmX3dhteuNASI

// {
//   "id": 3,
//   "url": "/v1.0/admin/users/3",
//   "first_name": "Mulumba",
//   "last_name": "Nasiifu",
//   "username": "MulNasf",
//   "email": "mulumbanasiif@gmail.com",
//   "avatar_url": "https://www.gravatar.com/avatar/869374bb532452fb1c883bc0ec69f541?d=identicon",
//   "first_seen": "2022-12-17T11:12:31Z",
//   "last_seen": "2022-12-17T11:12:31Z"
// }

// _aYCbhAnWz2nT37BM6uVLKREZwp74v7L_fg8QCcrlDw

const token = '';

const RefreshAccessToken = () => {
  const accessToken = localStorage.getItem('access_token');
  const refreshToken = localStorage.getItem('refresh_token');
  const tokenExpiry = localStorage.getItem('access_token_expiry');
  const data = {
    access_token: accessToken,
    refresh_token: refreshToken,
    access_expiration: tokenExpiry,
  }
  const response = client('v1.0/tokens').put('', { ...data })
  return response
}

const checkIfAccessTokenStillValid = () => {
  console.log('::token checkIfAccessTokenStillValid:')
  const expiryDate = localStorage.getItem('access_token_expiry');
  const currentTimeStamp = new Date().getTime()
  const expiryTimestamp = Date.parse(expiryDate)
  console.log('::token currentTimeStamp:', currentTimeStamp)
  console.log('::token expiryTimestamp:', expiryTimestamp)
  if (currentTimeStamp >= expiryTimestamp) {
    console.log('::token expired token:')
    RefreshAccessToken().then(response => {
      console.log('::Debug refreshed access token:', response)
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('access_token_expiry', response.data.access_expiration);
      localStorage.setItem('refresh_token', response.data.refresh_token);
    })
  }
}

export const GetAllRooms = () => {
    const rooms = client('v1.0/rooms');
    return rooms;
}

export const FetchSpecificRoom = (id) => {
  const room = client(`v1.0/rooms/${id}`).get();
  return room;
}

export const GetRoomEnhancements = () => {
  const enhancement = client(`v1.0/admin/enhancements`);
  return enhancement;
}

export const FetchRoomImages = (roomId) => {
  const roomImages = client(`v1.0/room/images/${roomId}`);
  return roomImages;
}

export const GetAllBooking = (roomId) => {
  const bookings = client(`v1.0/bookings`);
  return bookings;
}

export const GetInvoiceDetails = () => {
  const invoiceDetails = client(`v1.0/reservations`).get('',{
    headers: {
      'Authorization': `Bearer ${token}` 
    }
  });
  return invoiceDetails;
}

export const RegisterBooking = (data) => {
  const response = client(`v1.0/bookings/`).post('',{...data},{
    headers: {
      'Authorization': `Bearer ${token}` 
    }
  });
  return response;
}

export const CheckRoomAvailability = (data) => {
  const rooms = client(`v1.0/booking/availability`).get('',{
    params: data
  });
  return rooms;
}

export const CheckSpecificRoomAvailability = (data) => {
  console.log('==debug v1.0III:', data);
  const rooms = client(`v1.0/booking/room/availability`).get('',{
    params: data
  });
  return rooms;
}

export const GetGuestBookings = (guestId) => {
  const bookings = client(`v1.0/bookings/${guestId}/all`).get('',{
    headers: {
      'Authorization': `Bearer ${token}` 
    }
  });
  return bookings;
}

export const RegisterEnhancement = (data) => {
  const response = client(`v1.0/enhacements`).post('',{...data});
  return response;
}

export const RegisterRate = (data) => {
  const response = client(`v1.0/rate`).post('',{...data}, {
    headers: {
      'Authorization': `Bearer ${token}` 
    }
  });
  return response;
}

export const GetUserReservations = async () => {
  await checkIfAccessTokenStillValid();
  const authToken = localStorage.getItem('access_token');
  const userJson = localStorage.getItem('userData');
  const userObj = JSON.parse(userJson);
  const rate = client(`v1.0/admin/guest/${userObj.id}/bookings`).get('',{
    headers: {
      'Authorization': `Bearer ${authToken}` 
    }
  });
  return rate;
}

export const ResetUserPassword = (data) => {
  const response = client(`v1.0/tokens/reset`).post('',{...data});
  return response;
}

export const GetASpecificRate = (rateId) => {
  const rate = client(`v1.0/rate/id`).get('',{
    params: rateId
  });
  return rate;
}

export const FetchCurrentUserData = () => {
  const rate = client(`me`).get('',{
    headers: {
      'Authorization': `Bearer ${token}` 
    }
  });
  return rate;
}

export const RegisterNewGuest = (data) => {
  const response = client(`guests`).post('',{...data});
  return response;
}

export const FetchGuestById = (guestId) => {
  const response = client(`guests`).get('',{
    headers: {
      'Authorization': `Bearer ${token}` 
    },
    params: {id: guestId},
  });
  return response;
}

export const EditGuestInformation = (guestId) => {
  const response = client(`guests`).put('','',{
    headers: {
      'Authorization': `Bearer ${token}` 
    },
    params: {id: guestId},
  });
  return response;
}

export const FetchGuestByUsername = (username) => {
  const response = client(`guests`).get('',{
    headers: {
      'Authorization': `Bearer ${token}` 
    },
    params: {username: username},
  });
  return response;
}

export const EditUserInformation = (data) => {
  const response = client(`me`).put('',{...data},{
    headers: {
      'Authorization': `Bearer ${token}` 
    },
  });
  return response;
}

export const RegisterUser = (data) => {
  const response = client(`users`).post('',{...data}, {
    headers: {
      'Authorization': `Bearer ${token}` 
    }
  });
  return response;
}

export const CheckIfUserHasAnAccount = (email) => {
  const response = client(`v1.0/registration/status/${email}`).get();
  return response;
}

export const FetchUserById = (userId) => {
  const response = client(`users`).get('',{
    headers: {
      'Authorization': `Bearer ${token}` 
    },
    params: {id: userId},
  });
  return response;
}

export const FetchUserByUserName = (username) => {
  const response = client(`users`).get('',{
    headers: {
      'Authorization': `Bearer ${token}` 
    },
    params: {username: username},
  });
  return response;
}

export const GetRoomsInCart = () => {
  const response = client(`v1.0/roomcart/`).get();
  return response;
}

export const LoginUser = (username, password) => {
  console.log('::Debug username:', username);
  console.log('::Debug password:', password);
  const response = client(`v1.0/tokens`).post('', null, {
    headers: {
      // Authorization: `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}`,
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    },
  });
  return response;
}

export const LogoutUserToken = async () => {
  await checkIfAccessTokenStillValid()
  const authToken = localStorage.getItem('access_token')
  console.log('::debug token:', authToken)
  const response = client('v1.0/tokens').delete('', {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
  return response
}

export const GetAuthenticatedUser = async () => {
  checkIfAccessTokenStillValid();
  const authToken = localStorage.getItem('access_token');
  const response = client('v1.0/me').get('', {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
  return response
}

export const ProcessBooking = (data) => {
  checkIfAccessTokenStillValid();
  const authToken = localStorage.getItem('access_token')
  const response = client(`v1.0/roomcart/processbooking/`).post('',{...data}, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response;
}

export const ProcessBookingForAnonymousUser = (data) => {
  const response = client(`v1.0/roomcart/guest/processbooking/`).post('',{...data},{});
  return response;
}

export const DeleteRoomFromCart = (entryId) => {
  const response = client(`v1.0/roomcart/delete/${entryId}`).delete();
  return response;
}

export const ClearBookingCart = () => {
  const response = client(`v1.0/roomcart/clear`).delete();
  return response;
}

export const DeleteEnhancementFromCart = (entryId, enhancementId) => {
  const response = client(`v1.0/roomcart/remove_enhancement/${entryId}/${enhancementId}`).delete();
  return response;
}

export const AddEnhancementToRoomInCart = (entryId, data) => {
  const response = client(`v1.0/roomcart/enhancement/${entryId}`).post('',{...data}, {});
  return response;
}

export const AddRoomToBookingCart = (data) => {
  const response = client(`v1.0/roomcart/add/`,{
    // headers: { 
    //   Cookie: "Cookie_2=value; session=b1705442-a74d-4398-817f-b7cad6a87cd9"
    // }
  }).post('',{...data});
  return response;
}

// Restaurant Apii
export const GetAllRestaurantProducts = () => {
  const products = client('v1.0/products/all');
  return products;
}

export const GetAllRestaurants = () => {
  const restaurants = client('v1.0/restaurant/all');
  return restaurants;
}

export const GetProductById = (id) => {
  const product = client(`v1.0/p/product/${id}`).get();
  return product;
}

export const GetRestaurantById = (id) => {
  const product = client(`v1.0/restaurant/${id}`);
  return product;
}

export const GetProductsInCart = () => {
  const product = client(`v1.0/cart`);
  return product;
}

export const AddProductToCart = (data) => {
  const response = client(`v1.0/cart/add/`,{
  }).post('',{...data});
  return response;
}

export const DeleteProductFromCart = (id) => {
  const product = client(`v1.0/cart/delete/${id}`);
  return product;
}


