import React from 'react';
import OrderList from './OrderList';
import Image1 from "../../images/restaurant/burger.jpg";
import Image2 from "../../images/restaurant/fishsteak.jpg";
import Image3 from "../../images/restaurant/friedchicken.jpg";

const OrderMain = () => {
    const orderArray = [
        {
            id: 1,
            title: "Rusty’s Burger",
            price: 47500,
            currency: "USD",
            rest_id: 1,
            menu_id: 1,
            inventory_count: 100,
            visits: 0,
            restaurant: {
            res_name: "Sir Baker Dinning",
            url: "/v1.0/restaurant/1?slug=fine-dinning",
            slug: "fine-dinning"
            },
            item_menu: {
            menu_title: "Main Menu",
            slug: "main-menu"
            },
            item_images: [
            {
            image_name: Image1
            }]
        },
        {
            id: 2,
            title: "Cajun Fish Steak",
            price: 32000,
            currency: "USD",
            rest_id: 1,
            menu_id: 1,
            inventory_count: 100,
            visits: 0,
            restaurant: {
            res_name: "Sir Baker Dinning",
            url: "/v1.0/restaurant/1?slug=fine-dinning",
            slug: "fine-dinning"
            },
            item_menu: {
            menu_title: "Main Menu",
            slug: "main-menu"
            },
            item_images: [
            {
            image_name: Image2
            }]
        },
        {
            id: 3,
            title: "Southern Fried Chicken",
            price: 52000,
            currency: "USD",
            rest_id: 1,
            menu_id: 1,
            inventory_count: 100,
            visits: 0,
            restaurant: {
            res_name: "Sir Baker Dinning",
            url: "/v1.0/restaurant/1?slug=fine-dinning",
            slug: "fine-dinning"
            },
            item_menu: {
            menu_title: "Main Menu",
            slug: "main-menu"
            },
            item_images: [
            {
            image_name: Image3
            }]
        },
    ]
    return (
        <div id='order-list-page'>
            {/* Header Banner */}
            <div
                className='banner-header section-padding valign bg-orderimg bg-fixed'
                data-overlay-dark='4'
                data-background='img/slider/1.jpg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 caption mt-90'>
                            <h5>The Emin Pasha Hotel</h5>
                            <h1>Main Menu</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section id='order-dashboard' className="facilties section-padding bg-order-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-subtitle">Our Main Menu</div>
                            <div className="section-title">We now deliver</div>
                        </div>
                    </div>
                    <OrderList orderArray={orderArray}/>
                </div>
            </section>
        </div>
    );
};

export default OrderMain;
