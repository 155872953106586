import React from 'react';

const TermsOfService = () => {
    return (
        <div id='order-list-page'>
            {/* Header Banner */}
            <div
                className='banner-header section-padding valign bg-orderimg bg-fixed'
                data-overlay-dark='4'
                data-background='img/slider/1.jpg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 caption mt-90'>
                            <h5>The Emin Pasha Hotel</h5>
                            <h1>Reservation Terms And Conditions</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section id='order-dashboard' className="facilties section-padding bg-order-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {/* <div className="section-subtitle">Our Reservation Policy</div> */}
                            <div className="section-title" style={{ color: '#aa8453'}}>Reservation Policy</div>
                        </div>
                        <div className="col-md-12">
                            <h2 style={{ marginBottom: 19 }}>BOOKING PAYMENTS & GUARANTEES</h2>
                            <p>
                                All group bookings need to be confirmed 14 days prior to arrival. Full payment of group booking
                                is required 14 days prior to arrival. All final changes and rooming lists must be received in
                                writing 7 days prior to arrival for all groups. Any changes done thereafter will be charged for in
                                full. Bookings not confirmed in writing within the above time will be released. Groups of over
                                10 rooms need to be guaranteed with 50% deposit at the time of making the booking. All our
                                rates are on a non-commissionable basis, and are not negotiable.
                            </p>
                            <p>
                                All non- guaranteed bookings will be released at 18h00 daily if not guaranteed with a company
                                LPO, by credit card, or by prepayment. All bookings that will be settled by local company
                                require an LPO (local purchase order) before arrival of the guests; alternatively full payment will
                                be required before check-in.
                            </p>

                            <p style={{ marginBottom: 30}}>
                                The Hotel only accepts the following credit cards: Visa, Master Card &amp; American Express.
                                Payment via electronic funds transfer must be done 1 week prior to check-in and the proof of
                                payment sent to us for verification purposes.
                            </p>
                            <h2 style={{ marginBottom: 19 }}>CHECK-IN/CHECK-OUT TIMES</h2>
                            <p>
                                Check out time is strictly at 10h00 and check in time at 14h00.
                            </p>

                            <p>
                                Early check-in can be booked on request. During periods of high occupancy we cannot
                                guarantee that a request for early check-in will be met for an early arrival. If an early check-in
                                needs to be guaranteed, we kindly request that the room is reserved from the night before the
                                actual date of arrival. Please note that this is subject to a charge of 100% of the daily rate
                                (including all taxes)
                            </p>

                            <p style={{ marginBottom: 30}}>
                                Late check-out can be booked at the front desk on the morning of departure. All late check-outs
                                will be charged at a 50% of the daily rate given from 10h00 - 18h00, 100% of the daily rate given
                                from 18h00 onwards. Late departures are strictly subject to availability.
                            </p>
                            <h2 style={{ marginBottom: 19 }}>CANCELLATIONS</h2>
                            <p>
                                Please be advised that we charge a no-show/cancellation fee, if bookings are not cancelled in
                                writing 48 hours prior to arrival. No-shows and late cancellations are charged at one night’s full
                                accommodation (including taxes) on the rate booked. If the Hotel occupancy is high at the time
                                of a no-show or late cancellation, the full number of nights will be charged.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TermsOfService;
