import React from 'react'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const AcropoleLounge = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <div>
      <header className="header home-header-container slider-fade slider">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {/* The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. */}
          <div
            className="text-center item bg-acropole-img"
            data-overlay-dark="3"
            data-background="../../images/spa/3.jpg"
          >
            {/* <img src={Image1} alt="" /> */}
          </div>
          <div
            className="text-center item bg-acropole-img1"
            data-overlay-dark="3"
            data-background="../../images/swimmingpool/swimmingpool1.jpeg"
          >
            {/* <img src={Image2} alt="" /> */}
          </div>
          <div
            className="text-center item bg-acropole-img2"
            data-overlay-dark="3"
            data-background="../../images/spa/2.jpg"
          >
            {/* <img src={Image3} alt="" /> */}
          </div>
        </Carousel>
        {/* arrow down */}
        <div className="arrow bounce text-center">
          <a href="#preface-section" data-scroll-nav="1" className="">
            {" "}
            <i className="ti-arrow-down"></i>{" "}
          </a>
        </div>
      </header>
      <section id='preface-section' className="rooms-page section-padding" data-scroll-index="1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {" "}
              <span>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
              </span>
              <div className="section-subtitle">WORK AND RESEARCH</div>
              <div className="section-title">Acropole Lounge</div>
              <p className="mb-3">
                Welcome to The Acropole Lounge, an oasis of elegance and 
                sophistication nestled within the heart of our 
                luxurious hotel. Step into a world of refined comfort, 
                where modern design seamlessly merges with timeless 
                charm to create an unparalleled atmosphere.
              </p>
              <p className="mb-3">
                As you enter The Acropole Lounge, you are greeted by an 
                ambiance that exudes tranquility and warmth. Soft 
                lighting casts a gentle glow over the plush 
                furnishings, inviting you to unwind and indulge in the 
                ultimate relaxation. The tasteful décor, featuring a 
                harmonious blend of contemporary elements and classic 
                accents, sets the stage for a truly memorable experience.
              </p>
              <p className="mb-3">
                Our lounge offers an array of seating options to suit 
                every mood and occasion. Sink into sumptuous armchairs 
                by the fireplace, perfect for intimate conversations or 
                savoring a glass of fine wine. Gather with friends at 
                the sleek bar, where skilled mixologists craft 
                artisanal cocktails with a creative flair. For those 
                seeking a more private setting, our secluded alcoves 
                provide a secluded retreat for moments of solitude or 
                business discussions.
              </p>
            </div>
            <div className="col-md-12">
              <div className="reservations">
                <div className="icon">
                  <span className="flaticon-call"></span>
                </div>
                <div className="text">
                  <p>Reservations</p>{" "}
                  <a href="tel:+256 312 264 712">+256 312 264 712 /4</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AcropoleLounge;