import React from 'react';
import HotelLibrary from "../components/library/library.jsx";
import Testimony from "../components/home/Testimony";
const Library = () => {
  return (
    <div>
      <HotelLibrary/>
      {/* <Testimony /> */}
    </div>
  );
}

export default Library;