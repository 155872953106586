import React from 'react';
import Guestroomdetail from '../components/room-details/guestroom-details.jsx';
import Bookingform from '../components/booking/Bookingform';
import Clients from '../components/clients/clients.jsx';

const Guestroomdetails = (props) => {
  return (
    <div>
      <Guestroomdetail setShowProgress={props.setShowProgress} />
      <Bookingform />
      {/* <Clients /> */}
    </div>
  );
};

export default Guestroomdetails;
