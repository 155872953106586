import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Check from '@mui/icons-material/Check';
import AvailableRooms from './AvailableRooms';
import SelectedRooms from './SelectedRooms';
import DatePicker from "react-datepicker";
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';
import PaidIcon from '@mui/icons-material/Paid';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MakePayment from './MakePayment';
import { FormatDate } from '../../shared/Formatter/index';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { nationalityList } from "../../shared/constants/Nationality"
import { ChevronCompactDown, ChevronCompactUp, XCircle } from 'react-bootstrap-icons';
import useBreakPoint from './../../shared/breakPoints/useBreakPoint';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import { useLocation, useNavigate } from 'react-router-dom';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { CurrencyFormat } from '../../shared/Formatter';
import { CheckRoomAvailability, LoginUser, ProcessBookingForAnonymousUser, GetAuthenticatedUser, ClearBookingCart, GetRoomEnhancements, CheckSpecificRoomAvailability, ProcessBooking, GetRoomsInCart, FetchSpecificRoom, CheckIfUserHasAnAccount, DeleteRoomFromCart } from './../../shared/utils/api/api';

const mapDispatchToProps = dispatch => {
  return {
    removeRoom: product =>
      dispatch({ type: 'REMOVE_ROOM_FROM_CART', payload: product }),
    emptyCart: product =>
      dispatch({ type: 'EMPTY_CART' }),
    addGuestInfo: product =>
      dispatch({ type: 'ADD_GUESTINFO', payload: product }),
    removeEnhancement: product =>
      dispatch({ type: 'REMOVE_ENHANCEMENT_FROM_CART', payload: product }),
    addEnhancement: product =>
      dispatch({ type: 'ADD_ENHANCEMENT', payload: product }),
  };
};

const mapStateToProps = state => {
  return {
    cartItems: state,
  };
};

const steps = ['Room Search', 'Available Rooms', 'Selected Rooms', 'Guest Information', 'Payment'];
const BookingStepper = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [availableRoomsArray, setAvailableRoomsArray] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [hasAcceptedTos, setHasAcceptedTos] = useState(false);
  const [hasAcceptedTosError, setHasAcceptedTosError] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [showRoomDetailsId, setShowRoomDetailsId] = useState(0);
  const [salutation, setSalutation] = useState("");
  const [nationality, setNationality] = useState("");
  const [cvvError, setCvvError] = useState(false);
  const [mobileMoneyNumberError, setMobileMoneyNumberError] = useState(false);
  const [expiryYearError, setExpiryYearError] = useState(false);
  const [cardFirstNameError, setCardFirstNameError] = useState(false);
  const [cardNumberError, setCardNumberError] = useState(false);
  const [cardLastNameError, setCardLastNameError] = useState(false);
  const [expiryMonthError, setExpiryMonthError] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [roomsInCartLength, setRoomsInCartLength] = useState(0);
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [identityType, setIdentityType] = useState("");
  const [identityNumber, setIdentityNumber] = useState("");
  const [gender, setGender] = useState(null);
  const [cartStartDate, setCartStartDate] = useState(null);
  const [cartEndDate, setCartEndDate] = useState(null);
  const [numberOfAdults, setNumberOfAdults] = useState("1");
  const [numberOfChildren, setNumberOfChildren] = useState("0");
  const [errorMessage, setErrorMessage] = useState("");
  const [cartItemChange, setCartItemChange] = useState(0);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [selectedRoomName, setSelectedRoomName] = useState(null);
  const [roomEnhancements, setRoomEnhancements] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [enhancementsArr, setEnhancementsArr] = useState([]);
  const [grossAmount, setGrossAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [cardNumber, setCardNumber] = useState("");
  const [cvv, setCvv] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [cardFirstName, setCardFirstName] = useState("");
  const [cardLastName, setCardLastName] = useState("");
  const [mobileMoneyNumber, setMobileMoneyNumber] = useState("");
  const { matchesAllMobile } = useBreakPoint();
  const nationalityArray = nationalityList;
  const currentDate = new Date();
  const { matchesMobile } = useBreakPoint();
  const [isAnonymousUserModalopen, setIsAnonymousUserModalopen] = useState(false);
  const [userModalopen, setUserModalopen] = useState(false);
  const [isUserHavingAccount, setIsUserHavingAccount] = useState(false);
  const handleOpenAnonymousUserModal = () => setIsAnonymousUserModalopen(true);
  const handleCloseAnonymousUserModal = () => setIsAnonymousUserModalopen(false);

  const handleChange = (event) => {
    setSalutation(event.target.value);
  };

  const handleAnonymousModalContinue = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const getAuthenticatedUser = () => {
    try {
      GetAuthenticatedUser().then(response => {
        console.log('::debug auth user:', response);
        localStorage.setItem('userData', JSON.stringify(response.data));
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      })
    } catch (error) {
      setOpenSnackBar(true);
      setErrorMessage('Oops an error occured');
    }
  };

  const handleLoginUser = (e) => {
    e.preventDefault();
    console.log('::debug handleLoginUserfunction');
    props.setShowProgress(true);
    try {
      LoginUser(userName, password).then(response => {
        setUserModalopen(false);
        props.setShowProgress(false);
        console.log('::debug login successful:', response);
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('access_token_expiry', response.data.access_expiration);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        getAuthenticatedUser();

      });
    } catch (error) {
      setOpenSnackBar(true);
      props.setShowProgress(false);
      setErrorMessage('Invalid username or password');
    }
  };

  const navigate = useNavigate();

  const handleFirstNameChange = (event) => {
    setFirstname(event.target.value);

  };

  const handleIdentificationType = (event) => {
    setIdentityType(event.target.value);

  };

  const handleLastNameChange = (event) => {
    setLastname(event.target.value);

  };

  const handleClose = () => {
    setOpenSnackBar(false);
  };

  const location = useLocation();
  console.log('xxdebug Location:', location.state);

  const handleChangeNationality = (event) => {
    setNationality(event.target.value);
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleClickIcon = (roomId) => {
    setShowRoomDetailsId(roomId);
    setShowDetails(!showDetails);
  }

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setSkipped(newSkipped);

    if (activeStep === 0) {
      props.emptyCart();
      navigate(location.pathname, { replace: true });
      await getRoomAvailability();
    }

    if (activeStep === 3) {
      handleCheckIfUserAccountExists();
    }

    if (activeStep === 4) {
      if (paymentMethod === 'card' &&
        (cardNumber === '' || cvv === '' ||
          expiryMonth === '' || expiryYear === '' ||
          cardFirstName === '' || cardLastName === '')) {
        setOpenSnackBar(true);
        setCvvError(cvv ? false : true);
        setExpiryYearError(expiryYear ? false : true);
        setCardFirstNameError(cardFirstName ? false : true);
        setCardNumberError(cardNumber ? false : true);
        setCardLastNameError(cardLastName ? false : true);
        setExpiryMonthError(cardFirstName ? false : true);
        setErrorMessage('Please Enter all card fields');
      } else if (paymentMethod === 'momo' && mobileMoneyNumber === '') {
        setOpenSnackBar(true);
        setMobileMoneyNumberError(mobileMoneyNumber ? false : true)
        setErrorMessage('Please Enter your mobile money number');
      } else if (hasAcceptedTos === false) {
        setHasAcceptedTosError(true);
      } else {
        props.setShowProgress(true);
        const formattedCardNumber = cardNumber.replace(/\D/g, '');
        const storageUserData = localStorage.getItem('userData');
        const storageAccessToken = localStorage.getItem('access_token');

        if (storageUserData && storageAccessToken && isUserHavingAccount) {
          try {
            await ProcessBooking(
              {
                payment_method: paymentMethod,
                telephone_no: phoneNumber,
                cardDetails: {
                  firstname: cardFirstName,
                  lastname: cardLastName,
                  cardno: formattedCardNumber,
                  cvv: cvv,
                  expirymonth: expiryMonth,
                  expiryyear: expiryYear,
                },
                // first_name: "Zaid",
                // last_name: "Egesa",
                // email: "mulumbanasf@gmail.com",
                // salutation: "string",
                // telephone_no: "256700497332",
                // country: "Uganda",
                // ident_type: "Passport",
                // ident_no: "Bojenty",
                // gender: "Male",
                // payment_method: "momo",
                // cardDetails: {
                //   cardno: "5531886652142950",
                //   cvv: "564",
                //   expirymonth: "9",0756881495
                //   expiryyear: "32",
                //   firstname: "Dona",
                //   lastname: "Egesa"
                // }
              }
            ).then((response) => {
              console.log(':: process booking:', response);
              const hasError = response.data.has_error;
              props.setShowProgress(false);
              if (hasError) {
                setOpenSnackBar(true);
                setErrorMessage(response.data.message + ". Remove the room from cart and select another room");
              } else {
                const successMessage = response.data.message;
                const redirectUrl = response.data.redirect_url;
                navigate(`/booking/paymentsuccessful/?message=${successMessage}&&url=${redirectUrl}&&paymentMethod=${paymentMethod}&&email=${email}`);
              }

            });
          } catch (error) {
            props.setShowProgress(false);
            setOpenSnackBar(true);
            setErrorMessage(error.response.data.message);
            console.log(':: processing booking error:', error.response);
          }
          //  .catch(errors => {
          //     props.setShowProgress(false);
          //     console.log(':: processing booking error:', errors);
          //     setErrorMessage('Ooops an error occured, contact reservations.');
          //   });
        } else {
          try {
            await ProcessBookingForAnonymousUser(
              {
                salutation: salutation,
                first_name: firstname,
                last_name: lastname,
                email: email,
                country: nationality,
                ident_type: identityType,
                ident_no: identityNumber,
                gender: gender,
                payment_method: paymentMethod,
                telephone_no: phoneNumber,
                cardDetails: {
                  firstname: cardFirstName,
                  lastname: cardLastName,
                  cardno: formattedCardNumber,
                  cvv: cvv,
                  expirymonth: expiryMonth,
                  expiryyear: expiryYear,
                },
              }
            ).then((response) => {
              console.log(':: process booking anonymous:', response);
              const hasError = response.data.has_error;
              props.setShowProgress(false);
              if (hasError) {
                setOpenSnackBar(true);
                setErrorMessage(response.data.message + ". Remove the room from cart and select another room");
              } else {
                const successMessage = response.data.message;
                const redirectUrl = response.data.redirect_url;
                navigate(`/booking/paymentsuccessful/?message=${successMessage}&&url=${redirectUrl}&&paymentMethod=${paymentMethod}&&email=${email}`);
              }


            })
          } catch (error) {
            props.setShowProgress(false);
            setOpenSnackBar(true);
            setErrorMessage(error.response.data.message);
            console.log(':: processing booking error annonymous:', error.response);
          };
        }

      }
      // } else if(paymentMethod === 'momo') {
      //   navigate('/rooms');
      // }else{
      //   navigate('/rooms');
      // }

      //return;
      // await RegisterBooking("POST_DATA").then((data) => {
      //   console.log('::debug registered booking:', data);
      // });
    }

    if (activeStep === 1) {
      console.log('::myxxzzdebug activeStep if:', activeStep);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    if (activeStep === 2) {
      handleGetSelectedRooms(true);
    }
  };
  console.log('::myxxzzdebug activeStep:', activeStep);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const clearLocationHistory = () => {
    return (
      navigate(location.pathname, {})
    );
  }

  const handleReset = () => {
    setActiveStep(0);
  };

  const getRoomCartData = async (roomsArray) => {
    console.log('::xxxcheck getRoomCartData:', roomsArray);
    var roomsCartDetail = [];
    for (var i = 0; i < roomsArray.length; i++) {
      var roomId = roomsArray[i].room_id;
      const temp = roomsArray[i].entryID;
      const roomArrayIndex = roomsArray[i];
      console.log('::xxxrooms get roomcart data index:', roomsArray[i]);
      try {
        await FetchSpecificRoom(roomId).then((response) => {
          console.log('::xxxrooms get index response:', response);
          roomsCartDetail.push({
            cart: roomArrayIndex,
            roomDetail: {
              entryId: temp,
              ...response.data
            }
          });
          console.log('::xxxcheck roomsCartDetail:', roomsCartDetail);
        });
      } catch (error) {
        console.log('::xxxrooms Error:', error);
      }
    }
    console.log('::Concated stATE testObject:', roomsCartDetail);
    setSelectedRooms(roomsCartDetail);
    console.log('::Concated stATE:', selectedRooms);
  }

  useEffect(() => {
    //const roomsArr = props.cartItems.roomsCart;
    console.log('::debug roomsInCartLength:', roomsInCartLength);
    if (activeStep === 0 && (startDate === '' || endDate === '')) {
      setIsNextButtonDisabled(true);
    } else if (activeStep === 1 && (roomsInCartLength === 0 || availableRoomsArray.length === 0)) {
      setIsNextButtonDisabled(true);
    } else if (activeStep === 2 && roomsInCartLength === 0) {
      setIsNextButtonDisabled(true);
    } else if (
      activeStep === 3 &&
      (gender === '' || nationality === '' || identityType === '' || identityNumber === '' ||
        phoneNumber === '' || email === '' || firstname === '' || lastname === '' || salutation === '' || selectedRooms.length === 0)
    ) {
      setIsNextButtonDisabled(true);
    } else {
      setIsNextButtonDisabled(false);
    }
  }, [
    availableRoomsArray.length,
    selectedRooms.length,
    roomsInCartLength,
    activeStep,
    startDate,
    endDate,
    lastname,
    firstname,
    gender,
    identityNumber,
    identityType,
    salutation,
    email,
    phoneNumber,
    nationality,
    props.cartItems
  ]);

  const handleCheckin = async () => {
    props.emptyCart();
    await getRoomAvailability();
    setActiveStep(1);
  };

  const handleAdultChange = (e) => {
    console.log(e.target.value);
    setNumberOfAdults(e.target.value);
  };

  const handleChildrenChange = (e) => {
    console.log(e.target.value);
    setNumberOfChildren(e.target.value);
  };

  useEffect(() => {
    if (location.state) {
      console.log('location.state useeffect', location.state);
      const { startDate, endDate, roomId, roomName, numberOfAdults, numberOfChildren } = location.state;
      if (startDate && endDate && numberOfAdults) {
        if (numberOfChildren) {
          setStartDate(startDate);
          setEndDate(endDate);
          setNumberOfChildren(numberOfChildren);
          setNumberOfAdults(numberOfAdults);
          handleCheckin();
        } else {
          setStartDate(startDate);
          setEndDate(endDate);
          setNumberOfAdults(numberOfAdults);
          handleCheckin();
        }

      }

      if (roomId) {
        setSelectedRoomId(roomId);
        setSelectedRoomName(roomName);
      }
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleGetSelectedRooms = async (skipStep) => {
    props.setShowProgress(true);
    let objectResponse = [];
    try {
      await GetRoomsInCart().then((response) => {
        objectResponse = response.data.data.room_cart;
        const cartTotal = response.data.data.cart_total;
        setGrossAmount(cartTotal);
      });
      console.log('::debug objectResponse:', objectResponse);
      await getRoomCartData(objectResponse);
      props.setShowProgress(false);
      if(skipStep){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (error) {
      props.setShowProgress(false);
    }

  };

  useEffect(() => {
    const roomEnhancements = props.cartItems.enhancements;
    const fetchRoomsInCart = async () => {
      let objectResponse = [];
      try {
        await GetRoomsInCart().then((response) => {
          console.log('xxxcheck in cart:', response.data);
          objectResponse = response.data.data.room_cart;
          const cartTotal = response.data.data.cart_total;
          setGrossAmount(cartTotal);
          console.log('::xxxcheck in cart objectResponse:', objectResponse);


        });
        if (objectResponse.length > 0) {
          setRoomsInCartLength(objectResponse.length);
          await GetRoomEnhancements().get().then((response) => {
            setEnhancementsArr(response.data.data);
          });
          await getRoomCartData(objectResponse);
          const checkInDateTime = new Date(objectResponse[0].checkInDate);
          const todayDate = new Date();
          if (
            todayDate.getFullYear() <= checkInDateTime.getFullYear() &&
            todayDate.getMonth() <= checkInDateTime.getMonth() &&
            todayDate.getDate() <= checkInDateTime.getDate()
          ) {
            setCartStartDate(objectResponse[0].checkInDate);
            setCartEndDate(objectResponse[0].checkOutDate);
            console.log('xxxcheck today date less than or eqal checkin date');
            setActiveStep(2)
          } else {
            props.setShowProgress(true);
            await ClearBookingCart().then(() => {
              props.setShowProgress(false);
            })
          }

        }

      } catch (error) {
        console.log('::debug roomcart error:', error);
      }
    }
    fetchRoomsInCart();

    setRoomEnhancements(roomEnhancements);
  }, [cartItemChange]);

  const handleAddMoreRooms = () => {
    setActiveStep(1);
  }

  const removeSelectedRoom = async (room) => {
    props.setShowProgress(true);
    try {
      await DeleteRoomFromCart(room.entryId).then((response) => {
        console.log('::debug delete Booking cart response:', response);
        handleGetSelectedRooms(false);
      });
      props.setShowProgress(false);
    } catch (error) {
      props.setShowProgress(false);
      console.log('::debug delete BOOKing cart eerror:', error);
    }
  };


  const getNextButtonName = (activeStep) => {
    switch (activeStep) {
      case 1:
        return "Check Now";
        // eslint-disable-next-line no-unreachable
        break;
      case 2:
        return "Continue";
        break;
      case 3:
        return "Checkout";
        break;
      case 4:
        return "Continue";
        break;
      case 5:
        return "Process Reservation";
        break;
      default:
        return "Check Now";
      // code block
    }

  };

  const handleCheckIfUserAccountExists = () => {
    props.setShowProgress(true);
    try {
      CheckIfUserHasAnAccount(email).then(response => {
        console.log('::Debug if user account response:', response)
        const message = response.data.message;
        props.setShowProgress(false);
        if (message === 'User already registered') {
          console.log('::myxxzzdebug User already registered');
          const authToken = localStorage.getItem('access_token');
          const storageUserData = localStorage.getItem('userData');
          setIsUserHavingAccount(true);
          if (authToken && storageUserData) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else {

            setUserModalopen(true);
          }

        } else {
          console.log('::myxxzzdebug User notttt registered');
          setIsUserHavingAccount(false);
          handleOpenAnonymousUserModal();
        }
      })
    } catch (error) {
      props.setShowProgress(false);
    }
  };

  const SearchRoomsComponent = () => {
    return (
      <div id='search-now-page'>
        <section id='order-dashboard' className="mt-5 pt-5">
          <div className="container">
            <div className="search-row mb-5">
              <div className='row'>
                <div className="col-md-12">
                  <div className="booking-inner clearfix">
                    {
                      location.state && location.state.roomId && (
                        <div className='stepper-selected-room'>
                          <div className='row'>
                            <div className="col-md-12">
                              <div className='restuarant-banner'>
                                <div className="restuarant-banner-message">
                                  <p>Check room availability will be processed against <b>{selectedRoomName}</b>  <button onClick={() => clearLocationHistory()}><span> Remove Condition </span></button></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                    <form className="form1 clearfix">
                      <div className="col1 c1">
                        <div className="input1_wrapper">
                          <label>Check in</label>
                          <div className="input1_inner">
                            <DatePicker
                              selected={startDate}
                              placeholderText='Check in Date'
                              onChange={(date) => setStartDate(date)}
                              minDate={currentDate}
                              className="form-control"
                              customInput={
                                <input
                                  type='text'
                                  className='form-control input datepicker hasDatepicker'
                                  placeholder='Check in'
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col1 c2">
                        <div className="input1_wrapper">
                          <label>Check out</label>
                          <div className="input1_inner">
                            <DatePicker
                              selected={endDate}
                              placeholderText='Check out Date'
                              onChange={(date) => setEndDate(date)}
                              minDate={startDate}
                              disabled={typeof startDate === 'string'}
                              className="form-control"
                              customInput={
                                <input
                                  type='text'
                                  className='form-control input datepicker hasDatepicker'
                                  placeholder='Check out'
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col3 c3">
                        <div className="select1_wrapper">
                          <label>Adults</label>
                          <div className="select1_inner">
                            <select
                              className="select2 select "
                              style={{ width: "100%" }}
                              value={numberOfAdults}
                              onChange={e => handleAdultChange(e)}
                            >
                              <option value="1">1 Adult</option>
                              <option value="2">2 Adults</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col3 c4">
                        <div className="select1_wrapper">
                          <label>Children</label>
                          <div className="select1_inner">
                            <select
                              className="select2 select"
                              style={{ width: "100%" }}
                              value={numberOfChildren}
                              onChange={e => handleChildrenChange(e)}
                            >
                              <option selected value="" disabled> Select Children</option>
                              <option value="0">No Child</option>
                              <option value="1">1 Child</option>
                              <option value="2">2 Children</option>
                              <option value="3">3 Children</option>
                              <option value="4">4 Children</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col2 c5">
                        <div className="select1_wrapper">
                          <label>Rooms</label>
                          <div className="select1_inner">
                            <select
                              className="select2 select"
                              style={{ width: "100%" }}
                            >
                              <option value="1">1 Room</option>
                              <option value="2">2 Rooms</option>
                              <option value="3">3 Rooms</option>
                              <option value="4">4 Rooms</option>
                            </select>
                          </div>
                        </div>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  const GuestInfoComponent = () => {
    return (
      <div id='book-now-page'>
        <section id='order-dashboard' className={matchesAllMobile ? "mt-3 pt-3" : "mt-5 pt-5"}>
          <div className="container">
            <div className='row'>
              <div className="reservation-section" style={{ display: matchesAllMobile ? 'block' : 'flex' }}>
                <div className="booking-cart-section" style={{ width: matchesAllMobile ? '100%' : '50%', paddingLeft: 20, paddingRight: 20 }}>
                  <div id="cart-order-items" className='mb-3'>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="float-right mb-3">
                          <div className="btn-outline-emin">
                            <button onClick={() => handleAddMoreRooms()}><span>+ Add More Rooms</span></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="total-rooms">
                            <h5>Number Of Rooms:{` ${selectedRooms.length}`}</h5>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="total-amount-section">
                            <h5>Total Amount:{`${CurrencyFormat(grossAmount)}USD`}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <ul className="accordion-box clearfix">
                          {
                            selectedRooms.length > 0 ? (
                              selectedRooms.map((roomObj) => {
                                const room = roomObj.roomDetail;
                                const cartStartDate = roomObj.cart.checkInDate;
                                const cartEndDate = roomObj.cart.checkOutDate;
                                return (
                                  <li key={room.id} className="accordion">
                                    <div className="block">
                                      <div className="emin-room-item">
                                        <span className='cart-name-amount'>
                                          <span>{room.room_name}</span>
                                          <b>{`${CurrencyFormat(room.room_base_price)}USD`}</b>
                                        </span>
                                        <button onClick={() => handleClickIcon(room.id)} type='button'>{
                                          showDetails && showRoomDetailsId === room.id ? <ChevronCompactUp /> : <ChevronCompactDown />
                                        }</button>
                                      </div>
                                      {
                                        showDetails && showRoomDetailsId === room.id && (
                                          <div className="content">
                                            <figure>
                                              <img src={room.roomImage[0].image_name} alt="" className="img-fluid" />
                                            </figure>
                                            <div className="col-md-12">
                                              <div
                                                className="rooms2 animate-box"
                                                data-animate-effect="fadeInUp"
                                              >

                                                <div className="caption">
                                                  <h3>
                                                    {`${CurrencyFormat(room.room_base_price)}USD`} <span>/ Night</span>
                                                  </h3>
                                                  <h4>
                                                    {room.room_name}
                                                  </h4>
                                                  <p>
                                                    {room.room_description}
                                                  </p>
                                                  <div className="row room-facilities">
                                                    <div className="col-md-4">
                                                      <ul>
                                                        <li>
                                                          <i className="flaticon-group"></i> 1-2 Persons
                                                        </li>
                                                        <li>
                                                          <i className="flaticon-wifi"></i> Free Wifi
                                                        </li>
                                                      </ul>
                                                    </div>
                                                    <div className="col-md-4">
                                                      <ul>
                                                        <li>
                                                          <i className="flaticon-bed"></i> Twin Bed
                                                        </li>
                                                        <li>
                                                          <i className="flaticon-breakfast"></i> Breakfast
                                                        </li>
                                                      </ul>
                                                    </div>
                                                    <div className="col-md-4">
                                                      <ul>
                                                        <li>
                                                          <i className="flaticon-clock-1"></i> 45 sqft room
                                                        </li>
                                                        <li>
                                                          <i className="flaticon-swimming"></i> Swimming Pool
                                                        </li>
                                                      </ul>
                                                    </div>
                                                    <hr className="border-2" />

                                                    <div className="col-md-6">
                                                      <p><b>Check-in Date: </b><span>{(cartStartDate)}</span></p>
                                                      <p><b>Check-out Date: </b><span>{(cartEndDate)}</span></p>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <p><b>Number of Adults: </b><span>1</span></p>
                                                      <p><b>Number of Children: </b><span>0</span></p>
                                                    </div>
                                                  </div>

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      }
                                    </div>

                                    <div className="delete-container">
                                      <button onClick={() => removeSelectedRoom(room)}><XCircle color='#de1d1d' /></button>
                                    </div>


                                  </li>
                                );
                              })
                            )
                              :
                              (
                                <div className='cart-empty'>
                                  <div className="row">
                                    <div style={{ display: 'flex', justifyContent: 'center' }} className="col-md-12">
                                      <HourglassEmptyIcon style={{ color: '#cdcccc', fontSize: 83 }} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }} className='col-md-12'>
                                      <p style={{ fontWeight: 'bold', fontSize: 29 }}>No rooms available for the selected checkin and checkout date. </p>

                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }} className='col-md-12'>
                                      <div className="butn-dark">
                                        <button onClick={() => { setActiveStep(1) }}>
                                          <span>Select Room</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          }
                        </ul>

                      </div>

                    </div>
                  </div>
                </div>

                <Divider orientation={matchesAllMobile ? "horizontal" : "vertical"} flexItem />

                <div className="guest-information-section" style={{ width: matchesAllMobile ? '100%' : '50%', paddingLeft: 20, paddingRight: 20, paddingTop: matchesAllMobile ? 30 : 0 }}>

                  <div id="order-submit-form" className="row">
                    <h5 className=''>Your Information</h5>
                    <div className="col-md-6 form-group">
                      <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">Salutation</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={salutation}
                          onChange={handleChange}
                          label="Salutation"
                        >
                          <MenuItem value="">
                            <em>Select Salutation</em>
                          </MenuItem>
                          <MenuItem value={'Mr.'}>Mr</MenuItem>
                          <MenuItem value={'Mrs.'}>Mrs</MenuItem>
                          <MenuItem value={'Miss.'}>Miss</MenuItem>
                          <MenuItem value={'Dr.'}>Dr</MenuItem>
                          <MenuItem value={'Capt.'}>Capt</MenuItem>
                          <MenuItem value={'Major.'}>Major</MenuItem>
                          <MenuItem value={'Col.'}>Col</MenuItem>
                          <MenuItem value={'Lt.'}>Lt</MenuItem>
                          <MenuItem value={'Hon.'}>Hon</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-6 form-group">
                      <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <TextField
                          id="standard-basic-name"
                          name="fullName"
                          required
                          label="First Name"
                          variant="standard"
                          value={firstname}
                          onChange={handleFirstNameChange}
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6 form-group">
                      <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <TextField
                          id="standard-basic-lst-name"
                          name="lastName"
                          required
                          label="Last Name"
                          variant="standard"
                          value={lastname}
                          onChange={handleLastNameChange}
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6 form-group">
                      <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <TextField
                          id="standard-basic-email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          name="email"
                          required
                          label="email"
                          variant="standard" />
                      </FormControl>
                    </div>
                    <div className="col-md-6 form-group">
                      <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={gender}
                          onChange={handleChangeGender}
                          label="Salutation"
                        >
                          <MenuItem disabled value="">
                            <em>Select Gender</em>
                          </MenuItem>
                          <MenuItem value="Male">
                            Male
                          </MenuItem>
                          <MenuItem value="Female">
                            Female
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-6 form-group">
                      <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <TextField
                          id="standard-basic-phone"
                          type="tel" name="phone"
                          required
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          label="Phone Number"
                          variant="standard" />
                      </FormControl>
                    </div>
                    <div className="col-md-6 form-group">
                      <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">Nationality</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={nationality}
                          onChange={handleChangeNationality}
                          label="Salutation"
                        >
                          <MenuItem disabled value="">
                            <em>Select Nationality</em>
                          </MenuItem>
                          {
                            nationalityArray.map((nationality) => {
                              return <MenuItem key={nationality.id} value={nationality.id}>{nationality.name}</MenuItem>;
                            })
                          }
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-6 form-group">
                      <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">Identification Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={identityType}
                          onChange={handleIdentificationType}
                          label="Salutation"
                        >
                          <MenuItem disabled value="">
                            <em>Select Identification Type</em>
                          </MenuItem>
                          <MenuItem value="Passport">
                            Passport
                          </MenuItem>
                          <MenuItem value="National ID">
                            National ID
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-6 form-group">
                      <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <TextField
                          id="standard-basic-id-no"
                          type="tel" name="Identity-Number"
                          required
                          value={identityNumber}
                          onChange={(e) => setIdentityNumber(e.target.value)}
                          label="Identification Number"
                          variant="standard" />
                      </FormControl>
                    </div>
                    {/* <div className="col-md-6 form-group">
                      <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <TextField
                          id="standard-basic-address"
                          type="text"
                          name="Identification Type"
                          required
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          label="Address"
                          variant="standard" />
                      </FormControl>
                    </div> */}
                    {/* <div className="col-md-12 form-group">
                      <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <TextField
                          id="standard-basic-note"
                          type="text"
                          multiline
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                          rows={4}
                          name="message"
                          label="Message"
                          variant="standard" />
                      </FormControl>
                    </div> */}
                  </div>
                </div>
                <Modal
                  open={isAnonymousUserModalopen}
                  onClose={handleCloseAnonymousUserModal}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{
                    position: 'relative',
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '20px',
                    maxWidth: '400px',
                    width: '100%',
                  }}>
                    <h2 id="modal-title" style={{ marginTop: 42, marginBottom: 5, textAlign: 'center' }}>Anonymous User Account Creation</h2>
                    <p id="modal-description" style={{ fontSize: 14, textAlign: 'center' }}>
                      <strong>Important Notice:</strong> An account with{' '}
                      <span style={{ color: "#aa8453", fontWeight: 'bold' }}>{email}</span>{' '}
                      will be created for you during checkout for seamless booking on your next
                      visit. Enjoy a faster and personalized booking experience with us!
                    </p>
                    <button
                      className='butn-dark'
                      onClick={handleAnonymousModalContinue}
                      style={{
                        width: '100%',
                        marginTop: '20px',
                        backgroundColor: '#aa8453',
                        color: '#ffffff',
                        paddingTop: 10,
                        paddingBottom: 10,
                        fontSize: 20,
                      }}
                    >
                      Continue
                    </button>
                    <div
                      className="delete-container-select-room"
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                      }}>
                      <button onClick={() => handleCloseAnonymousUserModal()} style={{ backgroundColor: '#eee', borderRadius: '53%' }}><XCircle fontSize="29px" color='#b70606' /></button>
                    </div>
                  </div>
                </Modal>

                <Modal
                  open={userModalopen}
                  onClose={() => setUserModalopen(false)}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    className='row'
                    style={{
                      position: 'relative',
                      backgroundColor: 'white',
                      borderRadius: '4px',
                      outline: 'none',
                      padding: '20px',
                      maxWidth: '400px',
                      width: '100%',
                    }}>
                    <div className="col-md-12">
                      <h2 id="modal-title" style={{ marginTop: 42, marginBottom: 5, textAlign: 'center' }}>User Account Login</h2>
                      <p id="modal-description" style={{ fontSize: 14, textAlign: 'center', marginBottom: 0 }}>
                        We have an account registered in your email address i.e{' '} <span style={{ color: "#aa8453", fontWeight: 'bold' }}>{email}</span> {' '}login into your account.

                      </p>
                      <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <TextField
                          id="standard-basic-username"
                          name="username"
                          required
                          value={userName}
                          onChange={(e) => { setUserName(e.target.value); }}
                          label="Username"
                          type='text'
                          variant="standard" />
                      </FormControl>
                      <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <TextField
                          id="standard-basic-password"
                          name="password"
                          required
                          value={password}
                          onChange={(e) => { setPassword(e.target.value); }}
                          label="Password"
                          type="password"
                          variant="standard" />
                      </FormControl>
                      <button
                        className='butn-dark'
                        onClick={(e) => { e.preventDefault(); handleLoginUser(e) }}
                        style={{
                          width: '100%',
                          marginTop: '20px',
                          backgroundColor: '#aa8453',
                          color: '#ffffff',
                          paddingTop: 10,
                          paddingBottom: 10,
                          fontSize: 16,
                        }}
                      >
                        Login In
                      </button>
                      <div className='float-right'>
                        <Link to="/auth/forgot-password" style={{ color: '#2770cf', fontWeight: 'bold', fontSize: 15 }}>Forgot Password?</Link>
                      </div>
                      <div
                        className="delete-container-select-room"
                        style={{
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                        }}>
                        <button onClick={() => setUserModalopen(false)} style={{ backgroundColor: '#eee', borderRadius: '53%' }}><XCircle fontSize="29px" color='#b70606' /></button>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>

          </div>
        </section>
      </div>
    );
  };

  const getRoomAvailability = async () => {
    var locationObj = {};
    var locationStartDate = "";
    var locationEndDate = "";
    var locationNumberOfAdults = "";
    var locationNumberOfChildren = "";
    var locationRoomId = null;
    console.log('::debug getRoomAvailability state:', location.state);
    if (location.state) {
      locationObj = location.state;
      locationStartDate = locationObj?.startDate;
      locationEndDate = locationObj?.endDate;
      locationRoomId = locationObj?.roomId;
      locationNumberOfAdults = locationObj?.numberOfAdults;
      locationNumberOfChildren = locationObj?.numberOfChildren
    }

    props.setShowProgress(true);
    setIsNextButtonDisabled(true);
    try {
      if (locationRoomId) {
        await CheckSpecificRoomAvailability({
          checkInDate: FormatDate(startDate),
          checkOutDate: FormatDate(endDate),
          room_id: locationRoomId,
          adults: locationNumberOfAdults ?? numberOfAdults
        }).then((response) => {
          setAvailableRoomsArray(response.data);
        });
      } else {
        await CheckRoomAvailability({
          checkInDate: FormatDate(startDate ? startDate : locationStartDate),
          checkOutDate: FormatDate(endDate ? endDate : locationEndDate),
          adults: locationNumberOfAdults ? locationNumberOfAdults : numberOfAdults,
          children: locationNumberOfChildren ? locationNumberOfChildren : numberOfChildren,
        }).then((response) => {
          console.log('::debug available roomsxx:', response.data);

          setAvailableRoomsArray(response.data);
        });
      }

      await GetRoomEnhancements().get().then((response) => {
        console.log('::debug GetRoomEnhancements:', response);
        setEnhancementsArr(response.data.data);
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      navigate(location.pathname, { replace: true });
    } catch (error) {
      props.setShowProgress(false);
      setIsNextButtonDisabled(false);
      setOpenSnackBar(true);
      console.log('::error', error);
      //console.log(error.response.data.errors.query._schema[0]);
      setErrorMessage(error.response.data.errors.query._schema[0]);
      // console.log(error.response.data.message);

    } finally {
      props.setShowProgress(false);
      setIsNextButtonDisabled(false);
    }
  };

  const switchStep = (activeStepValue) => {
    switch (activeStepValue) {
      case 1:
        return <SearchRoomsComponent />;
        // eslint-disable-next-line no-unreachable
        break;
      case 2:
        return <AvailableRooms
          setStep={setActiveStep}
          setShowProgress={props.setShowProgress}
          setRoomsInCartLength={setRoomsInCartLength}
          startDate={startDate}
          endDate={endDate}
          availableRooms={availableRoomsArray}
          numberOfChildren={numberOfChildren}
          numberOfAdults={numberOfAdults} />
        break;
      case 3:
        return <SelectedRooms
          startDate={startDate}
          endDate={endDate}
          cartStartDate={cartStartDate}
          cartEndDate={cartEndDate}
          setShowProgress={props.setShowProgress}
          enhancements={enhancementsArr}
          setSelectedRooms={setSelectedRooms}
          setStep={setActiveStep} />;
        break;
      case 4:
        return GuestInfoComponent();
        break;
      case 5:
        return <MakePayment
          paymentMethod={paymentMethod}
          cardNumber={cardNumber}
          cvv={cvv}
          expiryMonth={expiryMonth}
          expiryYear={expiryYear}
          cardFirstName={cardFirstName}
          hasAcceptedTos={hasAcceptedTos}
          hasAcceptedTosError={hasAcceptedTosError}
          cardLastName={cardLastName}
          mobileMoneyNumber={mobileMoneyNumber}
          cvvError={cvvError}
          expiryYearError={expiryYearError}
          mobileMoneyNumberError={mobileMoneyNumberError}
          cardFirstNameError={cardFirstNameError}
          cardNumberError={cardNumberError}
          expiryMonthError={expiryMonthError}
          cardLastNameError={cardLastNameError}
          setHasAcceptedTos={setHasAcceptedTos}
          setHasAcceptedTosError={setHasAcceptedTosError}
          setPaymentMethod={setPaymentMethod}
          setCardNumber={setCardNumber}
          setCvv={setCvv}
          setExpiryMonth={setExpiryMonth}
          setExpiryYear={setExpiryYear}
          setCardFirstName={setCardFirstName}
          setCardLastName={setCardLastName}
          setMobileMoneyNumber={setMobileMoneyNumber}
          setMobileMoneyNumberError={setMobileMoneyNumberError}
          setExpiryYearError={setExpiryYearError}
          setCardFirstNameError={setCardFirstNameError}
          setCardLastNameError={setCardLastNameError}
          setExpiryMonthError={setExpiryMonthError}
          setCvvError={setCvvError}
          setCardNumberError={setCardNumberError}
        />
        break;
      default:
        return <SearchRoomsComponent />;
      // code block
    }
  };

  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };


  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <ScreenSearchDesktopIcon />,
      2: <BusinessOutlinedIcon />,
      3: <AddShoppingCartIcon />,
      4: <LibraryBooksOutlinedIcon />,
      5: <PaidIcon />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(228 168 83) 0%, rgb(199 152 85) 50%, rgb(215 167 99) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(228 168 83) 0%, rgb(199 152 85) 50%, rgb(215 167 99) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(228 168 83) 0%, rgb(199 152 85) 50%, rgb(215 167 99) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(228 168 83) 0%, rgb(199 152 85) 50%, rgb(215 167 99) 100%)',
    }),
  }));



  return (
    <div id='booking-stepper-page'>
      <div
        className='banner-header section-padding valign bg-img bg-fixed'
        data-overlay-dark='4'
        data-background='img/slider/1.jpg'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 mt-90'>
              <h5>The Emin Pasha Hotel</h5>
              <h1>Room Booking</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="booking-stepper section-padding bg-cream">
        <div className="container">
          {/* <div className="row">
                        <div className="col-md-12">
                            <div className="section-subtitle">Book Now Page</div>
                            <div className="section-title">Room Booking</div>
                        </div>
                    </div> */}
          <div className="row">
            <div className="col-md-12">

              <Box sx={{ width: '100%', marginBottom: 3, }}>
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={openSnackBar}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert variant="filled" severity="error">{errorMessage}</Alert>
                </Snackbar>
                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {/* <Stepper activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        if (isStepOptional(index)) {
                                            labelProps.optional = (
                                                <Typography variant="caption">Optional</Typography>
                                            );
                                        }
                                        if (isStepSkipped(index)) {
                                            stepProps.completed = false;
                                        }
                                        return (
                                            <Step key={label} {...stepProps}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper> */}
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                    {
                      switchStep(activeStep + 1)
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        color="primary"
                        disabled={activeStep === 0}
                        variant="outlined"
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        style={{ fontFamily: 'Avenirlight' }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {/* {isStepOptional(activeStep) && (
                        <Button colo<ser="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                          Skip
                        </Button>
                      )} */}
                      <div className={isNextButtonDisabled ? 'btn-primary-emin-disabled' : 'btn-primary-emin'}>
                        <button disabled={isNextButtonDisabled} onClick={handleNext}>
                          {/* {activeStep === steps.length - 1 ? 'Finish' : 'Next'} */}
                          {getNextButtonName(activeStep + 1)}
                        </button>
                      </div>

                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingStepper);
